<template>
  <div class="d-block">
    <span class="font-weight-bold mb-2" v-if="!hideTitle">{{ Archetypes.CLSSHTD.name }}</span>
    <v-sheet class="w-100" elevation="0">
    <v-card-text>
      <div class="d-flex flex-column w-100">
        <!-- Brand -->
        <div class="d-block">
          <v-row>
            <v-col cols="2" class="d-flex">
              <span :class="classTitle">{{ Archetypes.CLSSHTDB.name }}</span>
            </v-col>
            <v-col cols="10">
              <ArchetypeInput
                :path="returnPath('TD/B')"
                :hideName="true"
                :schema="Archetypes.CLSSHB"
                :collections="dataPoints"
              />
            </v-col>
          </v-row>    
        </div>
        <!-- BC -->
        <div class="d-block mt-1">
          <v-row>
            <v-col cols="2" class="d-flex">
              <span :class="classTitle">{{ Archetypes.CLSSHTDBC.name }}</span>
            </v-col>
            <v-col cols="10">
              <ArchetypeInput
                :path="returnPath('TD/BC')"
                :hideName="true"
                :schema="Archetypes.CLSSHBC"
                :collections="dataPoints"
              />
            </v-col>
          </v-row>    
        </div>
        <!-- DIA -->
        <div class="d-block mt-1">
          <v-row>
            <v-col cols="2" class="d-flex">
              <span :class="classTitle">{{ Archetypes.CLSSHTDD.name }}</span>
            </v-col>
            <v-col cols="10">
              <ArchetypeInput
                :path="returnPath('TD/DIA')"
                :hideName="true"
                :schema="Archetypes.CLSSHD"
                :collections="dataPoints"
              />
            </v-col>
          </v-row>    
        </div>
        <!-- S-C-A -->
        <div class="d-block mt-1">
          <v-row>
            <!-- S -->
            <v-col cols="4">
              <div class="d-flex">
                <span :class="classTitle">{{ Archetypes.CLSSHTDS.name}}</span>
                <ArchetypeInput
                  :path="returnPath('TD/S')"
                  :hideName="true"
                  :schema="Archetypes.CLSSHS"
                  :collections="dataPoints"
                />
              </div>
            </v-col>
             <!-- C -->
            <v-col cols="4">
              <div class="d-flex">
                <span :class="classTitle">{{ Archetypes.CLSSHTDC.name}}</span>
                <ArchetypeInput
                  :path="returnPath('TD/C')"
                  :hideName="true"
                  :schema="Archetypes.CLSSHC"
                  :collections="dataPoints"
                />
              </div>
            </v-col>
             <!-- A -->
            <v-col cols="4">
              <div class="d-flex">
                <span :class="classTitle">{{ Archetypes.CLSSHTDA.name}}</span>
                <ArchetypeInput
                  :path="returnPath('TD/A')"
                  :hideName="true"
                  :schema="Archetypes.CLSSHA"
                  :collections="dataPoints"
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- TYPE -->
        <div class="d-block mt-1">
          <v-row>
            <v-col cols="2" class="d-flex">
              <span :class="classTitle">{{ Archetypes.CLSSHTDT.name }}</span>
            </v-col>
            <v-col cols="10">
              <ArchetypeInput
                :path="returnPath('TD/TYPE')"
                :hideName="true"
                :schema="Archetypes.CLSSHT"
                :collections="dataPoints"
              />
            </v-col>
          </v-row>    
        </div>
      </div>
    </v-card-text>
    </v-sheet>
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "ContactLensSoftHardTrialDetailComponent",
  components: {
    ArchetypeInput  
  },
  props: {
    hideTitle: {
      type: Boolean,
      default: false
    },

    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
    pathAppend: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    classTitle: 'text-left text-body-2 font-weight-bold mr-2 mt-3'
  }),
  methods: {
    returnPath(primaryPath) {
      let pathAppend = this.pathAppend != '' ? `/${this.pathAppend}` : '';
      return `${this.pathPrefix}/${primaryPath}${pathAppend}`;
    }
  }
}
</script>

<style scoped>
.v-text-field__details {
  display: none;
}
</style>