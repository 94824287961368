
import Helper from '../plugins/helper';
const MedNameStorage = 'ListMedicationStoraged';

const state = () => ({
  listRegisterMedicine: Helper.getLocalStorage(MedNameStorage) == null ? [] : JSON.parse(Helper.getLocalStorage(MedNameStorage)),
})

const getters = {

}

const mutations = {
  ADD_MORE_MEDICATION: (state, payload) => {
    let { itemMedicine }  = payload
    
    state.listRegisterMedicine.push(itemMedicine);
    Helper.setLocalStorage(MedNameStorage, JSON.stringify(state.listRegisterMedicine));
  }
}

export default {
  namespaced: 'Medication',
  state,
  getters,
  mutations,
}