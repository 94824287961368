<template>
  <v-row>
    <v-col cols="2" class="d-flex align-center">
      <span class="w-100 text-center font-weight-bold">
        {{ Archetypes.MET.name }}
      </span>
    </v-col>
    <v-col cols="10">
        <ArchetypeInput 
          :path="pathPrefix + '/MET/OU'"
          :hideName="true"
          :schema="Archetypes.MET"
          :collections="dataPoints"
          @input="input"
        />
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
    components: {
        ArchetypeInput
    },
    props: {
        dataPoints: {
            type: Array,
            default: function () {
                return [];
            },
        },
        pathPrefix: {
            type: String,
            default: ""
        }
    },
    methods: {
      input(val) {
        this.$emit("input", val);
      },
  },
}
</script>

<style>

</style>