import AuthMiddleware from '@/middlewares/authen'
import { UserTypeCodes } from "@/plugins/constant";

export default [
  {
    path: "home",
    component: () => import("@/layouts/home-layout/HomeLayout"),
    children: [
      {
        path: "/",
        component: () => import(`@/views/Home/WorkList/Index.vue`),
        redirect: '/home/work-list/1',
        children: [
          {
            path: "work-list/:id",
            component: () => import(`@/views/Home/WorkList/WorkList.vue`),
          },
        ],
      },
      {
        path: "form",
        component: () => import(`@/views/Home/Form/Form.vue`),
        redirect: '/home/form/list',
        children: [
          {
            path: "list",
            component: () => import(`@/components/examination/management/FormList.vue`),
          },
          {
            path: "create",
            component: () => import(`@/components/examination/management/FormCreate.vue`),
            beforeEnter: AuthMiddleware.AllowUserTypes([UserTypeCodes.Root, UserTypeCodes.Admin, UserTypeCodes.Doctor]),
          },
          {
            path: "detail/:formID",
            component: () => import(`@/components/examination/management/FormCreate.vue`),
          },
        ],
      },
      {
        path: 'medicine',
        component: () => import('@/views/Home/Medicine/Medicine')
      },
      {
        path: "calendar",
        component: () => import(`@/views/Home/Calendar/Calendar.vue`),
      },
      {
        path: "roster",
        component: () => import(`@/views/Home/Calendar/Roster.vue`),
      },
      {
        path: "patient",
        component: () => import(`@/views/Home/Patient/Patient.vue`),
      },
      {
        path: 'analysis',
        component: () => import('@/components/analysis/AnalysisList')
      },
      {
        path: 'flow',
        component: () => import('@/layouts/blank-layout/BlankLayout'),
        children: [
          {
            path: "/",
            component: () => import(`@/components/flow/FlowList.vue`),
          },
          {
            path: "designer",
            component: () => import(`@/components/flow/FlowDesigner.vue`),
          }
        ]
      },
      {
        path: "report",
        component: () => import(`@/views/Home/Report/Index.vue`),
        children: [
          {
            path: "/",
            redirect: '/home/report/finish',
          },
          {
            path: "finish",
            component: () => import(`@/views/Home/Report/Report.vue`),
          },
          {
            path: "paid",
            component: () => import(`@/views/Home/Report/Report.vue`),
          },
        ]
      },
    ],
  },
];
