<template>
  <div class="d-block w-100">
    <span class="d-block text-left text-subtitle-1 font-weight-bold">{{ Archetypes.THCLS.name }}</span>
    <div class="d-block">
      <v-row justify="space-between">
        <v-col cols="5" class="text-center">
          <span class="font-weight-bold">OD</span>
        </v-col>
        <v-col cols="5" class="text-center">
          <span class="font-weight-bold">OS</span>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="5">
          <CLSTHBSComponent
            :dataPoints="dataPoints"
            :pathPrefix="`${pathPrefix}/${Archetypes.THCLS.archetypeID}`"
            pathAppend="OD"
          />
        </v-col>
        <v-col cols="5">
          <CLSTHBSComponent
            :dataPoints="dataPoints"
            :pathPrefix="`${pathPrefix}/${Archetypes.THCLS.archetypeID}`"
            pathAppend="OS"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name:"TrialHardContactLensComponent",

  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  }
}
</script>

<style>

</style>