<template>
  <v-row>
    <v-col cols="2" class="d-flex align-center">
      <span class="w-100 text-left font-weight-bold">
        {{ Archetypes.MEDNAME.name }}
      </span>
    </v-col>
    <v-col cols="5">
      <ArchetypeInput
        :path="pathPrefix + '/MEDNAME'"
        :hideName="true"
        :schema="Archetypes.MEDNAME"
        :collections="dataPoints"
      />
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "MedicineNameComponent",
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  }
}
</script>

<style>

</style>