<template>
  <div class="d-flex flex-column">
    <TONOComponent :dataPoints="dataPoints" :pathPrefix="pathPrefix" />
    <PDComponent :dataPoints="dataPoints" :pathPrefix="pathPrefix" />
  </div>
</template>

<script>
export default {
  name: "TonoPDComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
</style>