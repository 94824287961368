<template>
  <div class="form-table">
    <v-row>
      <v-col cols="12">
        <h4>{{ Archetypes.RSOD.name }}</h4>
        <v-tabs v-model="tab">
          <v-tab v-for="item in items" :key="item">
            {{ items.length }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item">
            <v-card>
              <v-card-text>{{ text }}</v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      items: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
      text: "S:0 A:0 F:0 E:0 ",
    };
  },
  components: {},
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    getLengthTab() {
      console.log(this.items.length);
    },
    addTabResult() {
      this.tab = this.items.length + 1;
    },
  },
};
</script>

<style>
.fit-height-tab {
  height: 200px;
}
</style>