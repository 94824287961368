<template>
  <v-row>
    <v-col cols="2" class="text-center d-flex align-center">
      <h3>{{ Archetypes.CLSPUPIL.name }}</h3>
    </v-col>
    <v-col cols="5" class="text-center">
      <ArchetypeInput
        :path="pathPrefix + '/PUP/OD'"
        :hideName="true"
        :collections="dataPoints"
        :schema="Archetypes.CLSPUP"
        @input="input"
      />
    </v-col>
    <v-col cols="5" class="text-center">
      <ArchetypeInput
        :path="pathPrefix + '/PUP/OS'"
        :hideName="true"
        :schema="Archetypes.CLSPUP"
        :collections="dataPoints"
        @input="input"
      />
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: 'ContactLensPupilSizeComponent',
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
}
</script>

<style>

</style>