<template>
  <v-row>
    <v-col cols="2" class="text-center">
      <h3>{{ Archetypes.CON.name }}</h3>
    </v-col>
    <v-col cols="5" class="text-center">
      <h4>{{ Archetypes.SOA.name }}</h4>
      <ArchetypeInputCON
        :path="pathPrefix + '/CON/OU'"
        :hideName="true"
        :collections="dataPoints"
        :schema="Archetypes.CON"
        ref="ArchetypeInputCON"
      />
    </v-col>
    <v-col cols="5" class="text-center">
      <ArchetypeInput
        :path="'/CON/SOA/OU'"
        :hideName="true"
        :schema="Archetypes.SOA"
        :collections="dataPoints"
        @input="handleChangeSOA_CON"
      />
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
import ArchetypeInputCON from "@/components/examination/ArchetypeInput.vue";

export default {
  components: {
    ArchetypeInput,
    ArchetypeInputCON,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
    isCONNone: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    handleChangeSOA_CON(el) {
      if (el.val == "None") {
        this.$refs.ArchetypeInputCON.resetValueGLO(0);
      }
    },
  },
};
</script>

<style>
</style>