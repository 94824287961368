<template>
  <div class="pb-5">
    <v-tabs v-model="criteria" background-color="transparent" grow>
      <v-tab v-for="item in criterias" :key="item">
        <v-badge
          v-if="hasDataPoint(`/COVT/${item}`)"
          color="green darken-2"
          dot
          offset-x="-8"
          offset-y="10"
        >
          {{ Archetypes[item].name }}
        </v-badge>
        <span v-else>{{ Archetypes[item].name }}</span>
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="criteria" class="mt-5">
      <v-tab-item eager v-for="item in criterias" :key="item">
        <COVTSDAComponent
          :dataPoints="dataPoints"
          :pathPrefix="`/COVT/${item}`"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import COVTSDAComponent from "@/components/examination/components/Archetypes/COVTSDAComponent";

export default {
  components: {
    COVTSDAComponent,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      criteria: 0,
      criterias: ["COVTWTS", "COVTWOS", "COVTWNS", "COVTAFD"],
    };
  },
  created() {},
  computed: {},
  methods: {
    hasDataPoint(prefix) {
      return this.validateHighLightDataPoint(
        this.dataPoints.find((d) => d.path.includes(prefix))
      );
    },
  },
};
</script>

<style>
</style>