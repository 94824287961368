<template>
  <v-card
    class="elevation-0 covteda-card"
    style="border: 1px solid rgba(0, 0, 0, 0.6)"
  >
    <v-tabs
      v-model="eyeType"
      background-color="transparent"
      grow
      class="cover-test-eye-tabs"
    >
      <v-tab v-for="item in eyeTypes" :key="item">
        <v-badge
          v-if="hasDataPointType(item)"
          color="green darken-2"
          dot
          offset-x="-8"
          offset-y="2"
        >
          {{ Archetypes[item].name }}
        </v-badge>
        <span v-else>{{ Archetypes[item].name }}</span>
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="eyeType">
      <v-tab-item eager v-for="item in eyeTypes" :key="item">
        <v-row>
          <v-col cols="2" class="d-flex align-center">
            <span class="w-100 text-center font-weight-bold">
              {{ Archetypes.SOM.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <ArchetypeInput
              v-model="type"
              :path="pathPrefix + '/SOM/' + item"
              :hideName="true"
              :schema="Archetypes.SOM"
              :collections="dataPoints"
              @input="input"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <span class="w-100 text-center font-weight-bold">
              {{ Archetypes.SMOM.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <ArchetypeInput
              v-model="type"
              :path="pathPrefix + '/SMOM/' + item"
              :hideName="true"
              :schema="Archetypes.SMOM"
              :collections="dataPoints"
              @input="input"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="d-flex align-center">
            <span class="w-100 text-center font-weight-bold">
              {{ Archetypes.AOM.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <ArchetypeInput
              v-model="type"
              :path="pathPrefix + '/AOM/' + item"
              :hideName="true"
              :schema="Archetypes.AOM"
              :collections="dataPoints"
              @input="input"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <span class="w-100 text-center font-weight-bold">
              {{ Archetypes.ACCOM.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <ArchetypeInput
              v-model="type"
              :path="pathPrefix + '/ACCOM/' + item"
              :hideName="true"
              :schema="Archetypes.ACCOM"
              :collections="dataPoints"
              @input="input"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="d-flex align-center">
            <span class="w-100 text-center font-weight-bold">
              {{ Archetypes.FOM.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <ArchetypeInput
              v-model="type"
              :path="pathPrefix + '/FOM/' + item"
              :hideName="true"
              :schema="Archetypes.FOM"
              :collections="dataPoints"
              @input="input"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <span class="w-100 text-center font-weight-bold">
              {{ Archetypes.FUOM.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <ArchetypeInput
              v-model="type"
              :path="pathPrefix + '/FUOM/' + item"
              :hideName="true"
              :schema="Archetypes.FUOM"
              :collections="dataPoints"
              @input="input"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="d-flex align-center">
            <span class="w-100 text-center font-weight-bold">
              {{ Archetypes.EOM.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <ArchetypeInput
              v-model="type"
              :path="pathPrefix + '/EOM/' + item"
              :hideName="true"
              :schema="Archetypes.EOM"
              :collections="dataPoints"
              @input="input"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <span class="w-100 text-center font-weight-bold">
              {{ Archetypes.EXOM.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <ArchetypeInput
              v-model="type"
              :path="pathPrefix + '/EXOM/' + item"
              :hideName="true"
              :schema="Archetypes.EXOM"
              :collections="dataPoints"
              @input="input"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      eyeType: 0,
      eyeTypes: ["OD", "OS"],
      type: {},
    };
  },
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    hasDataPointType(eyeType) {
      return this.validateHighLightDataPoint(
        this.dataPoints.find(
          (d) => d.path.includes(this.pathPrefix) && d.path.includes(eyeType)
        )
      );
    },
  },
};
</script>

<style>
</style>