import Helper from '../plugins/helper';

const localStorageName = "HeaderTabConfig";

const state = () => ({
  headerTab: getHeaderTabConfig(),
})

const getters = {
  headerTab: state => state.headerTab,
}

const mutations = {
  // save the whole current tab object
  SAVE_HEADER_TAB(state) {
    var { selected, options } = state.headerTab;
    saveHeaderTabConfig({
      selected,
      options: options.map(o => ({
        key: o.key,
        label: o.label,
        closable: o.closable == false ? false : true
      }))
    });
  },

  RESTORE_DEFAULT_HEADER_TAB(state) {
    state.headerTab = {
      selected: "/home/work-list/1",
      options: [{
        label: "Home",
        key: "/home/work-list/1",
        closable: false,
      }],
    }
    saveHeaderTabConfig(state.headerTab);
  },
  ADD_HEADER_TAB(state, { label, key }) {
    var ind = state.headerTab.options.findIndex(op => op.key == key);
    if (ind != -1) return;
    state.headerTab.options.push({ label, key, closable: true });
    state.headerTab.selected = key;
    saveHeaderTabConfig(state.headerTab);
  },
  // update home tab with new key
  UPDATE_HEADER_TAB_HOME(state, key) {
    var ind = state.headerTab.options.findIndex(op => op.label.toLowerCase() == 'home');
    if (ind == -1) return;
    state.headerTab.options[ind].key = key;
    state.headerTab.selected = key;
    saveHeaderTabConfig(state.headerTab);
  },

  UPDATE_HOME_TAB_NAME(state, dispatch) {
    let { name } = dispatch;
    state.headerTab.options = state.headerTab.options.map(i => {
      if (i.key == '/home/work-list/1') {
        i.label = `Home - ${name}`
      }
      return i;
    })
  }
}

const actions = {
}

var getHeaderTabConfig = () => {
  var stringData = Helper.getLocalStorage(localStorageName);
  if (stringData) return JSON.parse(stringData);
  return {
    selected: "/home/work-list/1",
    options: [{
      label: "Home",
      key: "/home/work-list/1",
      closable: false,
    }],
  }

}
var saveHeaderTabConfig = (headerTab) => {
  return Helper.setLocalStorage(localStorageName, JSON.stringify(headerTab));
}

export default {
  state,
  getters,
  mutations,
  actions,
}