<template>
  <div class="d-block position-relative">
    <v-sheet elevation="0">
      <v-row justify="space-between">
        <v-col cols="9">
          <div class="d-flex flex-column" style="overflow: auto">
            <div
              class="d-block ma-1"
              v-for="(item, index) in listPreliminary"
              :key="index"
              :id="item.href"
            >
              <div class="preliminaryTest__title">
                {{ item.name }}
              </div>
              <div class="preliminaryTest__content">
                <component
                  :is="item.components"
                  :dataPoints="dataPoints"
                  :pathPrefix="pathPrefix"
                ></component>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="position-relative">
          <div class="position-fixed preliminaryControl__container">
            <v-card elevation="0" outlined>
              <v-card-text class="pa-0">
                <v-list flat class="pa-0">
                  <v-list-item-group v-model="selectTest">
                    <v-list-item
                      active-class="preliminaryControl__item__active"
                      v-for="(item, index) in listPreliminary"
                      :key="index"
                    >
                      <v-list-item-content
                        v-scroll-to="{ element: `#${item.href}`, offset: -200 }"
                      >
                        {{ item.name }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "PreliminaryComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  created() {},
  data: () => ({
    selectTest: 0,
    listPreliminary: [
      {
        name: "Visual Accuity",
        href: "visualAccuity",
        components: "VSAComponent",
      },
      {
        name: "Tonometer & PD",
        href: "tonometerPD",
        components: "TOPDComponent",
      },
      {
        name: "Current Glass",
        href: "currentGlass",
        components: "CGComponent",
      },
      {
        name: "Objective Refraction",
        href: "objectiveRefraction",
        components: "OJRComponent",
      },
      {
        name: "Subjective Refraction",
        href: "subjectiveRefraction",
        components: "SJRComponent",
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.position-fixed {
  position: fixed;
}
.preliminaryTest__title {
  display: block;
  width: 100%;
  padding: 10px 12px;
  background-color: #03214d;
  color: #ffffff;
  font-size: 18px;
  font-weight: 450;
}
.preliminaryTest__content {
  display: block;
  padding: 10px 0px;
}
.preliminaryControl__container {
  display: inline-block;
  width: 250px;

  .preliminaryControl__item__active {
    color: #ffffff;
    background-color: #0d0f12;
    font-size: 14px;
  }
}
</style>