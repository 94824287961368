<template>
  <div class="d-block">
    <v-card class="pa-0" elevation="0" outlined>
      <v-card-title>
        <span>{{ Archetypes.CAIF.name }}</span>
      </v-card-title>
      <v-card-text>
        <IMGLComponent :dataPoints="dataPoints" :pathPrefix="pathPrefix" />
        <IMGDComponent :dataPoints="dataPoints" :pathPrefix="pathPrefix" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CaptureImageFormComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
</style>