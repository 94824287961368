var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hideName)?_c('h4',{staticClass:"mb-2 font-weight-bold"},[_vm._v(_vm._s(_vm.schema.name))]):_vm._e(),(_vm.schema.type == 'checkbox')?_c('div',_vm._l((_vm.schema.options),function(opt){return _c('v-checkbox',{key:opt,staticClass:"float-left",attrs:{"dense":"","hide-details":"","label":opt,"value":opt},on:{"change":_vm.handleDataChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}),1):_vm._e(),(_vm.schema.type == 'radio')?_c('div',[_c('v-radio-group',{attrs:{"row":""},on:{"change":_vm.handleDataChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.schema.options),function(opt){return _c('v-radio',{key:opt,staticClass:"float-left mb-2",attrs:{"dense":"","hide-details":"","label":opt,"value":opt}})}),1)],1):_vm._e(),(
      _vm.schema.type != 'checkbox' &&
      _vm.schema.type != 'radio' &&
      _vm.schema.type != 'link' &&
      _vm.is
    )?_c(_vm.is,{tag:"component",attrs:{"multiple":_vm.multiple,"label":_vm.schema.name,"items":_vm.schema.options,"rows":"2","outlined":"","dense":""},on:{"change":_vm.handleDataChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),(_vm.schema.type == 'link')?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center pa-1"},[_c('v-text-field',{staticClass:"mr-1",staticStyle:{"width":"50%"},attrs:{"label":_vm.schema.name,"rows":"2","outlined":"","dense":""},on:{"change":_vm.handleDataChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),(
          /(http|https){0,1}:{0,1}(\/\/){0,1}(www.){0,1}(\w+.){1,}(\/|(\w+))$/.test(
            _vm.value
          ) && _vm.value.length > 0
        )?_c('v-btn',{attrs:{"dark":"","icon":"","color":"#007bff"},on:{"click":function($event){return _vm.openTabLink(_vm.value)}}},[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1):_vm._e()],1),_c('div',{staticClass:"d-block pa-1 h-auto"},[(
          /(http|https){0,1}:{0,1}(\/\/){0,1}(www.){0,1}(\w+.){1,}(\/|(\w+))$/.test(
            _vm.value
          ) && _vm.value.length > 0
        )?_c('iframe',{staticClass:"w-100",staticStyle:{"height":"400px"},attrs:{"src":_vm.value}}):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }