<template>
  <v-sheet>
    <div class="d-block">
      <span class="text-subtitle-1 font-weight-bold">{{ clsType }}</span>
    </div>
    <div class="d-block">
      <v-row>
        <v-col lg="4" md="5" sx="12" sm="12">
          <span class="d-block text-center font-weight-bold">OD</span>
        </v-col>
        <v-col lg="4" md="5" sx="12" sm="12">
          <span class="d-block text-center font-weight-bold">OS</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="4" md="5" sx="12" sm="12">
          <CLSSHTDComponent 
            v-bind="$props"
            :hideTitle="true"
            pathAppend="OD"
          />
        </v-col>
        <v-col lg="4" md="5" sx="12" sm="12">
          <CLSSHTDComponent 
            v-bind="$props"
            :hideTitle="true"
            pathAppend="OS"
          />
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'ClsCommonOrderComponent',
  // can be Hard, can be soft or can be OrthoK
  props: {
    clsType: {
      type: String, 
      required: true, 
    },
    pathPrefix: {
      type: String, 
      required: true,     
    },
    
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  }
}
</script>

<style>

</style>