<template>
  <v-sheet elevation="0">
    <!-- Current glass condition -->
    <CGCComponent 
      class='mt-2 mb-2'
      v-model="currentGlassCondition"
      :pathPrefix="this.rootPath"
      :dataPoints="dataPoints"
    />
    <!-- LASIK, USE GLASS, NOT USE -->
    <template v-if="showCGComponent">
      <div class="d-block pa-2">
        <v-row>
          <v-col
            md="3"
            sx="12"
            sm="12"
            class="pa-0"
            v-for="(item, index) in tabCurrentGlass"
            :key="index"
          >
            <div
              @click="changeTab(item.name)"
              :class="item.active ? 'tabObj__item__active' : 'tabObj__item'"
            >
              <v-badge
                v-if="hasDataPoint(item.pathPrefix)"
                color="green"
                dot
                offset-x="-8"
                offset-y="10"
              >
                {{ item.text }}
              </v-badge>
              <span v-else>{{ item.text }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="d-block mt-1">
        <div
          class="d-block"
          v-for="(item, index) in tabCurrentGlass"
          :key="index"
        >
          <template v-if="item.active">
            <div v-for="(component, index) in item.components" :key="index">
              <component
                :is="component"
                :pathPrefix="returnPath(component, item.pathPrefix)"
                :dataPoints="dataPoints"
              ></component>
              <v-row v-if="index == 0 && item.name != 'cls_glass'">
                <v-col cols="2"></v-col>
                <v-col cols="5" class="text-center font-weight-bold">
                  <span>OD</span>
                </v-col>
                <v-col cols="5" class="text-center font-weight-bold">
                  <span>OS</span>
                </v-col>
              </v-row>
            </div>
          </template>
        </div>
      </div>
    </template>
    <!-- IOL CASE -->
    <template v-else>
      <CGIOLComponent 
        :pathPrefix="this.rootPath"
        :dataPoints="dataPoints"
      />
    </template>
  </v-sheet>
</template>

<script>
import CGIOLComponent from '@/components/examination/components/Archetypes/CGIOLComponent'
import CGCComponent from '@/components/examination/components/Archetypes/CGCComponent';
export default {
  name: "CurrentGlassComponent",
  components: {
    CGCComponent,
    CGIOLComponent
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  watch: {
    currentGlassCondition() {
      if (this.currentGlassCondition != null && typeof this.currentGlassCondition == 'object') {
        if (this.currentGlassCondition.value) {
          let value = this.currentGlassCondition.value.split('|');
          if (value[0] && value[0] != "IOL") {
            this.showCGComponent = true
          } else {
            this.showCGComponent = false
          }
        }
      }
    }
  },
  data: () => ({
    rootPath: "/CG",
    showCGComponent: true,
    currentGlassCondition: null,
    sharedComponent: [],
    tabCurrentGlass: [
      {
        text: "Distant Glass",
        name: "distant_glasss",
        active: true,
        pathPrefix: "/CG/CDG", // current glass/ current distant glass
        components: [
          "WBCGComponent",
          "SPHComponent",
          "CYLComponent",
          "AXSComponent",
          "DSVAComponent",
        ],
      },
      {
        text: "Near Glass",
        name: "near_glass",
        active: false,
        pathPrefix: "/CG/CNG", // current glass/ current near glass
        components: [
          "WBCGComponent",
          "SPHComponent",
          "CYLComponent",
          "AXSComponent",
          "NVACGComponent",
        ],
      },
      {
        text: "Progressive glass",
        name: "progressive_glass",
        active: false,
        pathPrefix: "/CG/CPG", // current glass/ current progressive glass
        components: [
          "WBCGComponent",
          "SPHComponent",
          "CYLComponent",
          "AXSComponent",
          "DSVAComponent",
          "ADDComponent",
          "NVACGComponent",
        ],
      },
      {
        text: "Contact Lens",
        name: "cls_glass",
        active: false,
        pathPrefix: "/CG/CCLS",
        components: ['CGCLSComponent']
      }
    ],
  }),

  methods: {
    changeTab(nameTab) {
      this.tabCurrentGlass = this.tabCurrentGlass.map((i) => {
        if (i.name == nameTab) {
          i.active = true;
        } else {
          i.active = false;
        }
        return i;
      });
    },
    returnPath(itemComponent, pathPrefix) {
      return this.sharedComponent.includes(itemComponent) ? "" : pathPrefix;
    },
    hasDataPoint(prefix) {
      return this.validateHighLightDataPoint(
        this.dataPoints.find((d) => d.path.includes(prefix))
      );
    },
  },
};
</script>

<style scoped>
.tabObj__item,
.tabObj__item__active {
  padding: 5px;
  text-align: center;
  cursor: pointer;
}
.tabObj__item__active {
  background-color: #0044a8;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.tabObj__item {
  color: black;
  background-color: #e2e5e9;
}
</style>