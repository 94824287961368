const state = {
    brand: {
        description: '',
        name: ''
    },
    clinic: {
        name: '',
        address: '',
        website: '',
        hotline: ''
    },
}

const mutations = {
    SET_CLINIC_BRAND: (state, payload) => {
        let { clinic, brand } = payload;
        state.clinic = clinic;
        state.brand = brand;
    }
}

export default {
    namespaced: 'Clinic',
    state,
    mutations
}