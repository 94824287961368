import moment from 'moment'
const MEDICATION_HEADERS = [
  {
    text: 'Dessert (100g serving)',
    align: 'start',
    sortable: false,
    value: 'name',
  },
  { text: 'Calories', value: 'calories', sortable: false },
  { text: 'Fat (g)', value: 'fat' },
  { text: 'Carbs (g)', value: 'carbs' },
  { text: 'Protein (g)', value: 'protein' },
  { text: 'Iron (%)', value: 'iron' },
];

const state = () => ({
  // medication
  medicationHeaders: MEDICATION_HEADERS,
  medications: [],


  materials: [],


  services: [],

  followUpObj: null,

  patientInfo: {
    patientID: '',
    avatar: '',
    familyName: '',
    middleName: '',
    givenName: '',
    phone: '',
    genderType: null,
    gender: '',
    email: '',
    birthDate: '',
    age: '',
    address: ''
  },
  test: ''
})

const getters = {

}

const mutations = {
  SET_PATIENT_INFO(state, payload) {
    let { 
      address,
      avatar,
      birthDate,
      email,  
      fullName,
      genderType,
      patientID,
      phone
      } = payload;
    state.patientInfo = { 
      patientID: patientID,
      address: address,
      avatar: avatar,
      phone: phone, 
      genderType: genderType,
      email: email,
      birthDate: moment(birthDate).format('DD-MM-YYYY'),
      age: new Date().getFullYear() - moment(birthDate).format('YYYY'),
      fullName: fullName,
      gender: (genderType == 1) ? 'Nam' : 'Nữ'
    }
    console.log(state.patientInfo.fullName)
  },
  ADD_MEDICATIONS(state, payload) {
    state.medications.push(...payload);
  },
  UPDATE_MEDICATIONS(state, payload) {
    state.medications = payload
  },
  REMOVE_MEDICATIONS(state, medicationID) {
    const index = state.medications.findIndex(item => item.id === medicationID)
    state.medications.splice(index, 1)
  },

  ADD_SERVICES(state, payload) {
    state.services.push(...payload);
  },
  UPDATE_SERVICES(state, payload) {
    state.services = payload
  },
  REMOVE_SERVICES(state, serviceID) {
    const index = state.services.findIndex(item => item.id === serviceID)
    state.services.splice(index, 1)
  },

  ADD_MATERIALS(state, payload) {
    state.materials.push(...payload);
  },
  UPDATE_MATERIALS(state, payload) {
    state.materials = payload
  },
  REMOVE_MATERIALS(state, materialID) {
    const index = state.materials.findIndex(item => item.id === materialID)
    state.services.splice(index, 1)
  },


  UPDATE_FOLLOW_UP_OBJECT(state, payload) {
    state.followUpObj = payload
  },

}

const actions = {
  
}

export default {
  namespaced: 'Patient',
  state,
  getters,
  mutations,
  actions,
}