<template>
  <v-tabs
    v-model="spec"
    vertical
    color="grey darken-1"
    class="cover-test-spec-tabs"
  >
    <v-tab v-for="item in specs" :key="item">
      <v-badge
        v-if="hasDataPoint(`${pathPrefix}/${item}`)"
        color="green darken-2"
        dot
        offset-x="-8"
        offset-y="10"
      >
        {{ Archetypes[item].name }}
      </v-badge>
      <span v-else>{{ Archetypes[item].name }}</span>
    </v-tab>
    <v-tabs-items v-model="spec">
      <v-tab-item class="pl-5" eager v-for="item in specs" :key="item">
        <COVTTDAComponent
          :dataPoints="dataPoints"
          :pathPrefix="`${pathPrefix}/${item}`"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import COVTTDAComponent from "@/components/examination/components/Archetypes/COVTTDAComponent";

export default {
  components: {
    COVTTDAComponent,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      spec: 0,
      specs: ["COVTDIS", "COVTNEA"],
    };
  },
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    hasDataPoint(prefix) {
      return this.validateHighLightDataPoint(
        this.dataPoints.find((d) => d.path.includes(prefix))
      );
    },
  },
};
</script>

<style lang="scss">
.cover-test-spec-tabs.v-tabs {
  .v-tabs-slider {
    width: 0px;
  }
  > .v-tabs-bar {
    border-top: 1px solid rgba(0, 0, 0, 0.6);
    .v-tab {
      border: 1px solid rgba(0, 0, 0, 0.6);
      margin-top: -1px;
      font-size: 11px !important;
      font-family: "Montserrat" !important;
      color: #424242 !important;
      min-width: 200px;
      justify-content: left;
    }
    .v-tab--active {
      background: #eeeeee;
      font-weight: bold;
    }
  }
  .v-tabs-bar.v-item-group > * {
    cursor: initial;
  }
}
</style>