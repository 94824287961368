export default {
  "flow": {
    "Flow": "Flow",
    "Title": "Title",
    "Description": "Description",
    "SearchTerm": "SearchTerm",
    "FlowID": "FlowID",
    "DateCreated": "DateCreated",
    "Actions": "",
    "CreateBtn": "Create",
    "SaveBtn": "Save",
  },
  "analysis": {
    "Analysis": "Analysis",
    "AnalysisID": "ID",
    "AnalysisMethods": "Analysis Methods",
    "StatusOptions": "Select Status",
    "StatusID": "Status",
    "PatientOptions": "Select Patient",
    "AnalysisName": "Analysis Name",
    "DateCreated": "Date Created",
    "Actions": "",
    "CreateNewAnalysis": "Create New Analysis",
    "PatientName": "Patient Name",
    "Progress": "Progress / Results",
    "ErrorDueToEmptyUrl": "Error Due To Empty Url",
    "ErrorOccuredDuringAnalysis": "An Error Occured During Analysis",
    "Heatmap": "Heatmap",
    "Result": "Result",
  },
  "common": {
    "select_lang": "Ngôn ngữ",
    "confirmMessage": "Are you want to complete this action ?",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "cancelStep": "Cancel this step",
    "Save": "Save",
    "print": "Print",
    "SaveData": "Save Data",
    "add": "Add",
    "edit": "Edit",
    "update": "Cập nhật",
    "select": "Select",
    "finish": "Finish",
    "passRe": "Password is required",
    "pass10Re": "Password must be less than 10 characters",
    "usernameRe": "Username is required",
    "usernameInRe": "Username must be valid",
    "dishchargeSummary": "Dishcharge Summary",
    "medicinePrescription": "Medicine Prescription",
    "glassPrescription": "Rimmed glass",
    "softClClass": "Soft-cls-glass",
    "HardClClass": "Hard-cls-glass",
    "OrthoKClClass": "Orthok-cls-glass",
    "viewMore": "View More",
    "editProfile": "Edit Profile",
    "saveProfile": "Save Profile",
    "overview": "Overview",
    "Progress": "Progress",
    "Analysis": "Analysis",
    "noData": "No data yet.",
    "rightEye": "Right Eye",
    "leftEye": "Left Eye",
  },
  "package": {
    "packageName": "Package Name",
    "type": "Type",
    "doctor": "Doctor",
    "room": "Room",
    "originalPrice": "Original Price",
    "discountPrice": "Discount Price",
    "free": "Free",
    "promotion": "Promotion",
    "addTitle": " Choose Assetment / Procedure To Add Using the Toolbar above",
    "total": "Total",
    "discount": "Discount",
    "finalPrice": "Final Price",
  },
  "quickAccess": {
    "orderService": "Order Service",
    "clinicalNote": "Clinical Note",
    "doctorCalendar": "Doctor calendar"
  },
  "personalInfo": {
    "aid": "Athena ID",
    "selectAvatar": "Select Camera",
    "Chụp hình": "Camera",
    "title": "Personal Information",
    "fullName": "Full Name*",
    "genderType": "Gender Type*",
    "DOB": "Date of birth*",
    "address": "Address",
    "phone": "Phone",
    "id": "Identification Number",
    "occupation": "Occupation",
    "country": "Country",
    "ethnicity": "Ethnicity",
    "healthInsurrance": "Health Insurrance",
    "insurance": "Insurance (*)",
    "insuranceType": "Insurance Type (*)",
    "public": "Public",
    "private": "Private",
    "insuranceNumber": "Insurance Number (*)",
    "yes": "Yes",
    "No": "No",
    "selectEth": "Press enter to use this ethicity",
    "medicalCondition": "Medical Condition Information",
    "lastVisitOf": "Last visit of",
    "lastVisit": "Last visit",
    "clinic": "Clinic",
    "service": "Service",
    "primaryDiagnosis": "Primary Diagnosis",
    "viewChecking": "View Checking",
    "socialInsuranceInformation": {
      "status": "Status",
      "title": "Social Insurance Information",
      "patientInfo": "Patient Information",
      "note": "Note",
      "currentInsuranceCard": "Current Insurance Card",
      "transferAnotherInsuranceCard": "Transfer Another Insurance Card",
      "historyExamination": "History Examination",
    }
  },
  "relativeInfo": {
    "title": "Relative Information",
    "relativeName": "Relative's Member Name",
    "address": "Address",
    "relationship": "Relationship",
    "genderType": "Gender Type",
    "phone": "Phone",
    "DOB": "Date of Birth",
    "cancel": "Cancel",
    "saveProfile": "Save Profile",
  },
  "login": {
    "title": "Sign in EMR",
    "title_intro": "Don't have an account? ",
    "title_intro1": "Contact ",
    "title_username": "Username",
    "title_password": "Password",
    "buttons": {
      "login": "Sign In",
      "language": "Language"
    },
    "input_text": {
      "user": "Username",
      "password": "Password"
    },
    "messages_Intro": {
      "title": "Electronic Medical Record",
      "text_1": "This software is part of",
      "text_slogan": "HIS Management System",
      "text_2": "for Hospital",
    }
  },
  "main": {
    "tab_left": {
      "tab_workList": "WorkList",
      "tab_calendar": "Calendar",
      "tab_doctorRoster": "Doctor Roster",
      "tab_patient": "Patient",
      "tab_report": "Report",
      "tab_medicine": "Medicine",
      "tab_form": "Form",
    },

    "workList": {
      "search": "Press enter to search",
      "allWorkList": "All worklist",
      "patientName": "Patient Name",
      "genderType": "Gender",
      "dateOfBirth": "DOB (Age)",
      "service": "Service",
      "checkIn_Time": "Check-in Time",
      "admissionType": "Admission type",
      "chiefComplaint": "Chief complain",
      "status": {
        "waiting": "Waiting",
        "inProgress": "In Progress",
        "complete": "Complete"
      },
      "tab_head_checkIn": "Check-In",
      "tab_head_booking": "Booking",
      "tab_head_note": "Note"
    },
    "calendar": {
      "btnCreate": "Create Event",
      "selectUser": "Select users",
      "searchPatientName": "Search Patient's name",
      "noResult": "No results match",
      "today": "Today",
      "day": "Day",
      "week": "Week",
      "month": "Month",
      "4days": "4 days",
      "totalBooking": "Total Booking",
      "patient_status": {
        "notComming": "Not Comming",
        "inComming": "In Comming",
        "late": "Late"
      },
      "roster": {
        "title_create": "Event Information",
        "titlle_event": "Title event",
        "saveEvent": "Save Event",
        "deleteEvent": "Delete Event",
        "notNullTitle": "Title is required!",
        "timeZone": {
          "reapeatEvent": "Repeat Event",
          "dosentRepeat": "Dose not repeat",
          "dayOfWeek": "Days of week",
          "weekOfMonth": "Weeks of month",
          "monthOfYear": "Months of year",
          "inOffice": "In Office",
          "outOffice": "Out of Office",
          "vacation": "Vacation",
          "absence": "Absence",
          "reserved": "Reserved",
          "guests": "Guests"
        },
        "message": {
          "errorCharacter": "Title must be more than 3 characters!",
          "createSuccess": "Save event information successfully.",
          "addOrUpdateSuccess": "Add or update Roster success!",
          "deleteSuccess": "Delete Roster success!",
          "deleteError": "Cannot delete this event. Please try again later!",
        },
      }
    },
    "patient": {
      "patientID": "Patient ID",
      "patientName": "Patient Name",
      "gender": "Gender",
      "dateOfBirth": "DOB(Age)",
      "email": "Email",
      "phone": "Phone",
      "lastVisits": "Last Visits",
      "service": "Service(s)",
      "searchPatientOptions": {
        "searchName": "Search Name",
        "pID": "PID",
        "DOB": "DOB",
        "phone": "Phone"
      }
    },
    "report": {
      "tab": {
        "finish": "Finish",
        "paid": "Paid",
      },
      "search": "Press enter to search",
      "dosentPatient": "Patient have not come to clinic yet!",
    },
    "medicine": {
      "listMedicine": "List Medicine",
      "add": "Add",
      "keySearch": "key Search",
      "newMedicine": "New medicine",
      "editMedicine": "Edit medicine",
      "medicineTable": {
        "name": "Name",
        "description": "Description",
        "medicineRoute": "Medicine Route",
        "instructionsForUse": "Instructions for use",
        "unitTime": "Unit/times",
        "timeDay": "Times/Day",
        "status": "Status",
        "per": "Per",
        "in": "In",
        "days": "days",
        "months": "months",
        "year": "years",
        "weekss": "weeks",
        "number": "number"
      },
      "medicineForm": {
        "name": "Name",
        "requestQ": "How many times in the day?",
        "description": "Description",
        "medicineRoute": "Medicine Route",
        "unitType": "Unit Type",
        "unitNT": "Usage Number & Unit type",
        "perDay": "Per/day",
        "amountBuyUnit": "Amount & Buying Unit",
        "total": "Total",
        "status": "Status",
        "validation": {
          "nameRe": "Name is required",
          "nameRe3": "Name must be less than 3 characters",
          "descriptionRe": "Description is required",
          "InputRe": "Input required more 3 characters",
          "usageRe": "Default usage num is required",
          "invalidFormat": "Not valid format!",
          "typeUnitRe": "Type Unit is required",
          "perDayRe": "Per/Day is required",
        }
      },
    },
    "form": {
      "search": "Press enter to search",
      "createForm": "Create form",
      "description": "Description",
      "amountComponent": "Amount Component",
      "listNameComponent": "List Name Component",
      "Actions": "Actions",
      "listComponent": "List Component",
      "searchComponent": "Search component",
      "formDetail": "Form Detail",
      "layout": "Layout",
      "form": "Form",
      "formName": "Form Name",
      "description_1": "(description)",
      "positionForm": "Drag & drop components from the right",
      "saveForm": "Save Form Design"
    },
    "patientInfoLine": {
      "DOB": "DOB",
      "phone": "Phone",
      "gender": "Gender",
      "address": "Address",
    },
    "admissionInfoLine": {
      "admissionTime": "Admission Time",
      "admissionType": "Admission Type",
      "chiefComplaints": "Chief Complaints",
      "none": "None"
    },
    "overview": {
      "select": "Select",
      "allergies": "Allergies",
      "personalBehaviours": "Personal Behaviours",
      "chronicDiseases": "Chronic Diseases",
      "pastHistoryConditions": "Past History Conditions",
      "lastVisits": "Last Visits",
      "clinicalNote": "Clinical Note",
      "previousNote": "Previous Note",
      "noHeathStatistics": "No health statistics yet!",
      "clinicTableNote": {
        "date": "Date",
        "description": "Description",
        "staffName": "Staff Name",
        "note": "Note",
        "action": "Actions",
        "noVisit": "Patient have no visits yet.!"
      },
      "chiefComplain": "Chief Complain",
      "diagnosis": "Diagnosis",
      "service": "Service(s)",
    },
    "generalExamination": {
      "generalExTitle": "General Examination",
      "date": "Date",
      "right": "Right",
      "left": "Left",
      "noData": "No data found yet."
    },
    "orthancImage": {
      "title": "Image",
      "date": "Date",
      "imageLink": "Image Link",
      "alerErr": "Only Cao Thang network can access this data!",
      "noData": "No data found yet."
    },
    "visualAcuity": {
      "title": "Visual Acuity",
      "date": "Date",
      "right": "Right",
      "left": "Left",
      "noData": "No data point yet.",
    },
    "progressDiagnosis": {
      "title": "Diagnosis",
      "date": "Ngày",
      "staff": "Staff",
      "diagnosis": "Diagnosis",
    },
    "progressPrescription": {
      "title": "Prescription",
      "date": "Date",
      "prescription": "Prescription",
    },
    "progressIOP": {
      "title": "IOP",
      "date": "Date",
      "right": "Right",
      "left": "Left",
    },
    "userType": {
      "admin": "Admin",
      "doctor": "Doctor",
      "root": "Root",
      "optomologist": "Optomologist",
      "receptionist": "Receptionist",
      "nurse": "Nurse",
      "technician": "Technician",
    },

    "language": "Language",
    "logOut": "Log out",
  },
  "examination": {
    "title": "Examination",
    "staffOrder": "Staff Order",
    "refactornoAndVSA": "Refraction measurement and Visual acuity measurement",
    "refactorno": "Refraction measurement",
    "visualAcuityMeasurement": "Visual acuity measurement",
    "staffPerform": "Staff Perform",
    "optometrist": "Optometrist",
    "doctor": "Doctor",
    "examination": "Examination",
    "chiefComplain": "Chief-Complaints",
    "generalCheckUp": {
      "title": "General-Check-Up",
      "generalOptomologyTest": "General Optomology Test"
    },

    "diagnosis": {
      "title": `Diagnosis`,
      "rightEye": "Right Eye (ICDD 10)",
      "leftEye": "Left Eye (ICDD 10)",
      "primaryDiagnosis": "Primary Diagnosis",
      "additionalDiagnosis": "Additional Diagnosis",
      "suspectedDiagnosis": "Suspected Diagnosis",
      "searchICDDCode": "Search ICDD Code 10",
      "bothEye": "Both eyes",
    },
    "conclusion": {
      "title": "Conclusion"
    },
    "treatmentPlan": {
      "title": `Treatment Plan`,
      "medicinePrescription": "Medicine Prescription",
      "listMedication": "List Medication",
      "medicationNote": "Medication Note",
      "emptyMed": "Empty! Nothing is here!",
      "medication": "Medication",
      "instruction": "Instruction",
      "instructionType": "Type...",
      "total": "Total",
      "insertMedicationInfo": "Insert an medication",
      "newMed": "New medicine",
      "addMedication": "Add Medication",
      "searchMedicine": "Search Medicine",
      "unitType": "Unit Type",
      "glassPrescription": "Glass Prescription",
      "clsPrescription": "Cls Prescription",
      "soft": "Soft",
      "Hard": "Hard",
      "rightEye10": "Right Eye (ICD 10)",
      "leftEye10": "Left Eye (ICD 10)",
      "searchICD": "Search ICD Code 10",
      "select": "Select",
      "bothEye": "Both eyes",
      "primaryDiagnosis": "Primary Diagnosis"
    },
    "optometryTreatment": {
      "title": "Optometry Treatment"
    },
    "optometristConclusion": {
      "title": "Optometrist Conclusion"
    },
    "followUp": {
      "title": "Follow Up",
      "list": "List",
      "noteBooking": "Note",
      "booking": "Booking",
      "bookingFrom": "Booking from",
      "noteFrom": "Note From",
      "createANew": "Create a new",
      "number": "Number",
      "day": "Day",
      "week": "Week",
      "month": "Month",
      "time": "Time (Hour)",
      "admissionType": "Admission Type",
      "service": "Service",
      "note": "Note",
      "holiday": "Holiday",
      "createFollowUp": "Create A Follow Up",
      "updateFollowUp": "Update a follow up",
      "noneFollowUp": "Empty !",
      "listFollowOfDay": "List follow-up in ",
      "listFollowNext": "List follow-up (next time)"
    },
    "dischargeSummary": {
      "title": "Discharge Summary",
      "optometristSummary": "Optometrist Summary",
      "chiefComplain": "Chief Complain",
      "noComplain": "No complain",
      "image": "Images",
      "noImage": "No image",
      "conclusion": "Conclusion",
      "noConclusion": "No conclusion",
      "followUp": "Follow up",
      "noFollowUp": "No follow-up",
      "glassPrescription": "Glass Prescription",
      "printGlass": "Print Glass Prescription",
      "doctorSummary": "Doctor summary",
      "service": "Service",
      "price": "Price",
      "referral": "Referral",
      "noReferral": "No Referral",
      "noMedicine": "No medication prescribed yet.",
      "medName": "Medicine Name",
      "intruction": "Intruction",
      "dischargeMode": "Discharge Mode",
      "patientSatisfaction": "Patient Satisfaction",
      "diagnosis": "Diagnosis",
      "noDiagnosis": "No diagnosis",
      "noAxPx": "No procedure/accessment",
      "nameStaff": "Name",
      "position": "Position",
      "examination": "Examination",
      "noExam": "No examniations found.",
      "action": "Action",
      "referralLetter":
      {
        "title": "Referral Letter",
        "clinic": "Clinic",
        "doctor": "Doctor",
        "package": "Package",
        "chooseService": "Choose service",
        "addTitle": "Choose Assetment / Procedure To Add Using the Toolbar above",
        "note": "Note",
      }
    },
    "doctorTreatmentPlan": {
      "title": "Doctor Treatment Plan"
    },
  },
  "print": {
    "fullName": "Full Name",
    "gender": "Gender",
    "address": "Address",
    "dateOfBirth": "Date of birth",
    "profileID": "Profile ID",
    "age": "Age",
    "glassPrescription": "Glass Prescription",
    "medicinePrescription": "Medicine Prescription",
    "contactLensPrescription": "Contact Lens Prescription",
    "dischargeSummary": "Discharge Print",
    "paymentSummary": "Payment Summary",
    "contactLen": {
      "glassType": "Glass Type",
      "parameterGlass": "Name",
      "parameter": "Parameter",
      "type": "Type",
      "degreeGlass": "Degree",
      "note": "Note",
      "date": "Date",
      "staffHandler": "Technicians",
    },
    "discharge": {
      "title": "Discharge Summary",
      "optometristSummary": "Optometrist Summary",
      "chiefComplain": "Chief Complain",
      "noComplain": "No complain",
      "image": "Images",
      "noImage": "No image",
      "conclusion": "Conclusion",
      "noConclusion": "No conclusion",
      "followUp": "Follow up",
      "noFollowUp": "No follow-up",
      "glassPrescription": "Glass Prescription",
      "printGlass": "Print Glass Prescription",
      "doctorSummary": "Doctor summary",
      "service": "Service",
      "price": "Price",
      "referral": "Referral",
      "noReferral": "No Referral",
      "noMedicine": "No medication prescribed yet.",
      "medName": "Medicine Name",
      "intruction": "Intruction",
      "dischargeMode": "Discharge Mode",
      "noDischargeMode": "No discharge mode",
      "noPatientSatisfaction": "No patient satisfaction",
      "patientSatisfaction": "Patient Satisfaction",
      "diagnosis": "Diagnosis",
      "noDiagnosis": "No diagnosis",
      "noAxPx": "No procedure/accessment",
      "nameStaff": "Name",
      "position": "Position",
      "examination": "Examination",
      "noExam": "No examniations found.",
      "toView": "(To view this discharge summary)",
      "date": "Date",
      "approver": "Approver",
      "referralOrder": "Referral Order",
      "medicine": "Medicine",
      "referralLetter":
      {
        "title": "Referral Letter",
        "clinic": "Clinic",
        "noClinic": "No clinic",
        "noDoctor": "No doctor",
        "noDescription": "No description",
        "doctor": "Doctor",
        "package": "Package",
        "chooseService": "Choose service",
        "addTitle": "Choose Assetment / Procedure To Add Using the Toolbar above",
        "note": "Note",
      }
    }
  },
  "message": {
    "optometristConclusion": "Successfully save conclusion information.",
    "dischargeMode": {
      "finishExamination": "Successfully finish examination.",
      "deleteAxPx": "Delete AxPx in this referral success.",
    },
    "referralPopUp": "Successfully save referral information.",
    "axPxPopUp": "Successfully added new services into episode.",
    "episodeMedication": {
      "updateMedication": "Successfully update medication.",
      "deleteMedication": "Successfully remove medication.",
    },
    "popUpmedicine": "Successfully remove medicine.",
    "clinicalNoteTable": "Delete clinical note success.",
    "clinicalNotePopUp": "Save successfully.",
    "formDetail": "Save form detail successfully",
    "chiefComplain": "Save successfully",
    "examination": {
      "saveDatapoint": "Successfully completed the order",
      "cancelCurrentOrder": "Successfully cancel this order item",
    },
    "generalCheckUp": "Successfully completed the order!",
    "listMedicationNote": "Successfully added medication note into episode record.",
    "followUp": "Create a follow-up successfully!",
    "rosterRegistration": "Save event information successfully.",
    "medicineContainer": "Create new medicine successfully!",
    "popUpMedicine": "Create new medicine successfully!",
    "workList":
    {
      "step": "Examination ",
      "isFinish": " has been completed successfully !",
      "patient": "Bệnh nhân ",
      "newPatientAlert": "New patient has been successfully checked-in at your clinic.",
    }
  },
  Patient: {
    Patient: 'Patients',
    Patients: 'Patients',
    Avatar: 'Avatar',
    Create: 'New Patient',
    SelectPatient: 'Select Patient',
    SearchPatient: 'Searching Patient',
    SearchName: 'Search Name',
    PatientInformation: 'Patient Information',
    PatientName: 'Patient Name',
    Name: 'Name',
    Gender: 'Gender',
    DOB: 'DOB',
    PID: 'Patient ID',
    AID: 'Athena ID',
    Age: 'Age',
    Phone: 'Phone',
    TotalPatient: 'Total Patient',
    FullName: 'Full Name',
    TopPatients: 'Top Patients',
    CalculatedAt: 'Calculated at',
  },
  bookingList: 'Danh sách đặt chỗ',
}