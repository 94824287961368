<template>
  <div>
    <h4 v-if="!hideName" class="mb-2 font-weight-bold">{{ schema.name }}</h4>
    <!-- CHECKBOX COMPONENT -->
    <div v-if="schema.type == 'checkbox'">
      <v-checkbox
        dense
        hide-details
        v-for="opt in schema.options"
        :key="opt"
        :label="opt"
        :value="opt"
        v-model="value"
        @change="handleDataChange"
        class="float-left"
      ></v-checkbox>
    </div>
    <!-- RADIO COMPONENT -->
    <div v-if="schema.type == 'radio'">
      <v-radio-group row v-model="value" @change="handleDataChange">
        <v-radio
          dense
          hide-details
          v-for="opt in schema.options"
          :key="opt"
          :label="opt"
          :value="opt"
          class="float-left mb-2"
        ></v-radio>
      </v-radio-group>
    </div>
    <!-- OTHER TYPES OF COMPONENTS -->
    <component
      v-if="
        schema.type != 'checkbox' &&
        schema.type != 'radio' &&
        schema.type != 'link' &&
        is
      "
      :is="is"
      :multiple="multiple"
      :label="schema.name"
      :items="schema.options"
      v-model="value"
      @change="handleDataChange"
      rows="2"
      outlined
      dense
    ></component>
    <!-- archetype Link -->
    <div class="d-flex flex-column" v-if="schema.type == 'link'">
      <div class="d-flex align-center pa-1">
        <v-text-field
          class="mr-1"
          style="width: 50%"
          :label="schema.name"
          v-model="value"
          @change="handleDataChange"
          rows="2"
          outlined
          dense
        ></v-text-field>
        <v-btn
          dark
          icon
          color="#007bff"
          v-if="
            /(http|https){0,1}:{0,1}(\/\/){0,1}(www.){0,1}(\w+.){1,}(\/|(\w+))$/.test(
              value
            ) && value.length > 0
          "
          @click="openTabLink(value)"
        >
          <v-icon> mdi-open-in-new </v-icon>
        </v-btn>
      </div>

      <div class="d-block pa-1 h-auto">
        <iframe
          style="height: 400px"
          v-if="
            /(http|https){0,1}:{0,1}(\/\/){0,1}(www.){0,1}(\w+.){1,}(\/|(\w+))$/.test(
              value
            ) && value.length > 0
          "
          class="w-100"
          :src="value"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VCheckbox,
  VSelect,
  VTextField,
  VTextarea,
  VSwitch,
} from "vuetify/lib";

export default {
  components: {
    VCheckbox,
    VSelect,
    VTextField,
    VTextarea,
    VSwitch,
  },
  props: {
    hideName: {
      type: Boolean,
      default: false,
    },
    collections: {
      type: Array,
      default: function () {
        return [];
      },
    },
    path: {
      type: String,
      default: "/",
    },
    schema: {
      type: Object,
      // example schema object
      default: function () {
        return {
          // archetypeCode == archetypeID
          archetypeID: "OD",
          // name of the archetype
          name: "Right Eye",
          // input type
          type: "select",
          // select-options (optionals)
          options: [true, false],
          // value of this input
          default: true,
        };
      },
    },
  },
  computed: {
    is() {
      if (!this.schema.type || this.schema.type == "link") return null;
      return "v-" + this.schema.type.split(":")[0];
    },
    multiple() {
      // by default: checkbox and radio are multiple
      return (
        this.schema.type == "checkbox" ||
        this.schema.type == "radio" ||
        this.schema.type.split(":").pop() == "multiple"
      );
    },
  },
  data() {
    return {
      value: this.schema.default,
    };
  },
  watch: {
    // When the entire data-point collections changed (loaded from Server)
    // => find the correct data point and map into value
    collections: {
      handler() {
        this.value = this.getDataValueByPathFromCollections();
      },
    },
  },
  created() {
    this.value = this.getDataValueByPathFromCollections();
  },
  methods: {
    resetValueGLO(value) {
      this.value = value;
    },
    // functions that map data value from collections
    // or get default value from schema if not any
    getDataValueByPathFromCollections() {
      var dataPoint = this.collections.find((item) => item.path == this.path);
      // if cannot find data point, return the default value from schema setting
      if (!dataPoint) {
        // first check default data type, can be string or []
        var defaultValue = this.schema.default;
        // if it is a string and not null
        if (defaultValue && typeof defaultValue == "string") {
          // emit the change event to update the data into collections
          this.handleDataChange(defaultValue);
        }
        // if it is an Array and not null and have atleast 1 element
        if (
          defaultValue &&
          Array.isArray(defaultValue) &&
          defaultValue.length > 0
        ) {
          // emit the change event to update the data into collections
          this.handleDataChange(defaultValue);
        }
        return defaultValue;
      }
      // if can find the data-point
      // get the data point value string
      var value = dataPoint.value;
      // if options are available
      if (value.includes("|")) {
        // get the value string
        value = value.split("|")[0];
        // if options are multiple
        if (this.multiple) {
          // if radio => value is single
          if (this.schema.type == "radio") {
            // do nothing and return the string result
            // else value is multiple
          } else {
            value = value.split(";");
          }
        }
      }
      this.handleDataChange(value);
      return JSON.parse(JSON.stringify(value));
    },
    parseData(value) {
      this.value = value;
      this.handleDataChange(value);
    },
    handleDataChange(val) {
      var value = val;
      // if the value is multiple => join values with ";"
      if (Array.isArray(value)) {
        value = value.join(";");
      }
      var { archetypeID, options } = this.schema;
      // => join options into value with ";"
      if (Array.isArray(options)) {
        value += `|${options.join(";")}`;
      }
      var path = this.path;
      var respone = {
        archetypeID,
        path,
        value,
        val,
      };
      this.$emit("input", respone);
      this.updateDataValueByPathIntoCollections(respone);
    },
    updateDataValueByPathIntoCollections({ archetypeID, path, value }) {
      var ind = this.collections.findIndex((item) => item.path == this.path);
      // if no data-point like this added yet
      if (ind < 0) {
        this.collections.push({ archetypeID, path, value });
        return;
      }
      if (value == "" || value == [] || value == undefined) {
        this.collections.splice(ind, 1);
        return;
      }
      if (value.includes("|")) {
        var value2 = value.split("|")[0];
        if (value2 == "") {
          this.collections.splice(ind, 1);
          return;
        }
      }
      // else => existed the data point
      this.collections[ind].value = value;
    },
    openTabLink(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss">
.examination-form {
  .row {
    margin: 0px;
  }
  .v-text-field.v-text-field--enclosed {
    .v-text-field__details {
      display: none;
    }
  }
  .v-input--selection-controls {
    margin-top: 2px;
    margin-bottom: 2px;
    padding-top: 0px;
    padding-right: 20px;
    min-width: 180px;
    // width: 100%;
    height: 20px;
  }
  .form-table {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-right: 0px;
    > .row {
      margin: 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.6);
      margin-bottom: -1px;
      .col {
        border-right: 1px solid rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>