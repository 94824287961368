export default {
  "flow": {
    "Flow": "Sơ đồ chuẩn đoán",
    "Title": "Tên",
    "Description": "Mô tả",
    "SearchTerm": "Từ khóa tìm kiếm",
    "FlowID": "ID",
    "DateCreated": "Ngày tạo",
    "Actions": "",
    "CreateBtn": "Tạo mới",
    "SaveBtn": "Lưu lại",
  },
  "analysis": {
    "Analysis": "Chuẩn đoán",
    "AnalysisID": "Lần chuẩn đoán",
    "AnalysisMethods": "Phương pháp chuẩn đoán",
    "StatusOptions": "Trạng thái",
    "StatusID": "Trạng thái",
    "PatientOptions": "Chọn bệnh nhân",
    "AnalysisName": "Loại",
    "DateCreated": "Ngày",
    "Actions": "",
    "CreateNewAnalysis": "Tạo chuẩn đoán mới",
    "PatientName": "Bệnh nhân",
    "Progress": "Tiến độ / kết quả",
    "ErrorDueToEmptyUrl": "Lỗi không xem được link không xác định",
    "ErrorOccuredDuringAnalysis": "Gặp lỗi khi đang xử lý",
    "Heatmap": "Tiên lượng",
    "Result": "Kết quả",
  },
  "common": {
    "select_lang": "Ngôn ngữ",
    "confirmMessage": "Bạn có muốn hoàn thành tác vụ này ?",
    "confirm": "Đồng ý",
    "cancel": "Hủy",
    "cancelStep": "Hủy bước thực hiện",
    "Save": "Lưu",
    "print": "In",
    "add": "Thêm",
    "edit": "Sửa",
    "update": "Cập nhật",
    "select": "Chọn",
    "SaveData": "Lưu dữ liệu",
    "finish": "Hoàn thành",
    "passRe": "Yêu cầu mật khẩu",
    "pass10Re": "Mật khẩu ít nhất 10 ký tự",
    "usernameRe": "Yêu cầu tài khoản",
    "usernameInRe": "Tài khoản không hợp lệ",
    "dishchargeSummary": "Đơn xuất viện",
    "medicinePrescription": "Đơn thuốc",
    "glassPrescription": "Đơn kính",
    "softClClass": "Kính mềm",
    "HardClClass": "Kính cứng",
    "OrthoKClClass": "Kính Ortho-K",
    "viewMore": "Xem thêm",
    "editProfile": "Chỉnh sửa thông tin",
    "saveProfile": "Lưu thông tin",
    "overview": "Tổng quan",
    "Progress": "Tiến độ sức khỏe",
    "Analysis": "Chuẩn đoán",
    "noData": "Không có dữ liệu.",
    "rightEye": "Mắt phải",
    "leftEye": "Mắt trái",
  },
  "quickAccess": {
    "orderService": "Đăng kí dịch vụ",
    "clinicalNote": "Lưu ý lâm sàng",
    "doctorCalendar": "Lịch bác sĩ"
  },
  "package": {
    "packageName": "Tên gói",
    "type": "Loại",
    "doctor": "Bác sĩ",
    "room": "Phòng",
    "originalPrice": "Giá gói",
    "discountPrice": "Giá giảm",
    "free": "Miễn phí",
    "promotion": "Khuyến mãi",
    "addTitle": "Chọn Assessment/ Procedure để thêm vào ở thanh công cụ trên",
    "total": "Tổng",
    "discount": "Giá giảm",
    "finalPrice": "Giá cuối cùng",
  },
  "personalInfo": {
    "aid": "Athena ID",
    "selectAvatar": "Chọn hình",
    "Chụp hình": "Chụp hình",
    "title": "Thông tin cá nhân",
    "fullName": "Họ và tên*",
    "genderType": "Giới tính*",
    "DOB": "Ngày sinh*",

    "address": "Địa chỉ",
    "phone": "Số điện thoại",
    "id": "CMND/CCCD",
    "occupation": "Nghề nghiệp",
    "country": "Quốc gia",
    "ethnicity": "Dân tộc",
    "healthInsurrance": "Bảo hiểm y tế",
    "insurance": "Bảo hiểm(*)",
    "insuranceType": "Loại bảo hiểm (*)",
    "public": "BHYT",
    "private": "BH tư nhân",
    "insuranceNumber": "Số bảo hiểm (*)",
    "yes": "Có",
    "No": "Không",
    "selectEth": "Nhấn enter để chọn dân tộc này",
    "medicalCondition": "Thông tin tình trạng Y tế",
    "lastVisitOf": "Lần khám cuối cùng của",
    "lastVisit": "Lịch sử lần khám",
    "clinic": "Phòng khám",
    "service": "Dịch vụ",
    "primaryDiagnosis": "Chẩn đoán chính",
    "viewChecking": "Xem kiểm tra",
    "socialInsuranceInformation": {
      "status": "Tình trạng",
      "title": "Thông tin bảo hiểm xã hội",
      "patientInfo": "Thông tin bệnh nhân",
      "note": "Ghi chú",
      "currentInsuranceCard": "Thẻ bảo hiểm hiện tại",
      "transferAnotherInsuranceCard": "Chuyển đổi sang thẻ bảo hiểm khác",
      "historyExamination": "Lịch sử kiểm tra",
    }
  },
  "relativeInfo": {
    "title": "Thông tin người thân",
    "relativeName": "Tên người thân",
    "address": "Địa chỉ",
    "relationship": "Mối quan hệ",
    "genderType": "Giới tính",
    "phone": "Số điện thoại",
    "DOB": "Ngày sinh",
    "cancel": "Quay lại",
    "saveProfile": "Lưu thông tin",
  },
  "login": {
    "title": "Đăng nhập vào EMR",
    "title_intro": "Không có tài khoản? ",
    "title_intro1": "Liên hệ ",
    "title_username": "Tên tài khoản",
    "title_password": "Mật khẩu",
    "buttons": {
      "login": "Đăng nhập",
      "language": "Ngôn ngữ"
    },
    "input_text": {
      "user": "Tên đăng nhập",
      "password": "Mật khẩu"
    },
    "messages_Intro": {
      "title": "Hồ sơ y tế điện tử",
      "text_1": "Phần mềm này là một phần của",
      "text_slogan": "Hệ thống quản lý HIS",
      "text_2": "cho Bệnh viện",
    }
  },
  "main": {
    "tab_left": {
      "tab_workList": "Danh sách",
      "tab_calendar": "Lịch",
      "tab_doctorRoster": "Danh sách bác sĩ",
      "tab_patient": "Bệnh nhân",
      "tab_report": "Báo cáo",
      "tab_medicine": "Thuốc",
      "tab_form": "Biểu mẫu",
    },
    "workList": {
      "search": "Nhấn enter để tìm kiếm",
      "allWorkList": "Tìm tất cả",
      "patientName": "Tên bệnh nhân",
      "genderType": "Giới tính",
      "dateOfBirth": "Ngày sinh",
      "service": "Dịch vụ",
      "checkIn_Time": "Thời gian check-in",
      "admissionType": "Loại",
      "chiefComplaint": "Lý do đến khám",
      "status": {
        "waiting": "Đang chờ",
        "inProgress": "Đang thực hiện",
        "complete": "Hoàn thành"
      },
      "tab_head_checkIn": "Đăng kí",
      "tab_head_booking": "Đặt lịch",
      "tab_head_note": "Ghi chú"
    },
    "calendar": {
      "btnCreate": "Tạo sự kiện",
      "selectUser": "Chọn người dùng",
      "searchPatientName": "Tìm tên bệnh nhân",
      "noResult": "Không có dữ liệu trùng khớp",
      "today": "Hôm nay",
      "day": "Ngày",
      "week": "Tuần",
      "month": "Tháng",
      "4days": "4 ngày",
      "totalBooking": "Tổng số đặt lịch",
      "patient_status": {
        "notComming": "Không đến",
        "inComming": "Đang đến",
        "late": "Đến trễ"
      },
      "roster": {
        "title_create": "Thông tin sự kiện",
        "titlle_event": "Tên sự kiện",
        "saveEvent": "Lưu sự kiện",
        "deleteEvent": "Xóa sự kiện",
        "notNullTitle": "Tiêu đề không được bỏ trống !",

        "timeZone": {
          "reapeatEvent": "Lặp lại",
          "dosentRepeat": "Không lặp",
          "dayOfWeek": "Ngày trong tuần",
          "weekOfMonth": "Tuần trong tháng",
          "monthOfYear": "Tháng trong năm",
          "inOffice": "Trong văn phòng",
          "outOffice": "Ngoài văn phòng",
          "vacation": "Kỳ nghỉ",
          "absence": "Vắng mặt",
          "reserved": "Đã đặt",
          "guests": "Khách mời"
        },
        "message": {
          "errorCharacter": "Tiêu đề phải nhiều hơn 3 kí tự!",
          "createSuccess": "Lưu sự kiện thành công.",
          "addOrUpdateSuccess": "Thêm hoặc chỉnh sửa bảng phân công thành công!",
          "deleteSuccess": "Xóa bảng phân công thành công!",
          "deleteError": "Không thể xóa sự kiện này. Vui lòng thử lại sau!",
        },
      }
    },
    "patient": {
      "patientID": "Mã số bệnh nhân",
      "patientName": "Tên bệnh nhân",
      "gender": "Giới tính",
      "dateOfBirth": "Ngày sinh",
      "email": "Email",
      "phone": "Số điện thoại",
      "lastVisits": "Lần cuối truy cập",
      "service": "Dịch vụ",
      "searchPatientOptions": {
        "searchName": "Tìm theo tên",
        "pID": "Mã bệnh nhân",
        "DOB": "Ngày sinh",
        "phone": "Số điện thoại"
      }
    },
    "report": {
      "tab": {
        "finish": "Hoàn thành",
        "paid": "Đã thanh toán",
      },
      "search": "Nhấn enter để tìm kiếm",
      "dosentPatient": "Bệnh nhân chưa đến khám !",
    },
    "medicine": {
      "listMedicine": "Danh sách thuốc",
      "add": "Thêm",
      "keySearch": "Từ khóa",
      "newMedicine": "Thêm thông tin thuốc mới",
      "editMedicine": "Sửa thông tin thuốc",
      "medicineTable": {
        "name": "Tên thuốc",
        "description": "Chú thích",
        "instructionsForUse": "Cách dùng",
        "medicineRoute": "Đường dùng",
        "unitTime": "Đơn vị/số lần",
        "timeDay": "Số Lần /Ngày",
        "status": "Trạng thái",
        "per": "Mỗi lần",
        "in": "Trong",
        "days": "ngày",
        "months": "tháng",
        "weekss": "tuần",
        "number": "Số lượng"
      },
      "medicineForm": {
        "name": "Tên thuốc",
        "description": "Chú thích",
        "requestQ": "Bao nhiêu lần trong một ngày ?",
        "medicineRoute": "Đường thuốc",
        "unitNT": "Lượng dùng & Đơn vị dùng",
        "perDay": "Lượng dùng/Ngày",
        "timeADay": "lần/ngày",
        "amountBuyUnit": "Số lượng & Đơn vị",
        "total": "Tổng",
        "status": "Trạng thái",
        "validation": {
          "nameRe": "Tên thuốc không được bỏ trống",
          "nameRe3": "Tên thuốc cần nhiều hơn 3 kí tự",
          "descriptionRe": "Chú thích không được bỏ trống",
          "InputRe": "Trường yêu cầu nhiều hơn 3 kí tự",
          "usageRe": "Bắt buột điền số lượng mặc định",
          "invalidFormat": "Sai định dạng",
          "typeUnitRe": "Bắt buột điền loại đơn vị",
          "perDayRe": "Bắt buột điền Số lần/Ngày",
        }
      },
    },
    "form": {
      "search": "Nhấn enter để tìm kiếm",
      "createForm": "Tạo biểu mẫu",
      "description": "Chú thích",
      "amountComponent": "Số lượng biểu mẫu",
      "listNameComponent": "Danh sách tên biễu mẫu",
      "Actions": "Tác vụ",
      "listComponent": "Danh sách biểu mẫu",
      "searchComponent": "Tìm kiếm biễu mẫu",
      "formDetail": "Chi tiết biểu mẫu",
      "layout": "Giao diện",
      "form": "Biểu mẫu",
      "formName": "Tên biểu mẫu",
      "description_1": "(Chú thích)",
      "positionForm": "Kéo & thả biểu mẫu vào ô giao diện",
      "saveForm": "Lưu thiết kế"
    },
    "patientInfoLine": {
      "DOB": "Ngày sinh",
      "phone": "Số điện thoại",
      "gender": "Giới tính",
      "address": "Địa chỉ",
    },
    "admissionInfoLine": {
      "admissionTime": "Thời gian vào",
      "admissionType": "Loại",
      "chiefComplaints": "Lý do đến khám",
      "none": "Không"
    },
    "overview": {
      "select": "Chọn",
      "allergies": "Dị ứng",
      "personalBehaviours": "Hành vi cá nhân",
      "chronicDiseases": "Bệnh mãn tính",
      "pastHistoryConditions": "Tiền sử phơi nhiễm",
      "lastVisits": "Hoạt động",
      "clinicalNote": "Lưu ý lâm sàng",
      "previousNote": "Lưu ý trước",
      "noHeathStatistics": "Chưa có thống kê sức khỏe!",
      "clinicTableNote": {
        "date": "Ngày",
        "description": "Chú thích",
        "staffName": "Tên nhân viên",
        "note": "Ghi chú",
        "action": "Tác vụ",
        "noVisit": "Bệnh nhân chưa có thông tin khám !"
      },
      "chiefComplain": "Lý do đến khám",
      "diagnosis": "Chuẩn đoán",
      "service": "Dịch vụ",
    },
    "generalExamination": {
      "generalExTitle": "Khám tổng quát",
      "date": "Ngày",
      "right": "Bên phải",
      "left": "Bên trái",
      "noData": "Không có dữ liệu."
    },
    "orthancImage": {
      "title": "Hình ảnh",
      "date": "Ngày",
      "imageLink": "Đường dẫn hình ảnh",
      "alerErr": "Chỉ Cao Thắng được quyền truy cập vào dữ liệu này!",
      "noData": "Không có dữ liệu."
    },
    "visualAcuity": {
      "title": "Thị lực",
      "date": "Ngày",
      "right": "Bên phải",
      "left": "Bên trái",
      "noData": "Không có dữ liệu",
    },
    "progressDiagnosis": {
      "title": "Chuẩn đoán",
      "date": "Ngày",
      "staff": "Nhân viên",
      "diagnosis": "Chuẩn đoán",
      "noData": "Không có dữ liệu",
    },
    "progressPrescription": {
      "title": "Đơn thuốc",
      "date": "Ngày",
      "prescription": "Đơn thuốc",
    },
    "progressIOP": {
      "title": "IOP",
      "date": "Ngày",
      "right": "Bên phải",
      "left": "Bên trái",
    },
    "userType": {
      "admin": "Quản trị viên",
      "doctor": "Bác sĩ",
      "root": "Root",
      "optomologist": "Bác sĩ nhãn khoa",
      "receptionist": "Tiếp tân",
      "nurse": "Y tá",
      "technician": "Kỹ thuật viên",
    },

    "language": "Ngôn ngữ",
    "logOut": "Đăng xuất",
  },
  "examination": {
    "title": "Kiểm tra",
    "refactornoAndVSA": "Khúc xạ và thị lực",
    "staffOrder": "Nhân viên yêu cầu",
    "staffPerform": "Nhân viên thực hiện",
    "optometrist": "Người đo thị lực",
    "doctor": "Bác sĩ",
    "chiefComplain": "Lý do đến khám",
    "generalCheckUp": {
      "title": "Kiểm tra tổng quát",
      "generalOptomologyTest": "Kiểm tra Nhãn Khoa Tổng quát"
    },

    "diagnosis": {
      "title": "Chuẩn đoán",
      "rightEye": "Mắt phải (ICDD 10)",
      "leftEye": "Mắt trái (ICDD 10)",
      "primaryDiagnosis": "Chuẩn đoán chính",
      "additionalDiagnosis": "Chuẩn đoán phụ",
      "suspectedDiagnosis": "Chuẩn đoán khả nghi",
      "searchICDDCode": "Tìm mã ICDD 10",
      "bothEye": "Hai mắt",
    },
    "conclusion": {
      "title": "Kết luận"
    },
    "treatmentPlan": {
      "title": "Kế hoạch điều trị",
      "medicinePrescription": "Đơn thuốc",
      "sampleListMedication": "Danh sách toa thuốc mẫu",
      "medicationNote": "Dặn dò",
      "emptyMed": "Danh sách trống !",
      "medication": "Đơn thuốc",
      "total": "Tổng số",
      "newMed": "Thêm thuốc mới",
      "addMedication": "Thêm đơn thuốc",
      "searchMedicine": "Tìm thuốc",
      "insertMedicationInfo": "Thêm thông tin đơn thuốc",
      "instruction": "Dặn dò",
      "instructionType": "Gõ văn bản ...",
      "unitType": "Loại ĐV",
      "glassPrescription": "Đơn kính",
      "clsPrescription": "Đơn kính áp tròng",
      "soft": "Mềm",
      "Hard": "Cứng",
      "rightEye10": "Mắt phải (ICD 10)",
      "leftEye10": "Mắt trái (ICD 10)",
      "searchICD": "Tìm mã ICD 10",
      "select": "Chọn",
      "bothEye": "Hai mắt",
      "addTreatment": "Thêm trị liệu",
      "primaryDiagnosis": "Chuẩn đoán chính"
    },
    "optometryTreatment": {
      "title": "Điều trị đo thị lực"
    },
    "optometristConclusion": {
      "title": "Kết luận bác sĩ "
    },
    "followUp": {
      "title": "Theo dõi",
      "list": "Danh sách",
      "noteBooking": "Lịch chờ hẹn",
      "booking": "Lịch hẹn",
      "bookingFrom": "Đặt lịch từ",
      "noteFrom": "Ghi chú từ",
      "createANew": "Tạo mới",
      "number": "Số",
      "day": "Ngày",
      "week": "Tuần",
      "month": "Tháng",
      "time": "Thời gian (Giờ)",
      "admissionType": "Loại",
      "service": "Dịch vụ",
      "note": "Ghi chú ",
      "holiday": "Sự kiện",
      "createFollowUp": "Tạo mới theo dõi",
      "updateFollowUp": "Cập nhật theo dõi",
      "noneFollowUp": "Danh sách trống !",
      "listFollowOfDay": "Danh sách lịch hẹn vào",
      "listFollowNext": "Danh sách lịch hẹn (Sắp tới)"
    },
    "dischargeSummary": {
      "title": "Tóm tắt xuất viện",
      "optometristSummary": "Tóm tắt từ người đo thị lực",
      "chiefComplain": "Lý do đến khám",
      "noComplain": "Không có lý do .",
      "image": "Hình ảnh",
      "noImage": "Không có hình ảnh",
      "conclusion": "Kết luận",
      "noConclusion": "Không có kết luận",
      "followUp": "Theo dõi",
      "noFollowUp": "Không có theo dõi",
      "glassPrescription": "Đơn kính",
      "printGlass": "In đơn kính",
      "doctorSummary": "Tóm tắt bác sĩ",
      "service": "Dịch vụ",
      "diagnosis": "Chuẩn đoán",
      "noDiagnosis": "Không có chuẩn đoán",
      "noAxPx": "Không có procedure/accessment",
      "noSercice": "Bản tóm tắt xuất viện không có dịch vụ.",
      "price": "Giá",
      "referral": "Giới thiệu",
      "noReferral": "Không cso giới thiệu",
      "noMedicine": "Chưa có thuốc kê đơn.",
      "medName": "Tên thuốc",
      "intruction": "Cách dùng",
      "dischargeMode": "Lựa chọn xuất viện",
      "patientSatisfaction": "Mức độ hài lòng của bệnh nhân",
      "nameStaff": "Tên người thực hiện",
      "position": "Chức vụ",
      "examination": "Khám",
      "noExam": "Không có dữ liệu",
      "action": "Tác vụ",

      "referralLetter":
      {
        "title": "Thư giới thiệu",
        "clinic": "Phòng khám",
        "doctor": "Bác sĩ",
        "package": "Gói dịch vụ",
        "chooseService": "Chọn gói dịch vụ",
        "chooseClinic": "Chọn phòng khám",
        "chooseDoctor": "Chọn bác sĩ",

        "addTitle": "Chọn Assessment/ Procedure để thêm vào ở thanh công cụ trên",
        "note": "Ghi chú",
        "examination": "Dịch vụ khám",
      }
    },


    "doctorTreatmentPlan": {
      "title": "Kế hoạch điều trị của bác sĩ "
    },
  },
  "print": {
    "fullName": "Họ và tên",
    "gender": "Giới tính",
    "address": "Địa chỉ",
    "dateOfBirth": "Ngày sinh",
    "profileID": "Mã hồ sơ",
    "age": "Tuổi",
    "glassPrescription": "Đơn kính",
    "medicinePrescription": "Đơn thuốc",
    "contactLensPrescription": "Đơn kính áp tròng",
    "dischargeSummary": "Bản tóm tắt xuất viện",
    "paymentSummary": "Phiếu thanh toán",
    "contactLen": {
      "glassType": "Loại kính",
      "parameterGlass": "Tên kính tiếp xúc",
      "parameter": "Thông số",
      "type": "Loại",
      "degreeGlass": "Độ kính tiếp xúc",
      "note": "Ghi chú",
      "date": "Ngày",
      "staffHandler": "Kỹ thuật viên",
    },
    "discharge": {
      "title": "Tóm tắt xuất viện",
      "optometristSummary": "Tóm tắt từ người đo thị lực",
      "chiefComplain": "Lý do đến khám",
      "noComplain": "Không có lý do .",
      "image": "Hình ảnh",
      "noImage": "Không có hình ảnh",
      "conclusion": "Kết luận",
      "noConclusion": "Không có kết luận",
      "followUp": "Theo dõi",
      "noFollowUp": "Không có theo dõi",
      "glassPrescription": "Đơn kính",
      "printGlass": "In đơn kính",
      "doctorSummary": "Tóm tắt bác sĩ",
      "service": "Dịch vụ",
      "diagnosis": "Chuẩn đoán",
      "noDiagnosis": "Không có chuẩn đoán",
      "noAxPx": "Không có procedure/accessment",
      "noSercice": "Bản tóm tắt xuất viện không có dịch vụ.",
      "price": "Giá",
      "referral": "Giới thiệu",
      "noReferral": "Không cso giới thiệu",
      "noMedicine": "Chưa có thuốc kê đơn.",
      "medName": "Tên thuốc",
      "intruction": "Cách dùng",
      "dischargeMode": "Lựa chọn xuất viện",
      "noDischargeMode": "Không có lựa chọn xuất viện",
      "patientSatisfaction": "Mức độ hài lòng của bệnh nhân",
      "noPatientSatisfaction": "Không có mức độ hài lòng của bệnh nhân",
      "nameStaff": "Tên người thực hiện",
      "position": "Chức vụ",
      "examination": "Khám",
      "noExam": "Không có dữ liệu",
      "toView": "(Để xem bản tóm tắt xuất viện)",
      "date": "Ngày",
      "approver": "Người duyệt",
      "referralOrder": "Yêu cầu xuất viện",
      "medicine": "Thuốc",
      "referralLetter":
      {
        "title": "Thư giới thiệu",
        "clinic": "Phòng khám",
        "noClinic": "Không có phòng khám",
        "doctor": "Bác sĩ",
        "noDoctor": "Không có bác sĩ",
        "noDescription": "Không có chú thích",
        "package": "Gói dịch vụ",
        "chooseService": "Chọn gói dịch vụ",
        "chooseClinic": "Chọn phòng khám",
        "chooseDoctor": "Chọn bác sĩ",

        "addTitle": "Chọn Assessment/ Procedure để thêm vào ở thanh công cụ trên",
        "note": "Ghi chú",
      }
    },
    "glass": {
      "currentGlassDeg": "Độ kính đang sử dụng",
    },

  },
  "message": {
    "optometristConclusion": "Hoàn tất lưu thông tin .",
    "dischargeMode": {
      "finishExamination": "Đã hoàn thành khám các bước thành công.",
      "deleteAxPx": "Xóa AxPx ở giới thiệu thành công !",
    },
    "referralPopUp": "Hoàn tất lưu thông tin giới thiệu.",
    "axPxPopUp": "Hoàn tất thêm service mới vào quy trình.",
    "episodeMedication": {
      "updateMedication": "Hoàn tất cập nhật đơn thuốc.",
      "deleteMedication": "Hoàn tất xóa đơn thuốc.",
    },
    "popUpmedicine": "Hoàn tất xóa thuốc.",
    "clinicalNoteTable": "Hoàn tất xóa lưu ý lâm sàng.",
    "clinicalNotePopUp": "Lưu thành công.",
    "formDetail": "Hoàn tất lưu chi tiết biểu mẫu",
    "chiefComplain": "Lưu thành công",
    "examination": {
      "saveDatapoint": "Lưu thành công",
      "cancelCurrentOrder": "Hoàn tất hủy bỏ bước trong quy trình",
    },
    "generalCheckUp": "Hoàn tất quy trình!",
    "listMedicationNote": "Lưu thành công dặn dò vào quy trình",
    "followUp": "Hoàn tất tạo mới dặn dò",
    "rosterRegistration": "Hoàn tất lưu sự kiện .",
    "medicineContainer": "Hoàn tất tạo thông tin thuốc mới!",
    "popUpMedicine": "Hoàn tất tạo thông tin thuốc mới!",
    "workList":
    {
      "step": "Bước khám ",
      "isFinish": " đã hoàn tất !",
      "patient": "Bệnh nhân ",
      "newPatientAlert": "Có bệnh nhân mới đã đăng kí ở phòng khám của bạn .",
    }
  },
  Patient: {
    Patient: 'Số lượng bệnh nhân',
    Patients: 'bệnh nhân',
    Avatar: 'Ảnh đại diện',
    Create: 'Thêm bệnh nhân',
    SelectPatient: 'Chọn bệnh nhân',
    SearchPatient: 'Tìm kiếm bệnh nhân',
    SearchName: 'Tìm theo tên',
    PatientInformation: 'Thông tin bệnh nhân',
    PatientName: 'Tên bệnh nhân',
    Name: 'Tên',
    Gender: 'Giới tính',
    DOB: 'Ngày sinh',
    PID: 'Mã bệnh nhân',
    AID: 'Athena ID',
    Age: 'Tuổi',
    Phone: 'Điện thoại',
    TotalPatient: 'Tổng số bệnh nhân',
    FullName: 'Họ và tên',
    TopPatients: 'Xếp hạng bệnh nhân',
    CalculatedAt: 'Được tính toán từ',
  },
  bookingList: 'Danh sách đặt chỗ',
}