<template>
  <div class="d-block">
    <span class="font-weight-bold text-title-2 pa-2">IOL Side</span>
    <v-row>
      <v-col cols="3">
        <div class="d-flex flex-row">
          <ArchetypeInput 
            :hideName="true"
            :collections="dataPoints"
            :path="pathPrefix + '/IOLG/OD'"
            :schema="Archetypes.CGIOLOD"
          />
        </div>
      </v-col>
       <v-col cols="3">
        <div class="d-flex flex-row">
          <ArchetypeInput 
            :hideName="true"
            :collections="dataPoints"
            :path="pathPrefix + '/IOLG/OS'"
            :schema="Archetypes.CGIOLOS"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: 'CurrentGlassIOLComponent',
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: () => []
    },
    pathPrefix: {
      type: String, 
      default: ''
    }
  }
}
</script>

<style>

</style>