<template>
  <v-tabs
    v-model="tab"
    vertical
    color="grey darken-1"
    class="general-exam-tabs"
  >
    <v-tab v-for="(item, ind) in examinations" :key="'tab-' + ind">
      <v-badge
        v-if="hasDataPoint(item.component)"
        color="green darken-2"
        dot
        offset-x="-8"
        offset-y="2"
      >
        {{ item.name }}
      </v-badge>
      <span v-else>{{ item.name }}</span>
    </v-tab>
    <v-tabs-items v-model="tab">
      <v-tab-item
        eager
        class="pl-5"
        v-for="(item, ind) in examinations"
        :key="'tab-item-' + ind"
      >
        <component
          :pathPrefix="
            '/' + rootPath + '/' + item.component.replace('Component', '')
          "
          :is="item.component"
          :dataPoints="dataPoints"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
export default {
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  created() {
    this.rootPath = this.Archetypes.GNRE.archetypeID;
  },
  data() {
    return {
      rootPath: null,
      examinations: [
        {
          component: "LBComponent",
          name: "Lệ bộ",
        },
        {
          component: "MIMComponent",
          name: "Mí mắt",
        },
        {
          component: "KMComponent",
          name: "Kết mạc",
        },
        {
          component: "GMComponent",
          name: "Giác mạc",
        },
        {
          component: "CMComponent",
          name: "Củng mạc",
        },
        {
          component: "TPComponent",
          name: "Tiền phòng",
        },
        {
          component: "MOMComponent",
          name: "Mống mắt",
        },
        {
          component: "DOTComponent",
          name: "Đồng tử",
        },
        {
          component: "TTTComponent",
          name: "Thủy tinh thể",
        },
        {
          component: "TTDComponent",
          name: "Thủy tinh dịch",
        },
        {
          component: "DMComponent",
          name: "Đáy mắt",
        },
        {
          component: "VMFComponent",
          name: "Võng mạc",
        },
        {
          component: "DTComponent",
          name: "Đĩa thị",
        },
        {
          component: "HDComponent",
          name: "Hoàng điểm",
        },
        {
          component: "HEMComponent",
          name: "Hệ mạch",
        },
        {
          component: "CBComponent",
          name: "Chu biên",
        },
        {
          component: "NCComponent",
          name: "Nhãn cầu",
        },
        {
          component: "VNComponent",
          name: "Vận nhãn",
        },
        {
          component: "HOMComponent",
          name: "Hốc mắt",
        },
        {
          component: "TMComponent",
          name: "Thể mi",
        },
      ],
      tab: null,
    };
  },
  methods: {
    hasDataPoint(compName) {
      compName = "/" + compName.replace("Component", "");
      return this.validateHighLightDataPoint(
        this.dataPoints.find((d) => d.path.includes(compName))
      );
    },
  },
};
</script>

<style lang="scss">
.general-exam-tabs.v-tabs {
  .v-tabs-slider-wrapper {
    width: 0px !important;
  }
  .v-tabs-bar {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-bottom: 0px;
    height: 100%;
    .v-tab {
      border-bottom: 1px solid rgba(0, 0, 0, 0.6);
      font-size: 11px !important;
      font-family: "Montserrat" !important;
      color: #424242 !important;
      min-width: 186px;
      justify-content: left;
    }
    .v-tab--active {
      background: #eeeeee;
    }
  }
  .v-tabs-bar.v-item-group > * {
    cursor: initial;
  }
}
</style>
