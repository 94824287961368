<template>
  <div class="d-block">
    <v-row>
      <v-col cols="2" class="d-flex">
        <span class="w-100 text-center font-weight-bold">
          {{ Archetypes.VA.archetypeID }}
        </span>
      </v-col>
      <v-col cols="5">
        <ArchetypeInput
          :path="`${pathPrefix}/DVT/OD`"
          :hideName="true"
          :schema="Archetypes.DVT"
          :collections="dataPoints"
          v-model="DVT.OD.value"
          class="mb-1"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVD/OD`"
          :hideName="true"
          :schema="Archetypes.DVD"
          :collections="dataPoints"
          v-if="DVT.OD.value.val == 'Thập phân'"
          class="mb-1"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVDL/OD`"
          :hideName="true"
          :schema="Archetypes.DVDL"
          :collections="dataPoints"
          v-if="DVT.OD.value.val == 'Thập phân'"
          class="mb-1"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVCF/OD`"
          :hideName="true"
          :schema="Archetypes.DVCF"
          :collections="dataPoints"
          v-if="DVT.OD.value.val == 'Đếm ngón tay'"
          class="mb-1"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVDLL/OD`"
          :hideName="true"
          :schema="Archetypes.DVDLL"
          :collections="dataPoints"
          v-if="DVT.OD.value.val == 'Sáng tối'"
          class="mb-1"
        />
      </v-col>
      <v-col cols="5">
        <ArchetypeInput
          :path="`${pathPrefix}/DVT/OS`"
          :hideName="true"
          :schema="Archetypes.DVT"
          :collections="dataPoints"
          v-model="DVT.OS.value"
          class="mb-1"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVD/OS`"
          :hideName="true"
          :schema="Archetypes.DVD"
          :collections="dataPoints"
          v-if="DVT.OS.value.val == 'Thập phân'"
          class="mb-1"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVDL/OS`"
          :hideName="true"
          :schema="Archetypes.DVDL"
          :collections="dataPoints"
          v-if="DVT.OS.value.val == 'Thập phân'"
          class="mb-1"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVCF/OS`"
          :hideName="true"
          :schema="Archetypes.DVCF"
          :collections="dataPoints"
          v-if="DVT.OS.value.val == 'Đếm ngón tay'"
          class="mb-1"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVDLL/OS`"
          :hideName="true"
          :schema="Archetypes.DVDLL"
          :collections="dataPoints"
          v-if="DVT.OS.value.val == 'Sáng tối'"
          class="mb-1"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "DistantVisionBoxComponent",
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    DVT: {
      OD: {
        value: "",
      },
      OS: {
        value: "",
      },
    },
  }),
};
</script>

<style>
</style>