import store from '../stores/store'
import { UserTypeCodes } from "@/plugins/constant";

const IsAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/login')
}
const IsNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/unauthorized')
}
const AllowUserTypes = (typeCodes = [UserTypeCodes.Root]) => {
    return (to, from, next) => {
        for (var typeCode of typeCodes) {
            var TypeName = Object.keys(UserTypeCodes)[typeCode - 1];
            var GetterTypeName = `is${TypeName}`;
            if (store.getters[GetterTypeName]) {
                next()
                return
            }
        }
        next('/unauthorized')
    }
}

export default {
    IsAuthenticated,
    IsNotAuthenticated,
    AllowUserTypes,
}