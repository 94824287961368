<template>
  <div class="d-flex flex-column w-100">
    <div class="d-block">
      <v-row justify="space-around" class="pa-0">
        <v-col cols="5">
          <GTComponent
            :pathPrefix="pathPrefix"
            :dataPoints="dataPoints"
            v-model="glassType"
          />
        </v-col>
        <v-col cols="5"></v-col>
      </v-row>
    </div>
    <div class="d-block pa-0" v-if="glassType.val == 'Progressive'">
      <v-row justify="space-around">
        <v-col cols="5">
          <PGTComponent :pathPrefix="pathPrefix" :dataPoints="dataPoints" />
        </v-col>
        <v-col cols="5"></v-col>
      </v-row>
    </div>
    <div class="d-flex flex-column">
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="5" class="text-center font-weight-bold">OD</v-col>
        <v-col cols="5" class="text-center font-weight-bold">OS</v-col>
      </v-row>
      <div
        class="d-block"
        v-for="component in NewGlassComponents"
        :key="component"
      >
        <component
          :is="component"
          :pathPrefix="pathPrefix"
          :dataPoints="dataPoints"
        ></component>
      </div>
    </div>
    <v-row>
      <v-col cols="2" class="d-flex justify-center">
        <span class="font-weight-bold mt-3 text-center">Take Note | Lưu ý</span>
      </v-col>
      <v-col cols="5">
        <div class="d-flex flex-column">
          <QNGPComponent :pathPrefix="pathPrefix" :dataPoints="dataPoints" />
          <NGPComponent :pathPrefix="pathPrefix" :dataPoints="dataPoints" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "GlassPrescriptionComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    pathPrefix: "GP",
    glassType: {},
    NewGlassComponents: [
      "SPHComponent",
      "CYLComponent",
      "AXSComponent",
      "DSVAComponent",
      "BCVAComponent",
      "ADDComponent",
      "NVComponent",
    ],
  }),
};
</script>

<style>
</style>