<template>
  <div class="d-block">
    <v-card class="pa-0" elevation="0" outlined>
      <v-card-title>
        <span>{{ Archetypes.DLTF.name }}</span>
      </v-card-title>
      <v-card-text>
        <DTIMEComponent :dataPoints="dataPoints" :pathPrefix="pathPrefix" />
        <MEDNComponent :dataPoints="dataPoints" :pathPrefix="pathPrefix" />
        <MEDDOComponent :dataPoints="dataPoints" :pathPrefix="pathPrefix" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MedicineDosageComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
</style>