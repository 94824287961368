<template>
  <div class="d-block position-relative mc__container">
    <v-sheet elevation="0">
      <v-row justify="space-between">
        <v-col cols="9">
          <div class="d-flex flex-column" style="overflow: auto">
            <div
              class="d-block ma-1"
              v-for="(item, index) in listMc"
              :key="index"
              :id="item.href"
            >
              <div class="mcTest__title">
                {{ item.name }}
              </div>
              <div class="mcTest__content">
                <component
                  :is="item.components"
                  :dataPoints="dataPoints"
                  :pathPrefix="pathPrefix"
                ></component>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="position-relative">
          <div class="position-fixed mcControl__container">
            <v-card elevation="0" outlined>
              <v-card-text class="pa-0">
                <v-list flat class="pa-0">
                  <v-list-item-group v-model="selectTest">
                    <v-list-item
                      active-class="mcControl__item__active"
                      v-for="(item, index) in listMc"
                      :key="index"
                    >
                      <v-list-item-content
                         @click="scrollToElement(`${item.href}`)"   
                      >
                        {{ item.name }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "MCComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  created() {},
  data: () => ({
    selectTest: 0,
    listMc: [
      {
        name: "AA",
        href: "aA",
        components: "AAComponent",
      },
      {
        name: "NPC",
        href: "nPC",
        components: "NPCComponent",
      },
      {
        name: "Visual field",
        href: "visualField",
        components: "VFComponent",
      },
      {
        name: "Ocular Motility",
        href: "ocularMotility",
        components: "OMComponent",
      },
      {
        name: "Pupillary",
        href: "pupillary",
        components: "PUPComponent",
      },
      {
        name: "Cover test",
        href: "coverTest",
        components: "COVTComponent",
      },
      {
        name: "Color vision",
        href: "colorVision",
        components: "CVComponent",
      },
      {
        name: "Stereo",
        href: "stereo",
        components: "STERComponent",
      },
    ],
  }),
  methods: {
    scrollToElement(element) {
      document.getElementById(element).scrollIntoView();
    }
  }
};
</script>

<style scoped lang="scss">
.mc__container {
  margin-top: 10px;
  overflow-y: scroll;
  display: block;
  scroll-behavior: smooth;
  height: 110vh;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-height: 1080px){
    height: 120vh;
  }
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.position-fixed {
  position: fixed;
}
.mcTest__title {
  display: block;
  width: 100%;
  padding: 10px 12px;
  background-color: #03214d;
  color: #ffffff;
  font-size: 16px;
  font-weight: 450;
}
.mcTest__content {
  display: block;
  padding: 10px 0px;
}
.mcControl__container {
  display: inline-block;
  width: 300px;

  .mcControl__item__active {
    color: #ffffff;
    background-color: #0d0f12;
    font-size: 14px;
  }
  @media only screen and (max-width: 1900px) {
    width: 350px;
  }
  @media only screen and (max-width: 1600px) and (min-width: 1200px) {
    width: 300px;
  } 
  @media only screen and (max-width: 1200px) {
    width: 250px;
  }
}
</style>