<template>
  <div class="form-table">
    <v-row>
      <v-col cols="2" class="text-center">
        <h4></h4>
      </v-col>
      <v-col cols="10" class="text-center">
        <h4>{{ Archetypes.OU.name }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="d-flex align-center">
        <span class="w-100 text-center font-weight-bold">
          {{ Archetypes.METST.name }}
        </span>
      </v-col>
      <v-col cols="10">
        <ArchetypeInput
          :path="pathPrefix + '/METST/OU'"
          :hideName="true"
          :schema="Archetypes.METST"
          :collections="dataPoints"
          @input="input"
        />
      </v-col>
    </v-row>
    <GLOComponent />
    <CONComponent />
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      components: ["GLOComponent", "CONomponent"],
    };
  },
  watch: {},
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style scoped>
.v-data-table__wrapper thead {
  color: black;
}
</style>