<template>
  <v-row>
    <v-col cols="5" offset="2">
      <div class="line">
        <div class="circle">
          <span class="top-left" @click="tabTypeClicked('VIII')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMVIII'"
              :hideName="true"
              :schema="Archetypes.CKOMVIII"
              :collections="dataPoints"
              ref="CKOMVIII"
            />
          </span>
          <span class="top" @click="tabTypeClicked('I')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMI'"
              :hideName="true"
              :schema="Archetypes.CKOMI"
              :collections="dataPoints"
              ref="CKOMI"
            />
          </span>
          <span class="top-right" @click="tabTypeClicked('II')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMII'"
              :hideName="true"
              :schema="Archetypes.CKOMII"
              :collections="dataPoints"
              ref="CKOMII"
            />
          </span>
          <div class="center" @click="tabTypeClicked('IX')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMIX'"
              :hideName="true"
              :schema="Archetypes.CKOMIX"
              :collections="dataPoints"
              ref="CKOMIX"
            />
          </div>
          <span class="right" @click="tabTypeClicked('III')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMIII'"
              :hideName="true"
              :schema="Archetypes.CKOMIII"
              :collections="dataPoints"
              ref="CKOMIII"
            />
          </span>
          <span class="bottom-right" @click="tabTypeClicked('IV')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMIV'"
              :hideName="true"
              :schema="Archetypes.CKOMIV"
              :collections="dataPoints"
              ref="CKOMIV"
            />
          </span>
          <span class="bottom" @click="tabTypeClicked('V')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMV'"
              :hideName="true"
              :schema="Archetypes.CKOMV"
              :collections="dataPoints"
              ref="CKOMV"
            />
          </span>
          <span class="bottom-left" @click="tabTypeClicked('VI')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMVI'"
              :hideName="true"
              :schema="Archetypes.CKOMVI"
              :collections="dataPoints"
              ref="CKOMVI"
            />
          </span>
          <span class="left" @click="tabTypeClicked('VII')">
            <ArchetypeInput
              :path="pathPrefix + '/CKOMVII'"
              :hideName="true"
              :schema="Archetypes.CKOMVII"
              :collections="dataPoints"
              ref="CKOMVII"
            />
          </span>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      types: [
        "CKOMI",
        "CKOMII",
        "CKOMIII",
        "CKOMIV",
        "CKOMV",
        "CKOMVI",
        "CKOMVII",
        "CKOMVIII",
        "CKOMIX",
      ],
    };
  },
  // mounted because refs are not defined yet
  created() {
    setTimeout(() => {
      this.renderTypesStatus();
    }, 2000);
  },
  methods: {
    renderTypesStatus() {
      var hasNoData = true;
      for (var type of this.types) {
        var dataPoint = this.dataPoints.find((p) =>
          p.path.includes(`/OM/${type}/`)
        );
        if (dataPoint) {
          this.$refs[type].resetValueGLO(type.replace("CKOM", ""));
          hasNoData = false;
        } else {
          this.$refs[type].resetValueGLO("");
        }
      }
      if (hasNoData) {
        this.$refs.CKOMIX.resetValueGLO("IX");
      } else {
        this.$refs.CKOMIX.resetValueGLO("");
      }
    },
    tabTypeClicked(type) {
      this.renderTypesStatus();
      this.$emit("tabTypeClicked", type);
    },
  },
};
</script>

<style>
.line {
  border-radius: 170px;
  height: 200px;
  width: 200px;
  display: inline-block;
  position: relative;
  background: linear-gradient(
      to top right,
      transparent calc(50% - 1px),
      black,
      transparent calc(50% + 1px)
    ),
    linear-gradient(
      to top left,
      transparent calc(50% - 1px),
      black,
      transparent calc(50% + 1px)
    ),
    linear-gradient(
      to right,
      transparent calc(50% - 1px),
      black,
      transparent calc(50% + 1px)
    ),
    linear-gradient(
      to bottom,
      transparent calc(50% - 1px),
      black,
      transparent calc(50% + 1px)
    );
}

.circle {
  border-radius: 150px;
  height: 155px;
  width: 155px;
  margin-top: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 39px;
  position: relative;
  border: 1px solid black;
  text-align: center;
  display: block;
}

.center .v-input__slot {
  z-index: 3;
  display: block;
  text-align: center;
  top: -9px;
  left: 9px;
}

.top-left .v-input__slot {
  top: -16px;
  left: -15px;
}

.top .v-input__slot {
  top: -70px;
  left: 64px;
}

.top-right .v-input__slot {
  top: -64px;
  left: 144px;
}

.right .v-input__slot {
  z-index: 1;
  display: grid;
  top: -34px;
  left: 173px;
}

.bottom-right .v-input__slot {
  top: 20px;
  left: 143px;
}

.bottom .v-input__slot {
  top: 27px;
  left: 64px;
}

.bottom-left .v-input__slot {
  top: -26px;
  left: -15px;
}

.left .v-input__slot {
  z-index: 1;
  display: grid;
  top: -129px;
  left: -44px;
}
</style>