<template>
  <v-row>
    <v-col cols="2" class="d-flex align-center">
      <span class="w-100 text-left font-weight-bold">
        {{ Archetypes.DTIME.name }}
      </span>
    </v-col>
    <v-col cols="5">
      <ArchetypeInput
        ref="DTimeArchetype"
        :path="pathPrefix + '/DTIME'"
        :hideName="true"
        :schema="Archetypes.DTIME"
        :collections="dataPoints"
      />
    </v-col>
    <v-col cols="5" class="d-flex align-center">
      <BaseTimePicker 
        v-model="timePicker"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseTimePicker from "@/components/common/BaseTimePicker"
import moment from 'moment';
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "DateTimeComponent",
  components: {
    ArchetypeInput,
    BaseTimePicker
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },

  watch: {
    timePicker() {
      let dateTime = `${this.date} - ${this.timePicker}:00`;
      this.mapDataToArchetype(dateTime)
    }
  },
  data: () => ({
    date: moment().format('DD/MM/YYYY'),
    timePicker: null,
  }),
  methods: {
    mapDataToArchetype(val) {
      this.$refs.DTimeArchetype.parseData(val);
    }
  }
}
</script>

<style>

</style>