<template>
  <v-row>
    <v-col cols="2" class="d-flex align-center">
      <span class="w-100 text-center font-weight-bold">
        {{ Archetypes.BTHD.name }}
      </span>
    </v-col>
    <v-col cols="5">
      <ArchetypeInput
        :path="pathPrefix + '/BTHD/OD'"
        :hideName="true"
        :schema="Archetypes.BTHD"
        :collections="dataPoints"
        @input="input"
      />
    </v-col>
    <v-col cols="5">
      <ArchetypeInput
        :path="pathPrefix + '/BTHD/OS'"
        :hideName="true"
        :schema="Archetypes.BTHD"
        :collections="dataPoints"
        @input="input"
      />
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style>
</style>