import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/scss/vuetify/overrides.scss'
Vue.use(Vuetify);

const theme = {
  primary: '#03214D',
  info: '#DEEBFF',
  success: '#E3FCEF',
  accent: '#fc4b6c',
  default: '#563dea',
  warning: '#FFF0B3',
  exudate: '#CC68D1',
  microaneuryms: '#007EFF',
  hemorrhage: '#E82020',
  normal: '#C4C4C4',
  greyBg: '#bdbdbd'
}

import { VCheckbox } from 'vuetify/lib'

export default new Vuetify({
  components: { VCheckbox },
  theme: {
    light: true,
    themes: {
      dark: theme,
      light: theme,
    },
  }
});