<template>
  <v-card-text class="pa-1">
      <!-- block tab cls -->
      <v-row>
        <v-col lg="4" md="8"  sx="12" sm="12">
          <v-tabs
            fixed-tabs
            v-model="clsTab"
          >
            <v-tab v-for="(itemCls, index) in clsTypes" :key="index"  :href="`#${itemCls.text}`"> 
              
              <v-badge v-if="itemCls.hasValue"
                dot
                color="green"
                >
                <span class="text-subtitle-2 font-weight-bold">{{itemCls.text}}</span>
              </v-badge>
              <span class="font-weight-bold" v-else>{{ itemCls.text }}</span>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <!-- block content tab -->
      <div class="d-block">
        <v-tabs-items v-model="clsTab">
          <v-tab-item v-for="(itemCls, index) in clsTypes" :key="index" :value="itemCls.text"> 
            <v-card>
              <!-- pathPrefix: /CLS/CLSTH/OHCLS -->
              <v-card-text>
                <ClsCommonOrder 
                  :clsType="itemCls.description"
                  :pathPrefix="`${pathPrefix}/${itemCls.typePath}`" 
                  :dataPoints="dataPoints"
                />
                <v-row class="pa-0">
                  <v-col lg="8" md="10" sx="12" sm="12" class="pl-3 pr-3">
                    <div class="d-block pl-3 pr-3">
                      <CLSONComponent
                        :dataPoints="dataPoints"
                        :pathPrefix="`${pathPrefix}/${itemCls.typePath}`"
                      />
                    </div>                
                  </v-col>
                </v-row>
          
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>

    </v-card-text>
</template>

<script>

import ClsCommonOrder from '@/components/common/ClsOrder/ClsCommonOrder'

export default {
  name: 'CurrentGlassCLSComponent',
  components: {
    ClsCommonOrder,
  },
  props: {
    dataPoints: {
      type: Array,
      default: () => []
    },
    pathPrefix: {
      type: String, 
      default: ""
    }
  },
  watch: {
    dataPoints() {
      this.checkHasValue()
    }
  },
  mounted() {
    this.checkHasValue();
  },
  data: () => {
    return {
      clsTypes: [
        {
          text: 'Soft',
          description: 'Old Soft Glass',
          hasValue: false,
          typePath: 'CLSTS', 
          type: 'soft'
        },
        { 
          text: 'Hard',
          description: 'Old Hard Glass',
          hasValue: false,
          typePath: 'CLSTH',
          type: 'hard'
        },
        { 
          text: 'Ortho-K',
          description: 'Old Ortho-K Glass',
          hasValue: false,
          typePath: 'CLSTO',    
          type: 'orthok'
        }
      ],
      clsTab: 'Soft',
      episodeRecordID: null,
    }
  },

  methods: {
    checkHasValue() {
      this.clsTypes.map(i => {
        let find = this.dataPoints.find(dt => dt.path.includes(`${this.pathPrefix}/${i.typePath}`))
        if (typeof find != 'undefined') {
          i.hasValue = true;
        } else {
          i.hasValue = false;
        }
        return i;
      })
    }
  }
}
</script>

<style>

</style>