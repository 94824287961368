<template>
  <div class="w-100">
    <v-btn @click="addNewTrial">
      <span>Add Trial</span>
    </v-btn>
    <v-tabs
      v-model="trial"
      background-color="transparent"
      grow
      class="cover-test-eye-tabs"
    >
      <v-tab v-for="trialItem in trials" :key="trialItem">
        <span>Trial {{ trialItem }}</span>
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="trial">
      <v-tab-item v-for="trialItem in trials" :key="trialItem">
        <TSCLSComponent
          :dataPoints="dataPoints"
          :pathPrefix="`/CLS/${Archetypes.CLSTS.archetypeID}/TRIAL-${trialItem}`" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import _ from 'lodash'
export default {

  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      trial: 0,
      trials: [1],
    }
  },
  created() {
    this.unwatchDataPointProp = this.$watch('dataPoints', (newVal) => {
      if (newVal) {
        // Do stuff...
        this.getListTrials();
        this.unwatchDataPointProp();
      }
    });  
  },
  mounted() {
    this.getListTrials();
  },
  methods: {
    addNewTrial() {
      this.trials.push(this.trials[this.trials.length - 1] + 1);
    },
    getListTrials() {
     
      let pathToFind = `/CLS/${this.Archetypes.CLSTS.archetypeID}/TRIAL-`;
       
      let regex = /TRIAL-([0-9]+)/;
      let listTrials = [];

      let dataWithPath = this.dataPoints.filter(i => i.path.includes(pathToFind)).map(i => i.path);
      if (dataWithPath.length > 0) {
         dataWithPath.forEach((i => {
          if (dataWithPath.length == 0) {
            return false;
          }
          let matchString = i.match(regex);
          listTrials.push(parseInt(matchString[1])); // 1,2,3,4,5,6
          dataWithPath = dataWithPath.filter(i => !i.includes(`TRIAL-${matchString[1]}`))
          if (dataWithPath.length == 0) {
            return;
          }
        }))
        listTrials = _.uniq(listTrials).sort()
        this.trials = listTrials;
      }    
    }
  }
}
</script>
