var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0 covteda-card",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.6)","width":"600px"}},[(!_vm.hasAnyOcularMobilityDataPoint)?_c('h4',{staticClass:"pt-2 pb-2"},[_vm._v("IX Selected")]):_c('div',[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(tab != 'IX'),expression:"tab != 'IX'"}],key:tab,on:{"click":function($event){return _vm.tabTypeClicked(tab)}}},[(_vm.hasDataPointType(tab))?_c('v-badge',{attrs:{"color":"green darken-2","dot":"","offset-x":"-8","offset-y":"2"}},[_vm._v(" "+_vm._s(tab)+" ")]):_c('span',[_vm._v(_vm._s(tab))])],1)}),1),_c('v-divider'),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab,attrs:{"eager":""}},[_vm._l((_vm.eyeTypes),function(eyeType){return _c('v-row',{key:eyeType,staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"2"}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-2 ma-0"},[_c('h4',[_vm._v(_vm._s(_vm.Archetypes[eyeType].name))])])],1)],1),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"10"}},[_c('v-row',{staticClass:"pa-0 ma-0"},_vm._l((_vm.params),function(param,index){return _c('v-col',{key:param,staticClass:"pa-2 ma-0",attrs:{"cols":"3"}},[_c('span',{class:{
                    'value-high-light':
                      _vm.collections[("/OM/CKOM" + tab + "/" + param + "OM/" + eyeType)] ||
                      _vm.collections[
                        ("/OM/CKOM" + tab + "/" + (_vm.params2[index]) + "/" + eyeType)
                      ],
                  }},[_vm._v(" "+_vm._s(param)+": "+_vm._s(_vm.collections[("/OM/CKOM" + tab + "/" + param + "OM/" + eyeType)] || "")+" "+_vm._s(_vm.collections[ ("/OM/CKOM" + tab + "/" + (_vm.params2[index]) + "/" + eyeType) ] || "")+" ")])])}),1)],1)],1)}),(_vm.hasDataPointType(tab))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mb-1",attrs:{"icon":"","color":"deep-orange"},on:{"click":function($event){return _vm.deleteAllTypeDataPoints(tab)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s('Delete ' + tab)}})]):_vm._e()],2)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }