<template>
  <v-row>
    <v-col cols="5" class="text-center">
      <CKOMComponent
        ref="CKOMComponent"
        class="mb-5"
        @tabTypeClicked="handleTabTypeClicked"
        :pathPrefix="`/OM`"
        :dataPoints="dataPoints"
      />
      <PrOMComponent
        class="mt-2"
        ref="PrOMComponent"
        @tabTypeClicked="handleTabTypeClicked"
        :dataPoints="dataPoints"
      />
    </v-col>
    <v-col
      cols="7"
      v-for="archetypeID in types"
      :key="archetypeID"
      v-show="archetypeID == type && type != 'CKOMIX'"
    >
      <v-row class="justify-center mb-2">
        <h3 class="d-flex text-center">
          {{ Archetypes[archetypeID].options[0] }}
        </h3>
      </v-row>
      <OMRComponent
        :dataPoints="dataPoints"
        :pathPrefix="`/OM/${archetypeID}`"
        @input="handleOcularMobilityDataPointChanged"
      />
      <OCDComponent :dataPoints="dataPoints" />
    </v-col>
  </v-row>
</template>

<script>
import PrOMComponent from "@/components/examination/components/Composites/PrOMComponent.vue";
import OMRComponent from "@/components/examination/components/Archetypes/OMRComponent.vue";
import CKOMComponent from "@/components/examination/components/Archetypes/CKOMComponent.vue";
export default {
  components: {
    PrOMComponent,
    OMRComponent,
    CKOMComponent,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      number: {},
      type: "CKOMI",
      types: [
        "CKOMI",
        "CKOMII",
        "CKOMIII",
        "CKOMIV",
        "CKOMV",
        "CKOMVI",
        "CKOMVII",
        "CKOMVIII",
        "CKOMIX",
      ],
    };
  },
  created() {},
  methods: {
    handleTabTypeClicked(type) {
      this.type = `CKOM${type}`;
      if (this.type == "CKOMIX") {
        this.removeAllOcularMobilityDataPoints();
        this.$refs.PrOMComponent.resetCollections();
      }
    },
    removeAllOcularMobilityDataPoints() {
      var ind = this.dataPoints.findIndex((p) => p.path.includes("/OM/"));
      if (ind > -1) {
        this.dataPoints.splice(ind, 1);
        this.removeAllOcularMobilityDataPoints();
      }
      return;
    },
    handleOcularMobilityDataPointChanged() {
      setTimeout(() => {
        this.$refs.PrOMComponent.renderCollections();
        this.$refs.CKOMComponent.renderTypesStatus();
      }, 0);
    },
  },
};
</script>

<style scoped>
.v-data-table__wrapper thead {
  color: black;
}
.button {
  float: right;
}
</style>