<template>
  <v-card
    class="elevation-0 covteda-card"
    style="border: 1px solid rgba(0, 0, 0, 0.6); width: 600px"
  >
    <v-row>
      <v-col cols="4" class="d-flex">
        <span class="w-100 text-left font-weight-bold">
          {{ Archetypes.COVTT.name }}
        </span>
      </v-col>
      <v-col cols="8">
        <ArchetypeInput
          :path="`${pathPrefix}/COVTT/OU`"
          :hideName="true"
          :schema="Archetypes.COVTT"
          :collections="dataPoints"
        />
      </v-col>
      <v-col cols="4" class="d-flex">
        <span class="w-100 text-left font-weight-bold">
          {{ Archetypes.COVTPD.name }}
        </span>
      </v-col>
      <v-col cols="8">
        <ArchetypeInput
          :path="`${pathPrefix}/COVTPD/OU`"
          :hideName="true"
          :schema="Archetypes.COVTPD"
          :collections="dataPoints"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="d-flex">
        <span class="w-100 text-left font-weight-bold">
          {{ Archetypes.COVTACM.name }}
        </span>
      </v-col>
      <v-col cols="8">
        <ArchetypeInput
          :path="`${pathPrefix}/COVTACM/OU`"
          :hideName="true"
          :schema="Archetypes.COVTACM"
          :collections="dataPoints"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="d-flex">
        <span class="w-100 text-left font-weight-bold">
          {{ Archetypes.COVTPOP.name }}
        </span>
      </v-col>
      <v-col cols="8">
        <ArchetypeInput
          :path="`${pathPrefix}/COVTPOP/OU`"
          :hideName="true"
          :schema="Archetypes.COVTPOP"
          :collections="dataPoints"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss">
.cover-test-eye-tabs.v-tabs {
  .v-tabs-slider {
    height: 0px;
  }
  > .v-tabs-bar {
    .v-tab {
      font-size: 11px !important;
      color: #424242 !important;
      min-width: 186px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    }
    .v-tab--active {
      background: #eeeeee;
      font-weight: bold;
    }
  }
  .v-tabs-bar.v-item-group > * {
    cursor: initial;
  }
}
</style>