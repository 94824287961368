<template>
  <div class="d-block pa-2">
    <span class="text-title-1 font-weight-bold">Current Glass condition</span>
    <ArchetypeInput 
      class="mt-2"
      :hideName="true"
      :schema="Archetypes.CGC"
      :collections="dataPoints"
      :path="pathPrefix + '/CGC/OU'"
      @input="input"
    />
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue"; 
export default {
  name: "CurrentGlassConditionComponent",
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array, 
      default: () => []
    },
    pathPrefix: {
      type: String,
      default: ''
    }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  }
}
</script>

<style>

</style>