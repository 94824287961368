<template>
  <v-card
    class="elevation-0 covteda-card"
    style="border: 1px solid rgba(0, 0, 0, 0.6); width: 600px"
  >
    <h4 class="pt-2 pb-2" v-if="!hasAnyOcularMobilityDataPoint">IX Selected</h4>
    <div v-else>
      <v-tabs v-model="selectedTab" show-arrows>
        <v-tab
          v-for="tab in tabs"
          :key="tab"
          @click="tabTypeClicked(tab)"
          v-show="tab != 'IX'"
        >
          <v-badge
            v-if="hasDataPointType(tab)"
            color="green darken-2"
            dot
            offset-x="-8"
            offset-y="2"
          >
            {{ tab }}
          </v-badge>
          <span v-else>{{ tab }}</span>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item eager v-for="tab in tabs" :key="tab">
          <v-row v-for="eyeType in eyeTypes" :key="eyeType" class="pa-0 ma-0">
            <v-col cols="2" class="pa-0 ma-0">
              <v-row class="pa-0 ma-0">
                <v-col class="pa-2 ma-0">
                  <h4>{{ Archetypes[eyeType].name }}</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="10" class="pa-0 ma-0">
              <v-row class="pa-0 ma-0">
                <v-col
                  cols="3"
                  v-for="(param, index) in params"
                  :key="param"
                  class="pa-2 ma-0"
                >
                  <span
                    :class="{
                      'value-high-light':
                        collections[`/OM/CKOM${tab}/${param}OM/${eyeType}`] ||
                        collections[
                          `/OM/CKOM${tab}/${params2[index]}/${eyeType}`
                        ],
                    }"
                  >
                    {{ param }}:
                    {{
                      collections[`/OM/CKOM${tab}/${param}OM/${eyeType}`] || ""
                    }}
                    {{
                      collections[
                        `/OM/CKOM${tab}/${params2[index]}/${eyeType}`
                      ] || ""
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-tooltip bottom v-if="hasDataPointType(tab)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="deep-orange"
                class="mr-2 mb-1"
                @click="deleteAllTypeDataPoints(tab)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span v-html="'Delete ' + tab"></span>
          </v-tooltip>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      selectedTab: 0,
      tabs: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
      params: ["S", "A", "F", "E"],
      params2: ["SMOM", "ACCOM", "FUOM", "EXOM"],
      eyeTypes: ["OD", "OS"],
      collections: {},
    };
  },
  computed: {
    hasAnyOcularMobilityDataPoint() {
      return this.dataPoints.find((d) => d.path.includes("/OM/")) || false;
    },
  },
  watch: {
    dataPoints: {
      handler() {
        this.renderCollections();
        this.renderSelectedTab();
      },
    },
  },
  created() {},
  methods: {
    deleteAllTypeDataPoints(type) {
      // find data point of this tab type
      var ind = this.dataPoints.findIndex((p) =>
        p.path.includes(`/OM/CKOM${type}/`)
      );
      // if found data point => delete => do again
      if (ind > -1) {
        this.dataPoints.splice(ind, 1);
        return this.deleteAllTypeDataPoints(type);
      }
      // till there are no data points...
      // re-render collections
      this.renderCollections();
      // default show the first tab type found
      this.renderSelectedTab();
    },
    renderCollections() {
      this.collections = {};
      this.dataPoints
        .filter((p) => p.path.includes("/OM/"))
        .map((p) => {
          this.collections[p.path] = p.value.split("|")[0];
        });
      this.$forceUpdate();
    },
    renderSelectedTab() {
      // find last value of data points
      var point = this.dataPoints
        // copy the array into new memory
        .slice()
        // reverse to get last data-point value
        .reverse()
        .find((p) => p.path.includes("/OM/"));
      // if cannot find any point
      if (!point) {
        this.selectedTab = 0;
        return;
      }
      var typeID = point.path
        .replace("/OM/", "")
        .split("/")[0]
        .replace("CKOM", "");
      if (!typeID) {
        this.selectedTab = 0;
        return;
      }
      this.selectedTab = this.tabs.findIndex((t) => t == typeID);
    },
    hasDataPointType(type) {
      return this.validateHighLightDataPoint(
        this.dataPoints.find((d) => d.path.includes(`/OM/CKOM${type}/`))
      );
    },
    resetCollections() {
      this.collections = {};
    },
    tabTypeClicked(type) {
      this.$emit("tabTypeClicked", type);
    },
  },
};
</script>

<style lang="scss">
.v-data-table__wrapper thead {
  color: black;
}
.fit-button-center {
  display: flex;
  justify-content: center;
}
.PrOM-table {
  .v-slide-group__content {
    border-right: 1px solid rgba(0, 0, 0, 0.6);
  }
}
.value-high-light {
  font-weight: bold;
  color: #43a047;
}
</style>