import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

import AuthMiddleware from '@/middlewares/authen'
import HomeRoutes from './home/routes'
import PatientRoutes from './patient/routes'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0
        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return goTo(scrollTo)
    },
    routes: [
        {
            path: '*',
            component: () => import('@/layouts/blank-layout/BlankLayout'),
            beforeEnter: AuthMiddleware.IsAuthenticated,
            redirect: '/index',
            children: [
                ...HomeRoutes,
                ...PatientRoutes,
            ]
        },
        {
            path: "/inspector",
            component: () => import(`@/components/flow/FlowInspector.vue`),
        },
        {
            path: '/index',
            component: () => import('@/views/Index'),
            beforeEnter: AuthMiddleware.IsAuthenticated,
        },
        {
            path: '/login',
            component: () => import('@/views/authentication/FullLogin'),
            beforeEnter: AuthMiddleware.IsNotAuthenticated,
        },
        {
            path: '/notfound',
            component: () => import('@/views/authentication/Error'),
        },
        {
            path: '/unauthorized',
            component: () => import('@/views/authentication/Error'),
        },
        {
            path: '/scannedQR',
            component: () => import(`@/views/ScannedQR.vue`),
        },
        {
            path: '/render',
            component: () => import(`@/components/examination/ArchetypeRender.vue`),
        },
        {
            path: '/flow',
            component: () => import('@/layouts/blank-layout/BlankLayout'),
            beforeEnter: AuthMiddleware.IsAuthenticated,
            redirect: '/flow/designer',
            children: [
                // {
                //     path: "designer",
                //     component: () => import(`@/components/flow/FlowDesginer.vue`),
                // },
                {
                    path: "inspector",
                    component: () => import(`@/components/flow/FlowInspector.vue`),
                },
            ]
        },
    ],
})