<template>
  <v-sheet elevation="0">
    <div class="d-block pa-2">
      <v-row>
        <v-col
          cols="6"
          sx="6"
          sm="6"
          class="pa-0"
          v-for="(item, index) in tabObjective"
          :key="index"
        >
          <div
            @click="changeTab(item.name)"
            :class="item.active ? 'tabObj__item__active' : 'tabObj__item'"
          >
            <v-badge
              v-if="hasDataPoint(item.pathPrefix)"
              color="green"
              dot
              offset-x="-8"
              offset-y="10"
            >
              {{ item.text }}
            </v-badge>
            <span v-else>{{ item.text }}</span>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="d-block w-100 mt-1">
      <div class="d-block" v-for="(item, index) in tabObjective" :key="index">
        <template v-if="item.active">
          <v-row class="mt-2">
            <v-col cols="2"></v-col>
            <v-col cols="5" class="text-center font-weight-bold">
              <span>OD</span>
            </v-col>
            <v-col cols="5" class="text-center font-weight-bold">
              <span>OS</span>
            </v-col>
          </v-row>
          <div v-for="component in item.components" :key="component">
            <component
              :is="component"
              :pathPrefix="returnPath(component, item.pathPrefix)"
              :dataPoints="dataPoints"
            ></component>
          </div>
        </template>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "ObjectiveRefractionComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  created() {
    this.parseDataPoint();
  },
  watch: {
    dataPoints() {
      this.parseDataPoint();
    },
  },
  data: () => ({
    tab: null,
    sharedComponent: ["PDComponent"],
    tabObjective: [
      {
        text: "Dry Objective",
        name: "dry_objective",
        active: true,
        pathPrefix: "OJR/DOJR",
        components: ["SPHComponent", "CYLComponent", "AXSComponent"],
      },
      {
        text: "Wet Objective",
        name: "wet_objective",
        active: false,
        pathPrefix: "OJR/WOJR",
        components: [
          "DLTComponent",
          "SPHComponent",
          "CYLComponent",
          "AXSComponent",
        ],
      },
    ],
  }),

  methods: {
    changeTab(nameTab) {
      this.tabObjective = this.tabObjective.map((i) => {
        if (i.name == nameTab) {
          i.active = true;
        } else {
          i.active = false;
        }
        return i;
      });
    },
    parseDataPoint() {
      this.tabObjective = this.tabObjective.map((i) => {
        i.dataPoints = this.dataPoints;
        return i;
      });
    },
    returnPath(itemComponent, pathPrefix) {
      return this.sharedComponent.includes(itemComponent) ? "" : pathPrefix;
    },
    hasDataPoint(prefix) {
      return this.validateHighLightDataPoint(
        this.dataPoints.find((d) => d.path.includes(prefix))
      );
    },
  },
};
</script>

<style scoped>
.tabObj__item,
.tabObj__item__active {
  padding: 5px;
  text-align: center;
  cursor: pointer;
}
.tabObj__item__active {
  background-color: #0044a8;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.tabObj__item {
  color: black;
  background-color: #e2e5e9;
}
</style>