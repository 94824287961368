<template>
  <div>
    <ArchetypeInput
      :path="`${pathPrefix}/COVTTTT`"
      :hideName="true"
      :schema="Archetypes.COVTTTT"
      :collections="dataPoints"
      @input="input"
      style="width: 600px"
      class="mb-2"
      v-model="abnormal"
    />
    <COVTTROComponent
      v-show="abnormal.val == 'Tropia'"
      :dataPoints="dataPoints"
      :pathPrefix="`${pathPrefix}/COVTTRO`"
    />
    <COVTPHOComponent
      v-show="abnormal.val == 'Phoria'"
      :dataPoints="dataPoints"
      :pathPrefix="`${pathPrefix}/COVTPHO`"
    />
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
import COVTTROComponent from "@/components/examination/components/Archetypes/COVTTROComponent";
import COVTPHOComponent from "@/components/examination/components/Archetypes/COVTPHOComponent";

export default {
  components: {
    ArchetypeInput,
    COVTTROComponent,
    COVTPHOComponent,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      abnormal: {},
    };
  },
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
};
</script>