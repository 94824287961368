<template>
  <div class="d-block w-100">
    
    <div class="d-block mb-4">
      <v-row justify="space-between">
        <v-col md="4" xl="3" sm="12">
          <div class="d-flex flex-column justify-end " style="height: 100%">
            <div class="d-block w-100">
              <span class="d-block text-h6">{{ Archetypes.CLST.name }}</span>
              <v-radio-group row v-model="contactLensType">
                <v-radio
                  dense
                  hide-details
                  v-for="opt in Archetypes.CLST.options"
                  :key="opt"
                  :label="Archetypes[opt].description"
                  :value="opt"
                  class="float-left mb-2"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>  
        </v-col>
        <v-col  md="5" xl="5" sm="12" v-if="dataCurrentCLS.length > 0">
          <v-card elevation="0" outlined>
            <v-card-text>
              <span class="d-block font-weight-bold text-subtitle-1">Current CLS glass</span>
              <div class="d-block w-100 mt-3">
                <v-tabs
                  fixed-tabs
                  v-model="clsTab"
                >
                  <v-tab v-for="(itemCls, index) in clsTypes" :key="index"  :href="`#${itemCls.text}`"> 
                    
                    <v-badge v-if="itemCls.hasValue"
                      dot
                      color="green"
                      >
                      <span class="text-subtitle-2 font-weight-bold">{{itemCls.text}}</span>
                    </v-badge>
                    <span class="font-weight-bold" v-else>{{ itemCls.text }}</span>

                  </v-tab>
                </v-tabs>
              </div>
              <div class="d-block">
                <v-tabs-items v-model="clsTab">
                  <v-tab-item v-for="(itemCls, index) in clsTypes" :key="index" :value="itemCls.text">
                    <v-card>
                      <v-row>
                        <v-col cols="6" v-for="(side, indexSide) in sideEyes" :key="indexSide">
                          <span class="d-block text-center font-weight-bold w-100">
                            {{side}}
                          </span> 
                          <div class="d-flex mt-2 flex-column">
                            <template v-for="(itemCurrentArchetype, indexCA) in archetypeCurrentCLS">
                              <div class="d-block mp-2 mt-2" :key="indexCA" v-if="typeof itemCurrentArchetype == 'string'">
                                <span class="font-weight-bold mr-2">{{itemCurrentArchetype}}: </span>
                                <b class="font-weight-regular ml-2">{{ exportCLSOldValFromArchetype(itemCls.typePath, itemCurrentArchetype,side ) }}</b>
                              </div>  
                              <div class="d-flex mp-2 justify-space-between mt-2" :key="indexCA" v-if="Array.isArray(itemCurrentArchetype)">
                                <div class="d-block" style="width: 30%" v-for="arrayArchetype in itemCurrentArchetype" :key="arrayArchetype">
                                  <span class="font-weight-bold mr-1">{{arrayArchetype}}: </span>
                                  <b class="font-weight-regular ml-1">{{ exportCLSOldValFromArchetype(itemCls.typePath,arrayArchetype,side ) }}</b>   
                                </div>
                              </div>
                            </template>
                          </div>
                        </v-col>   
                      </v-row>
                   </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
      <TLSCLSComponent 
        v-if="contactLensType === Archetypes.CLSTS.archetypeID"
        :dataPoints="dataPoints" />

      <TLHCLSComponent 
        v-if="contactLensType === Archetypes.CLSTH.archetypeID"
        :dataPoints="dataPoints" />

      <TLOCLSComponent 
        v-if="contactLensType === Archetypes.CLSTO.archetypeID"
        :dataPoints="dataPoints"
      />
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      contactLensType: null,
      dataCurrentCLS: [],
      clsTypes: [
        {
          text: 'Soft',
          description: 'Soft Order',
          hasValue: false,
          typePath: 'CLSTS',
          type: 'soft'
        },
        { 
          text: 'Hard',
          description: 'Hard Order',
          hasValue: false,
          typePath: 'CLSTH',
          type: 'hard'
        },
        { 
          text: 'Ortho-K',
          description: 'Ortho-K Order',
          hasValue: false,   
          typePath: 'CLSTO',
          type: 'orthok'
        }
      ],
      clsTab: 'Soft',
      sideEyes: ['OD', 'OS'],
      archetypeCurrentCLS: ['B', 'BC', 'DIA', ['S','C', 'A'], 'TYPE' ]
    }
  },
  created() {
    this.contactLensType = this.Archetypes.CLSTS.archetypeID;
    this.getContactLensType()
    this.getOldCLS()
  },
  watch: {
    dataPoints() {
      this.getOldCLS();
    }
  },
  methods: {
    getContactLensType() {
      var point = this.dataPoints.find(p => p.path.includes('/CLS/'));
      if (point) {
        this.contactLensType = point.path.replace('/CLS/', '').split('/')[0];
      }
    },
    getOldCLS() {
      let rootPath = "/CG/CCLS/"
      var currentCLS = []
      if (this.dataPoints.length > 0) {
        currentCLS = _.cloneDeep(this.dataPoints.filter(p => p.path.includes(rootPath)));
        if (currentCLS.length > 0) {
          this.dataCurrentCLS = currentCLS;
          this.fomratTabTypeCls()
        }
      }
    },
    exportCLSOldValFromArchetype(typeCLS, archetypeID, side ) {
      let pathQuery = `/CG/CCLS/${typeCLS}/TD/${archetypeID}/${side}`;
      let dataPoint = this.dataCurrentCLS.find(i => i.path == pathQuery);
      if (typeof dataPoint != 'undefined') {
        if (["S", "C", "A"].includes(archetypeID)) {
          return `${dataPoint.value} D`;
        }
        return dataPoint.value;
      } else {
        return '';
      }
    },

    fomratTabTypeCls() {
      this.clsTypes.map(i => {
        let find = this.dataCurrentCLS.find(dt => dt.path.includes(i.typePath));
        console.log(find)
        if (typeof find == 'undefined') {
          i.hasValue = false;
        } else {
          i.hasValue = true;
        }
        return i;
      })
    }
  }
}
</script>
