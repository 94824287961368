export const Archetypes = {
  ADD: { archetypeID: "ADD", name: "ADD | Độ lão", type: "text-field", default: "" },
  BR: { archetypeID: "BR", name: "Break", type: "text-field", default: "" },
  PPUP: { archetypeID: "PPUP", name: "P", type: "text-field", default: "" },
  RE: { archetypeID: "RE", name: "Recovery", type: "text-field", default: "" },
  AAV: { archetypeID: "AAV", name: "Value", type: "text-field", default: "" },
  AXS: { archetypeID: "AXS", name: "Axis Value", type: "text-field", default: "" },
  MEAA: { archetypeID: "MEAA", name: "Method", type: "select", options: ["Push up", "Pull away"], default: "Push up" },
  SGTPUp: { archetypeID: "SGTPUp", name: "Soi góc tiền phòng trên", type: "text-field", default: "" },
  SGTPDown: { archetypeID: "SGTPDown", name: "Soi góc tiền phòng dưới", type: "text-field", default: "" },
  SGTPLeft: { archetypeID: "SGTPLeft", name: "Soi góc tiền phòng trái", type: "text-field", default: "" },
  SGTPRight: { archetypeID: "SGTPRight", name: "Soi góc tiền phòng phải", type: "text-field", default: "" },
  SGTP: { archetypeID: "SGTP", name: "Soi góc tiền phòng" },
  CYL: { archetypeID: "CYL", name: "Cylinder Value", type: "text-field", default: "" },
  SPH: { archetypeID: "SPH", name: "Sphere Value", type: "text-field", default: "" },
  GC: { archetypeID: "GC", name: "Ghi chú", type: "textarea", default: "" },
  GLO: { archetypeID: "GLO", name: "Global", type: "textarea", default: 0 },
  CON: { archetypeID: "CON", name: "Contour", type: "textarea", default: 0 },
  DLT: { archetypeID: "DLT", name: "Dilation value", type: "select", options: ["Cylogyl 1%", "Mydrin P", ""], default: "" },
  EPUP: { archetypeID: "EPUP", name: "Equal", type: "select", options: ["4", "3", "2", "1"], default: "4" },
  RPUP: { archetypeID: "RPUP", name: "Reactive", type: "select", options: ["4", "3", "2", "1"], default: "4" },
  LPUP: { archetypeID: "LPUP", name: "Light", type: "select", options: ["4", "3", "2", "1"], default: "4" },
  APUP: { archetypeID: "APUP", name: "Accommodation", type: "select", options: ["4", "3", "2", "1"], default: "4" },
  OCD: { archetypeID: "OCD", name: "Others condition", type: "checkbox", options: ["Endpoint Nystagmus", "Midline jump"] },
  MGPUP: { archetypeID: "MGPUP", name: "Marcus Gunn pupil", type: "select", options: ["4", "3", "2", "1", "0"], default: "0" },
  VA: { archetypeID: "VA", name: "Visual acuity value", type: "text-field", default: "" },
  PD: { archetypeID: "PD", name: "Pupillary distance value", type: "text-field", default: "" },
  BCVA: { archetypeID: "BCVA", name: "Best corrected visual acuity value", type: "text-field", default: "" },
  IOP: { archetypeID: "IOP", name: "Intraocular pressure value | Nội nhãn áp", type: "text-field", default: "" },
  GP: { archetypeID: "GP", name: "G point", type: "select", options: ["NONE", "G1", "G2", "G3", "G4", "G5", "G6", "G7", "G8", "G9", "G10"], default: "" },
  TOTN: { archetypeID: "TOTN", name: "To the nose | Đến mũi", type: "checkbox", options: ["To the nose"] },
  FVF: { archetypeID: "FVF", name: "Full | Hoàn toàn", type: "checkbox", options: ["Full"] },
  RIVF: { archetypeID: "RIVF", name: "Restricted in | Hạn chế trong", type: "checkbox", options: ["Superiotemporal", "Superionasal", "Inferiotemporal", "Inferionasal"] },
  RP: { archetypeID: "RP", name: "Range point", type: "select", options: ["NONE", "20-35", "25-30", "30-35", "35-40"], default: "" },
  SOA: { archetypeID: "SOA", name: "Seconds of arc", type: "checkbox", options: ["None"] },
  AddPUP: { archetypeID: "AddPUP", name: "AddPUP | Thêm tròng", type: "radio", options: ["+", "-"], default: "+" },
  BD: { archetypeID: "BD", name: "Bọng dò", type: "checkbox", options: ["Dẹt", "Xơ", "Mỏng", "Quá phát"], default: [] },
  CD: { archetypeID: "CD", name: "C/D", type: "checkbox", options: ["0.1", "0.2", "0.3", "0.4", "0.5", "0.6", "0.7", "0.8", "0.9", "0.95", "0.99"], default: [] },
  NO: { archetypeID: "NO", name: "Notching", type: "checkbox", options: ["1h", "2h", "3h", "4h", "5h", "6h", "7h", "8h", "9h", "10h", "11h", "12h", "Notching bất thường", "Bình thường"], default: [] },
  BT: { archetypeID: "BT", name: "Bình thường", type: "checkbox", options: ["Bình thường"], default: "" },
  HBT: { archetypeID: "HBT", name: "Hồng / Bình thường", type: "checkbox", options: ["Hồng / Bình thường"], default: "" },
  TL: { archetypeID: "TL", name: "Tuyến lệ", type: "checkbox", options: ["Viêm"], default: [] },
  LD: { archetypeID: "LD", name: "Lệ đạo", type: "checkbox", options: ["Hẹp", "Chảy nước mắt sống", "Viêm"], default: [] },
  LQ: { archetypeID: "LQ", name: "Lệ quản", type: "checkbox", options: ["Bơm lệ quản nước thoát không tốt", "Trào lệ quản đối diện", "Trào lệ quản tại chỗ"], default: [] },
  L: { archetypeID: "L", name: "Lỗ", type: "checkbox", options: ["Lỗ lớp", "Giả lỗ"], default: [] },
  NG: { archetypeID: "NG", name: "Nhỏ giãn", type: "checkbox", options: ["Nhỏ Giãn"], default: [] },
  LM: { archetypeID: "LM", name: "Lông mi", type: "checkbox", options: ["Mi trên", "Mi dưới", "Quặm", "Lông xiêu"], default: [] },
  DV: { archetypeID: "DV", name: "Dạng viêm", type: "checkbox", options: ["U mi", "Lẹo", "Chắp"], default: [] },
  DD: { archetypeID: "DD", name: "Độ đục", type: "checkbox", options: ["Độ 1", "Độ 2", "Độ 3", "Độ 4", "Độ 5"], default: [] },
  ST: { archetypeID: "ST", name: "Song thị", type: "checkbox", options: ["Song thị"], default: [] },
  DMMIM: { archetypeID: "DMMIM", name: "Da mi", type: "checkbox", options: ["Dư da mi", "Nốt ruồi"], default: [] },
  VKM: { archetypeID: "VKM", name: "Viêm kết mạc", type: "checkbox", options: ["Tiết tố mủ", "Tiết tố trong", "Giả mạc", "Cầu dính"], default: [] },
  DCZ: { archetypeID: "DCZ", name: "Dây chằng Zinn", type: "checkbox", options: ["Yếu", "Đứt"], default: [] },
  ADT: { archetypeID: "ADT", name: "Ánh đồng tử", type: "checkbox", options: ["Xám", "Không soi được", "Hồng"], default: [] },
  TDM: { archetypeID: "TDM", name: "Tắc động mạch", type: "checkbox", options: ["Trung tâm", "Nhánh", "Mi VM"], default: [] },
  TTM: { archetypeID: "TTM", name: "Tắc tĩnh mạch", type: "checkbox", options: ["Trung tâm", "Nhánh"], default: [] },
  BTKOKM: { archetypeID: "BTKOKM", name: "Bất thường khác ở kết mạc", type: "checkbox", options: ["Nhiễm trùng", "Nhiễm giun chỉ", "Nhuộm màu kết mạc"], default: [] },
  VTDIOL: { archetypeID: "VTDIOL", name: "Vị trí đặt IOL", type: "checkbox", options: ["Tiền phòng", "Hậu phòng"], default: [] },
  AC: { archetypeID: "AC", name: "AC", type: "checkbox", options: ["Deep & clear", "Cell / flare", "Shallow", "Fibrin (+)"], default: [] },
  ACC: { archetypeID: "ACC", name: "ACC", type: "checkbox", options: ["0.5+", "1+", "2+", "3+"], default: [] },
  BTPhanXa: { archetypeID: "BTPhanXa", name: "Bình thường", type: "checkbox", options: ["Phản xạ (+)"], default: "" },
  BatTVN: { archetypeID: "BatTVN", name: "Bất thường", type: "checkbox", options: ["Bất thường"], default: "" },
  BatTDT: { archetypeID: "BatTDT", name: "Bất thường", type: "checkbox", options: ["Méo", "Dính", "Màng đồng tử"], default: "" },
  BatTTTD: { archetypeID: "BatTTTD", name: "Bất thường", type: "checkbox", options: ["Đục", "Xuất huyết"], default: "" },
  BatTCB: { archetypeID: "BatTCB", name: "Bất thường", type: "checkbox", options: ["Attached", "Detached", "Drusen"], default: "" },
  BatTPX: { archetypeID: "BatTPX", name: "Bất thường phản xạ", type: "checkbox", options: ["Kém", "Mất", "Phản xạ (-)"], default: "" },
  BTKTVHD: { archetypeID: "BTKTVHD", name: "Bất thường kích thước và hình dáng", type: "checkbox", options: ["Nhỏ", "To", "Hình nón"], default: [] },
  BTMSGM: { archetypeID: "BTMSGM", name: "Bất thường mặt sau giác mạc", type: "checkbox", options: ["Tủa sắc tố", "Xuất tiết", "Guttata"], default: [] },
  BTCD: { archetypeID: "BTCD", name: "Bất thường cùng đồ", type: "checkbox", options: ["Cạn", "Dính"], default: [] },
  IOL: { archetypeID: "IOL", name: "Intraocular lens", type: "checkbox", options: ["Đục bao sau (PCO)", "Rách bao sau", "Lệch nhiều", "Hơi lệch", "Chính tâm", "Đã đặt IOL"], default: [] },
  TMG: { archetypeID: "TMG", name: "Tân mạch gai", type: "checkbox", options: ["1/4 gai", "1/4 đên 1/2 gai", "Hơn 1/2 gai"], default: [] },
  TAM: { archetypeID: "TAM", name: "Tân mạch", type: "checkbox", options: ["Tân mạch võng mạc", "Tân mạch hắc mạc"], default: [] },
  TMHM: { archetypeID: "TMHM", name: "Tân mạch hắc mạc", type: "checkbox", options: ["Dưới hoàng điểm", "Ngoài hoàng điểm"], default: [] },
  RGNC: { archetypeID: "RGNC", name: "Rung giật nhãn cầu", type: "checkbox", options: ["Rung giật nhãn cầu"], default: [] },
  BTBOM: { archetypeID: "BTBOM", name: "Bất thường bờ mi", type: "checkbox", options: ["Phù", "Sẹo da mi", "Hở mi", "Trễ mi", "Sụp mi", "Khuyết mi", "Lật mi", "Sưng nề"], default: [] },
  BTMM: { archetypeID: "BTMM", name: "Bất thường", type: "checkbox", options: ["Xơ teo", "Cương tụ", "Tân mạch", "Phòi", "Kẹt", "Khuyết", "Bussaca"], default: [] },
  BTKM: { archetypeID: "BTKM", name: "Bất thường kết mạc", type: "checkbox", options: ["Cương tụ", "Sẹo", "Phù nề", "Xuất huyết", "Sừng hóa", "Nhú", "Hột", "U", "Cặn lắng", "Thoái hóa", "Cương tụ rỉa", "Khô mắt"], default: [] },
  BTCM: { archetypeID: "BTCM", name: "Bất thường củng mạc", type: "checkbox", options: ["Giãn lòi", "Tiêu mỏng", "Sẹo mổ"], default: [] },
  BTG: { archetypeID: "BTG", name: "Bất thường gai", type: "checkbox", options: ["Lõm teo gai", "Phù gai", "Bạc màu gai", "Thái hóa cạnh gai", "ISN't bất thường"], default: [] },
  HTKT: { archetypeID: "HTKT", name: "Hình thái cương tụ", type: "checkbox", options: ["Tỏa lan", "Ở rìa", "Ở kết mạc nhãn cầu", "Toàn bộ"], default: [] },
  HTL: { archetypeID: "HTL", name: "Hình thái lác", type: "checkbox", options: ["Lác trong", "Lác ngoài", "Lác chéo"], default: [] },
  HTD: { archetypeID: "HTD", name: "Hình thái đục", type: "checkbox", options: ["Nhân", "Vỏ", "Dưới bao", "Toàn bộ", "Giả tróc bao", "Canxi hóa", "Lệch T3", "Đục trắng sữa"], default: [] },
  BTCGGM: { archetypeID: "BTCGGM", name: "Bất thường cảm giác giác mạc", type: "checkbox", options: ["Mất", "Giảm"], default: [] },
  BTT: { archetypeID: "BTT", name: "Bờ tổn thương", type: "checkbox", options: ["Nham nhỡ", "Trơn nhẵn", "Đào rãnh"], default: [] },
  BTBM: { archetypeID: "BTBM", name: "Bất thường biểu mô", type: "checkbox", options: ["Tổn thương dạng chấm", "Thoái hóa dài băng", "Lắng đọng thuốc", "Haze"], default: [] },
  BTNM: { archetypeID: "BTNM", name: "Bất thường nhu mô", type: "checkbox", options: ["Phù nhu mô", "Viêm nhu mô"], default: [] },
  BMST: { archetypeID: "BMST", name: "Biểu mô sắc tố", type: "checkbox", options: ["Bong"], default: [] },
  BTHD: { archetypeID: "BTHD", name: "Bất thường hoàng điểm", type: "checkbox", options: ["Mất ánh hoàng điểm", "Sẹo hoàng điểm", "Xuất huyết vùng hoàng điểm", "Xuất tiết gần hoàng điểm", "Teo hoàng điểm", "EP change"], default: [] },
  CNGNM: { archetypeID: "CNGNM", name: "Có nếp gấp nội mô", type: "checkbox", options: ["Nhẹ", "Vừa", "Nặng"], default: [] },
  TTGM: { archetypeID: "TTGM", name: "Tổn thương giác mạc", type: "checkbox", options: ["Dọa thủng", "Thủng", "Kẹt mống mắt", "Sẹo giác mạc", "Loạn dưỡng"], default: [] },
  TMGM: { archetypeID: "TMGM", name: "Tân mạch giác mạc", type: "checkbox", options: ["Nông,hướng tâm", "Ly tâm", "Sâu"], default: [] },
  T: { archetypeID: "T", name: "Trong", type: "checkbox", options: ["Trong"], default: [] },
  TD: { archetypeID: "TD", name: "Thanh dịch", type: "checkbox", options: ["Bong"], default: [] },
  THVM: { archetypeID: "THVM", name: "Thoái hóa võng mạc", type: "checkbox", options: ["Ngoại biên", "Trung tâm"], default: [] },
  HM: { archetypeID: "HM", name: "Hắc mạc", type: "checkbox", options: ["Viêm khu trú", "Thoái hóa", "Loạn dưỡng", "Viêm lan tỏa", "Viêm thể mi sau", "Viêm khác", "Xuất huyết", "Rách", "Bong"], default: [] },
  HVM: { archetypeID: "HVM", name: "Hắc võng mạc", type: "checkbox", options: ["Sẹo", "Thoái hóa", "Viêm"], default: [] },
  DVCM: { archetypeID: "DVCM", name: "Dạng viêm cùng mạc", type: "checkbox", options: ["Lan tỏa", "Áp-xe", "Nốt", "Hoại tử"], default: [] },
  DPT: { archetypeID: "DPT", name: "Đã phẫu thuật", type: "checkbox", options: ["Cắt bẻ CGM", "Cắt bẻ+CCH", "Cắt CMS", "Cắt CMS+CCH", "Đặt van dẫn lưu", "Quang đông thể mi", "Lạnh đông thể mi", "Sửa sẹo bọng", "Kẹt củng mạc", "Laser tạo hình bè"], default: [] },
  DTH: { archetypeID: "DTH", name: "Đã thực hiện", type: "checkbox", options: ["Laser mống mắt ngoại vi", "Laser tạo hình mống mắt"], default: [] },
  BTKDT: { archetypeID: "BTKDT", name: "Bất thường khác", type: "checkbox", options: ["Không soi được"], default: [] },
  TITK: { archetypeID: "TITK", name: "Tình trạng khác", type: "checkbox", options: ["Thoái hóa", "Nhiễm ký sinh trùng"], default: [] },
  TOTK: { archetypeID: "TOTK", name: "Tổn thương khác", type: "checkbox", options: ["Có tổn thương", "Không có"], default: [] },
  DSM: { archetypeID: "DSM", name: "Độ sụp mi", type: "checkbox", options: ["Độ 1", "Độ 2", "Độ 3"], default: [] },
  MGD: { archetypeID: "MGD", name: "Rối loạn tuyến meibomian", type: "checkbox", options: ["Stage 1", "Stage 2", "Stage 3", "Stage 4", "'plus' desease"], default: [] },
  BTDM: { archetypeID: "BTDM", name: "Bất thường", type: "checkbox", options: ["Drusen", "Khác"], default: [] },
  BTHM: { archetypeID: "BTHM", name: "Bất thường", type: "checkbox", options: ["Phù", "Thiếu máu", "Viêm mao mạch"], default: [] },
  BTHOM: { archetypeID: "BTHOM", name: "Bất thường", type: "checkbox", options: ["Bất thường"], default: [] },
  DBT: { archetypeID: "DBT", name: "Descemet bất thường", type: "checkbox", options: ["Rạn màng Descemet", "Cuộn Descemet"], default: [] },
  DMVM: { archetypeID: "DMVM", name: "Động mạch võng mạc", type: "checkbox", options: ["Tắc thoáng qua", "Tắc trung tâm"], default: [] },
  Dru: { archetypeID: "Dru", name: "Drusen", type: "checkbox", options: ["Drusen vùng hoàng điểm", "Drusen rải rác"], default: [] },
  VM: { archetypeID: "VM", name: "Võng mạc", type: "checkbox", options: ["Xuất tiết", "Thoái hóa", "Loạn dưỡng", "Xuất huyết", "Bong", "Rách", "Tách lớp", "Bong thanh dịch", "Màng trước VM (ERM)"], default: [] },
  VTV: { archetypeID: "VTV", name: "Vị trí viêm", type: "checkbox", options: ["Viêm thượng củng mạc"], default: [] },
  VTTL: { archetypeID: "VTTL", name: "Vị trí thẩm lậu", type: "checkbox", options: ["Khu trú", "Lan tỏa", "Nhiều ổ vệ tinh"], default: [] },
  VRGM: { archetypeID: "VRGM", name: "Vùng rìa giác mạc", type: "checkbox", options: ["Thoái hóa già", "Lắng đọng Can-xi"], default: [] },
  VTMBM: { archetypeID: "VTMBM", name: "Vị trí mất biểu mô", type: "checkbox", options: ["Trung tâm", "Lệch tâm", "Sát rìa"], default: [] },
  VTTMNM: { archetypeID: "VTTMNM", name: "Vị trí tiêu mỏng nhu mô", type: "checkbox", options: ["Ở rìa", "Lệch tâm", "Trung tâm"], default: [] },
  VTVTM: { archetypeID: "VTVTM", name: "Vị trí viêm", type: "checkbox", options: ["Thể mi sau", "Mống mắt-thể mi"], default: [] },
  MDV: { archetypeID: "MDV", name: "Mức độ viêm", type: "checkbox", options: ["Sâu", "Nông"], default: [] },
  M: { archetypeID: "M", name: "Mộng", type: "checkbox", options: ["Độ 4", "Độ 1", "Độ 2", "Góc ngoài", "Góc trong", "Độ 3", "Mộng thịt", "Mộng mỡ"], default: [] },
  MDTM: { archetypeID: "MDTM", name: "Mức độ tân mạch", type: "checkbox", options: ["1/3 chu vi", "1/3 đến 2/3 chu vi", "Hơn 2/3 chu vi"], default: [] },
  MDTL: { archetypeID: "MDTL", name: "Mức độ thẩm lậu", type: "checkbox", options: ["Nông", "Sâu", "Rất sâu"], default: [] },
  MDTMNM: { archetypeID: "MDTMNM", name: "Mức độ tiêu mỏng nhu mô", type: "checkbox", options: ["Dưới 1/2 bề dày", "Trên 1/2 bề dày"], default: [] },
  MTGM: { archetypeID: "MTGM", name: "Mặt trước giác mạc", type: "checkbox", options: ["SPK", "Viêm", "Loét", "Viêm kết-giác mạc", "Sẹo", "Đục", "Viêm GM chấm công", "Pigment KPs"], default: [] },
  MMVM: { archetypeID: "MMVM", name: "Mạch máu võng mạc", type: "checkbox", options: ["Biến đổi", "Tăng sinh", "Loạn dưỡng"], default: [] },
  MET: { archetypeID: "MET", name: "Method", type: "select", options: ["Ishihara", "HRR"], default: "Ishihara" },
  METST: { archetypeID: "METST", name: "Method", type: "select", options: ["Random Dot 2"], default: "Random Dot 2" },
  PBBM: { archetypeID: "PBBM", name: "Phù bọng biểu mô", type: "checkbox", options: ["Nhẹ", "Vừa", "Nặng"], default: [] },
  P: { archetypeID: "P", name: "Phù", type: "checkbox", options: ["Dạng nang (CME)", "Tỏa lan"], default: [] },
  G: { archetypeID: "G", name: "Gonioscopy | Nội soi", type: "checkbox", options: ["I", "II", "III", "IV", "PAS"], default: [] },
  LMBM: { archetypeID: "LMBM", name: "Lượng mất biểu mô", type: "checkbox", options: ["Dưới 1/3 diện tích", "1/3 đến 1/2 diện tích", "Trên 1/2 diện tích"], default: [] },
  XHVM: { archetypeID: "XHVM", name: "Xuất huyết võng mạc", type: "checkbox", options: ["Nông", "Sâu"], default: [] },
  XT: { archetypeID: "XT", name: "Xuất tiết", type: "checkbox", options: ["Cứng", "Dạng bông"], default: [] },
  GT: { archetypeID: "GT", name: "Glass Type | Loại kính", type: "radio", options: ["Progressive", "Near", "Distant"], default: "" },
  GS: { archetypeID: "GS", name: "Glass Status | Tình trạng kính", type: "radio", options: ["Old glass", "No glass", "New glass"], default: "" },
  CS: { archetypeID: "CS", name: "Contact Lens Status | Tình trạng kính áp tròng", type: "radio", options: ["Old lens", "No lens", "New lens"], default: "" },
  PGT: { archetypeID: "PGT", name: "Progressive glass type | Loại kính lũy tiến", type: "radio", options: ["PALs", "Bifocal", "E-Bifocal"], default: "" },
  QNGP: { archetypeID: "QNGP", name: "Quick Note Glass Prescription", description: "Ghi chú nhanh đơn kính", type: "checkbox", options: ['Tập đeo kính', 'Đeo kính thường xuyên', 'Tái khám sau 6 tháng'] },
  NGP: { archetypeID: "NGP", name: "Note Glass Prescription | Ghi chú đơn kính", description: "Ghi chú đơn kính", type: "textarea" },
  // Visual Acuity - Decimal
  DVU: { archetypeID: "DVU", name: "Distant Vision Unit", description: "Độ", type: "radio", options: ["Decimal", "Snellen"], default: "Decimal" },
  DVT: { archetypeID: "DVT", name: "Distant Vision Type", description: "Loại", type: "select", options: ["Thập phân", "Đếm ngón tay", "Bóng bàn tay", "Sáng tối"], default: "" },
  DVD: { archetypeID: "DVD", name: "Distant Vision Decimal", description: "Thập phân", type: "select", options: ["0", "10/10", "9/10", "8/10", "7/10", "6/10", "5/10", "4/10", "3/10", "2/10", "1/10"], default: "" },
  DVCF: { archetypeID: "DVCF", name: "Distant Vision Count Finger", description: "Đếm ngón tay", type: "select", options: ["5.5", "5", "4.5", "4", "3.5", "3", "2.5", "2", "1.5", "1", "0.5", "0"], default: "" },
  DVDL: { archetypeID: "DVDL", name: "Distant Vision Decimal Level", description: "Mức", type: "checkbox", options: ["+", "-"], default: [] },
  DVDLL: { archetypeID: "DVDLL", name: "Distant Vision Dark Light Level", description: "Độ do ánh sáng", type: "radio", options: ["-", "+"], default: "" },
  // Visual Acuity - Snellen
  DVT_SNE: { archetypeID: "DVT", name: "Distant Vision Type", description: "Loại", type: "select", options: ["Snellen", "Đếm ngón tay", "Bóng bàn tay", "Sáng tối"], default: "" },
  DVD_SNE: { archetypeID: "DVD", name: "Distant Vision Decimal", description: "Thập phân", type: "select", options: ["0", "20/10", "20/13.5", "20/16", "20/20", "20/25", "20/32", "20/40", "20/50", "20/63", "20/80", "20/100", "20/125", "20/160", "20/200", "20/250", "20/320", "20/400", "20/500"], default: "" },
  //Ocular motility result
  PrOM: { archetypeID: "PrOM", name: "Ocular Motility" },
  CKOM: { archetypeID: "CKOM", name: "Ocular Motility" },
  CKOMI: { archetypeID: "CKOMI", name: "CKOMI", type: "checkbox", options: ["I"], default: "" },
  CKOMII: { archetypeID: "CKOMII", name: "CKOMII", type: "checkbox", options: ["II"], default: "" },
  CKOMIII: { archetypeID: "CKOMIII", name: "CKOMIII", type: "checkbox", options: ["III"], default: "" },
  CKOMIV: { archetypeID: "CKOMIV", name: "CKOMIV", type: "checkbox", options: ["IV"], default: "" },
  CKOMV: { archetypeID: "CKOMV", name: "CKOMV", type: "checkbox", options: ["V"], default: "" },
  CKOMVI: { archetypeID: "CKOMVI", name: "CKOMVI", type: "checkbox", options: ["VI"], default: "" },
  CKOMVII: { archetypeID: "CKOMVII", name: "CKOMVII", type: "checkbox", options: ["VII"], default: "" },
  CKOMVIII: { archetypeID: "CKOMVIII", name: "CKOMVIII", type: "checkbox", options: ["VIII"], default: "" },
  CKOMIX: { archetypeID: "CKOMIX", name: "CKOMIX", type: "checkbox", options: ["IX"], default: "" },
  OM: { archetypeID: "OM", name: "Ocular Motility" },
  OMR: { archetypeID: "OMR", name: "OMR" },
  SOM: { archetypeID: "SOM", name: "S", type: "radio", options: ["+", "-"], default: "" },
  AOM: { archetypeID: "AOM", name: "A", type: "radio", options: ["+", "-"], default: "" },
  FOM: { archetypeID: "FOM", name: "F", type: "radio", options: ["+", "-"], default: "" },
  EOM: { archetypeID: "EOM", name: "E", type: "radio", options: ["+", "-"], default: "" },
  SMOM: { archetypeID: "SMOM", name: "Smooth", type: "select", options: ["4", "3", "2", "1"], default: "" },
  ACCOM: { archetypeID: "ACCOM", name: "Accurate", type: "select", options: ["4", "3", "2", "1"], default: "" },
  FUOM: { archetypeID: "FUOM", name: "Full", type: "select", options: ["4", "3", "2", "1"], default: "" },
  EXOM: { archetypeID: "EXOM", name: "Extensive", type: "select", options: ["4", "3", "2", "1"], default: "" },
  // Image archetype
  IMGL: { archetypeID: "IMGL", name: "Image link", type: "link", default: "" },
  IMGD: { archetypeID: "IMGD", name: "Image description", type: "textarea", default: "" },
  IMGT: { archetypeID: "IMGT", name: "Image type", type: "select", options: ["OCT", "FFA", "Siêu âm A", "Siêu âm B", "Đếm tế bào nội mô", "Thị trường Oculus", "Hình màu đáy mắt", "Cắt lớp bán phần trước"], default: "OCT" },
  // Dilation Archetype
  DTIME: { archetypeID: "DTIME", name: "Date & Time", type: "text-field", default: "" },
  MEDNAME: { archetypeID: "MEDNAME", name: "Tên thuốc", type: "text-field", default: "" },
  MEDDOSE: { archetypeID: "MEDDOSE", name: "Dose", type: "text-field", default: "" },
  // Operation Archetype
  PRV: { archetypeID: 'PRV', name: "Procedure name value", type: 'text-field', default: "" },
  PRBYV: { archetypeID: 'PRBYV', name: "Perform by value", type: "text-field", default: "" },
  CPOV: { archetypeID: 'CPOV', name: "Condition post operator value", type: "text-field", default: "" },
  RSOD: { archetypeID: "RSOD", name: "Result OD" },
  RSOS: { archetypeID: "RSOS", name: "Result OS" },
  // Cover Test
  COVT: { archetypeID: "COVT", name: "Cover Test" },
  COVTSDA: { archetypeID: "COVTSDA", name: "Spec Data", description: "Cover Test - Spec Data" },
  COVTWTS: { archetypeID: "COVTWTS", name: "Without spec", description: "Covert Test Condition - Without spec" },
  COVTWOS: { archetypeID: "COVTWOS", name: "With old spec", description: "Covert Test Condition - With old spec" },
  COVTWNS: { archetypeID: "COVTWNS", name: "With new spec", description: "Covert Test Condition - With new spec" },
  COVTAFD: { archetypeID: "COVTAFD", name: "After dilation", description: "Covert Test Condition - After dilation" },
  COVTTDA: { archetypeID: "COVTTDA", name: "Type Data", description: "Cover Test - Type Data" },
  COVTDIS: { archetypeID: "COVTDIS", name: "Distant", description: "Cover Test - Distant" },
  COVTNEA: { archetypeID: "COVTNEA", name: "Near", description: "Cover Test - Near" },
  COVTTTT: { archetypeID: "COVTTTT", name: "Abnormal", description: "Cover Test - Abnormalities", type: "select", options: ["Ortho", "Tropia", "Phoria"], default: "" },
  COVTORT: { archetypeID: "COVTORT", name: "Eye Data", description: "Cover Test - Ortho Data" },
  COVTTRO: { archetypeID: "COVTTRO", name: "Eye Data", description: "Cover Test - Tropia Data" },
  COVTPHO: { archetypeID: "COVTPHO", name: "Eye Data", description: "Cover Test - Phoria Data" },
  COVTT: { archetypeID: "COVTT", name: "Type", description: "Cover Test - Type", type: "select", options: ["EXO", "ESO", "HYPER", "HYPO", "EXOCYCLO", "INCUCLO"], default: "" },
  COVTPD: { archetypeID: "COVTPD", name: "Prism Diopter", description: "Cover Test - Prism Diopter", type: "text-field", default: "" },
  COVTPOP: { archetypeID: "COVTPOP", name: "Tính thay đổi", description: "Cover Test - Tính thay đổi", type: "radio", options: ["Intermittent", "Constant"], default: "" },
  COVTACM: { archetypeID: "COVTACM", name: "Accomodative", description: "Cover Test - Accomodative", type: "text-field", default: "" },
  // Current Glass 
  WBCG: { archetypeID: "WBCG", name: "Nơi mua kính", type: "radio", options: ["Cao Thang Optic shop", "Other"] },
  GP_CG: { archetypeID: "GP_CG", name: "G Point Kính hiện tại", type: "select", options: ["NONE", "No G", "G1", "G2", "G3", "G4", "G5", "G6", "G7", "G8", "G9", "G10"], default: "" },
  CGC: { archetypeID: "CGC", name: "Current GLass Condition", type: "radio", options: ['Chưa sử dụng', 'Đã sử dụng', 'Đã Lasik', 'IOL'], default: ''},
  CGIOL: { archetypeID: "CGIOL", name: "Current GLass IOL",},
  CGIOLOD: { archetypeID: "CGIOLOD", name: "Current Glass IOL OD",  type: "checkbox", options: ['MP'], default: '' },
  CGIOLOS: { archetypeID: "CGIOLOS", name: "Current Glass IOL OS",  type: "checkbox", options: ['MT'], default: '' },

  // Contact Lens
  // Pretest CLS
  CLSPUP: { archetypeID: 'CLSPUP', name: "Contact lens Pupil size", type: "text-field", default: '' },
  CLSAPER: { archetypeID: 'CLSAPER', name: "Contact lens Aperture", type: "text-field", default: '' },
  CLSHVID: { archetypeID: 'CLSHVID', name: "Contact lens HVID", type: "text-field", default: '' },
  CLSKF: { archetypeID: 'CLSKF', name: 'Contact lens K Flat', type: 'text-field', default: '' },
  CLSKS: { archetypeID: 'CLSKS', name: 'Contact lens K Steep', type: 'text-field', default: '' },
  CLSKA: { archetypeID: 'CLSKA', name: 'Contact lens K Average', type: 'text-field', default: '' },
  // Slitlamp Dry eye
  CLSSTP: { archetypeID: 'CLSSTP', name: "Contact lens Tear Prism", type: "text-field", default: '' },
  CLSSSC: { archetypeID: 'CLSSSC', name: "Contact lens Schirmer", type: "text-field", default: '' },
  CLSSTBUT: { archetypeID: 'CLSSTBUT', name: "Contact lens TBUT", type: "text-field", default: '' },
  // CLS Trial len details
  CLSSHB: { archetypeID: 'CLSSHB', name: "Lens detail Brand", type: "text-field", default: '' },
  CLSSHBC: { archetypeID: 'CLSSHBC', name: "Lens detail BC", type: "text-field", default: '' },
  CLSSHD: { archetypeID: 'CLSSHD', name: "Lens detail Dia", type: "text-field", default: '' },
  CLSSHS: { archetypeID: 'CLSSHS', name: 'Lens detail S', type: "text-field", default: '' },
  CLSSHC: { archetypeID: 'CLSSHC', name: 'Lens detail C', type: "text-field", default: '' },
  CLSSHA: { archetypeID: 'CLSSHA', name: 'Lens detail A', type: "text-field", default: '' },
  CLSSHT: { archetypeID: 'CLSSHT', name: 'Lens detail Type', type: "text-field", default: '' },
  // CLS Centration
  CLSSHCE: { archetypeID: 'CLSSHCE', name: 'Lens Centration', type: "radio", options: ["Good", "Superior", "Inferior", "Nasal", "Temporial"], default: "" },
  CLSSCENT: { archetypeID: 'CLSSCENT', name: 'Lens Center', type: "radio", options: ["Bubles", "Pooling", "Alignment/Bearing", "Touch"], default: "" },
  CLSSMI: { archetypeID: 'CLSSMI', name: 'Lens Mid-periphery', type: "radio", options: ["Heavy bearing", "Medium bearing", "Light bearing", "Excessive clearance"], default: "" },
  CLSSE: { archetypeID: 'CLSSE', name: 'Lens Edge-with', type: "radio", options: ["Narrow", "Optimal", "Steep"], default: '' },
  CLSSEC: { archetypeID: 'CLSSEC', name: 'Lens Edge clearance', type: "radio", options: ["Minimal", "Adequate", "Excessive"], default: '' },
  CLSSCL: { archetypeID: 'CLSSCL', name: 'Lens Soft conclusion', type: "radio", options: ["Optimal", "Acceptable", "Too flat", "Too sleep", "Refitted"], default: '' },
  CLSSHVA: { archetypeID: 'CLSSHVA', name: 'VA with Cls', type: "text-field", default: '' },
  CLSSHVAV: { archetypeID: 'CLSSHVAV', name: 'VA', type: "text-field", default: "" },
  CLSSHPT: { archetypeID: 'CLSSHPT', name: 'Primary gaze text', type: "text-field", default: "" },
  CLSSHPS: { archetypeID: 'CLSSHPS', name: 'Primary gaze selection', type: "radio", options: ["Fast", "Medium", "Slow"], default: "" },
  // SOFT Trial CLS
  CLSTSC: { archetypeID: 'CLSTSC', name: 'Corneal Coverrage value', type: 'radio', options: ["Full", "Expo"] },
  CLSR: { archetypeID: 'CLSR', name: 'Rotation value', type: 'text-field', default: '' },
  CLSPUTV: { archetypeID: 'CLSPUTV', name: 'Push up test value', type: 'radio', options: ['Too loose', 'Acceptable', 'Too tight'], default: '' },
  CLSSCLUSV: { archetypeID: 'CLSSCLUSV', name: 'Trial soft Conclusion value', type: 'radio', options: ['Optimal', 'Acceptable', 'Too flat', 'Too tight'], default: '' },
  // ORTHO CLS
  CLSOCENV: { archetypeID: 'CLSOCENV', name: 'Ortho centration value', type: 'radio', options: ["Good", "Not Good"], default: '' },
  CLSORV: { archetypeID: 'CLSORV', name: 'Ortho Reserve value', type: 'radio', options: ["Ideal", "Wide", "Buddles"], default: '' },
  CLSOALIV: { archetypeID: 'CLSOALIV', name: 'Ortho Alignment value', type: 'radio', options: ["Ideal", "Heavy bearing", "Absent"], default: '' },
  CLSOPERV: { archetypeID: 'CLSOPERV', name: 'Ortho Periphevy value', type: 'radio', options: ["Ideal", "Higher", "Lower"], default: '' },
  // CLS VA without CLS
  CLSOVAWOV: { archetypeID: 'CLSOVAWOV', name: 'VA without CLs value', type: 'text-field', default: '' },
  CLSONV: { archetypeID: 'CLSONV', name: 'Note | Ghi chú', description: 'Note Order Contact lens', type: 'textarea', default: '' },

  // Conclusion OrthoK
  CLSOCLV: { archetypeID: 'CLSOCLV', name: 'Lens OrthoK conclusion value', type: "radio", options: ["Optimal", "Acceptable", "Too flat", "Too sleep", "Refitted"], default: '' },
  VSA: { archetypeID: "VSA", name: "Visual Acuity", description: "Thị lực" },
  OD: { archetypeID: "OD", name: "OD", description: "Mắt phải" },
  OS: { archetypeID: "OS", name: "OS", description: "Mắt trái" },
  OU: { archetypeID: "OU", name: "OU", description: "Hai Mắt" },
  ALT: { archetypeID: "ALT", name: "Alter", description: "Tính thay đổi của mắt" },
  LB: { archetypeID: "LB", name: "Lệ bộ" },
  MIM: { archetypeID: "MIM", name: "Mí mắt" },
  KM: { archetypeID: "KM", name: "Kết mạc" },
  GM: { archetypeID: "GM", name: "Giác mạc" },
  CM: { archetypeID: "CM", name: "Cùng mạc" },
  TP: { archetypeID: "TP", name: "Tiền phòng" },
  MOM: { archetypeID: "MOM", name: "Mống mắt" },
  DOT: { archetypeID: "DOT", name: "Đồng tử" },
  DM: { archetypeID: "DM", name: "Đáy mắt" },
  VMF: { archetypeID: "VMF", name: "Võng mạc" },
  DT: { archetypeID: "DT", name: "Đĩa thị" },
  HD: { archetypeID: "HD", name: "Hoàng điểm" },
  HEM: { archetypeID: "HEM", name: "Hệ mạch" },
  CB: { archetypeID: "CB", name: "Chu biên" },
  NC: { archetypeID: "NC", name: "Nhãn cầu" },
  VN: { archetypeID: "VN", name: "Vận nhãn" },
  HOM: { archetypeID: "HOM", name: "Hốc mắt" },
  TM: { archetypeID: "TM", name: "Thể mi" },
  DIVD: { archetypeID: "DIVD", name: "Distant Vision - Decimal" },
  DIVSNE: { archetypeID: "DIVSNE", name: "Distant Vision - Snellen" },
  HTCT: { archetypeID: "HTCT", name: "Hình thái cương tụ" },
  COPO: { archetypeID: "COPO", name: "Condition post operator" },
  MEDN: { archetypeID: "MEDN", name: "Medication name" },
  MEDDO: { archetypeID: "MEDDO", name: "Medication dose" },
  NVACG: { archetypeID: "NVACG", name: "Near Vision" },
  NV: { archetypeID: "NV", name: "Near vision", description: "Tầm nhìn gần" },
  TQ: { archetypeID: "TQ", name: "Tổng quát" },
  BM: { archetypeID: "BM", name: "Biểu mô" },
  NhuM: { archetypeID: "NhuM", name: "Nhu mô" },
  MD: { archetypeID: "MD", name: "Màng Descemet" },
  NoiM: { archetypeID: "NoiM", name: "Nội mô" },
  DCL: { archetypeID: "DCL", name: "Độ của lỗ" },
  CG: { archetypeID: "CG", name: "Current glass" },
  TONO: { archetypeID: "TONO", name: "TONO" },
  TOPD: { archetypeID: "TOPD", name: "TOPD" },
  TTT: { archetypeID: "TTT", name: "TTT" },
  TTD: { archetypeID: "TTD", name: "TTD" },
  CV: { archetypeID: "CV", name: "Cover test - simple" },
  DSVA: { archetypeID: "DSVA", name: "Distant Vision" },
  GeneralExam: { archetypeID: "GeneralExam", name: "Khám thực thể" },
  SR: { archetypeID: "SR", name: "Subjective refraction" },
  AA: { archetypeID: "AA", name: "AA", desccription: "Amplitude of accommodation | Biên độ chỗ" },
  NPC: { archetypeID: "NPC", name: "NPC", description: "Near point convergence | Điểm gần hội tụ" },
  VF: { archetypeID: "VF", name: "VF", description: "Visual Field | Vùng thị giác" },
  PUP: { archetypeID: "PUP", name: "Pupillary", description: "Pupillary | Đồng tử" },
  STER: { archetypeID: "STER", name: "STEREO" },
  OJR: { archetypeID: "OJR", name: "Objective refraction", description: "Khúc xạ khách quan" },
  DOJR: { archetypeID: "DOJR", name: "Dry Objective Refraction" },
  WOJR: { archetypeID: "WOJR", name: "Wet Objective Refraction" },
  SJR: { archetypeID: "SJR", name: "Subjective Refraction", description: "Khúc xạ chủ quan" },
  SC: { archetypeID: "SC", name: "Distant vision without Glass", description: "Nhìn xa không kính" },
  CC: { archetypeID: "CC", name: "Distant vision with Glass", description: "Nhìn xa có kính" },
  DIV: { archetypeID: "DIV", name: "Distant Vision", description: "Tầm nhìn xa" },
  PRM: { archetypeID: "PRM", name: "Preliminary" },
  MC: { archetypeID: "MC", name: "Khám MC" },
  // Form Name Composite Nhỏ giãn - Hình ảnh
  DLTF: { archetypeID: "DLTF", name: "Form nhỏ giãn" },
  CAIF: { archetypeID: "CAIF", name: "Capture Image Form" },
  // Form Operation
  OSTIME: { archetypeID: "OSTIME", name: "Start time" },
  OETIME: { archetypeID: "OETIME", name: "End time" },
  PRNAME: { archetypeID: "PRNAME", name: "Procedure Name" },
  PRBY: { archetypeID: "PRBY", name: "Perform by" },
  CPO: { archetypeID: "CPO", name: "Condition post operator" },
  ORF: { archetypeID: "ORF", name: "Operative Report Form" },
  // Form Contact lens
  // pretest
  CLSPRE: { archetypeID: "CLSPRE", name: "Pre-test CLs" },
  CLSPUPIL: { archetypeID: "CLSPUPIL", name: "Pupil size" },
  CLSAPERTURE: { archetypeID: "CLSAPERTURE", name: "Aperture" },
  CLSHVIDN: { archetypeID: "CLSHVIDN", name: "HVID" },
  CLSKREAD: { archetypeID: "CLSKREAD", name: "K Reading" },
  // splitlamp
  CLSSLIT: { archetypeID: 'CLSSLIT', name: 'Slitlamp Dry Eye' },
  CLSTP: { archetypeID: "CLSTP", name: "Tear prism" },
  CLSSC: { archetypeID: "CLSSC", name: "Schirmer" },
  CLSTBUT: { archetypeID: "CLSTBUT", name: "TBUT" },
  // Trial lens
  CLSSHTD: { archetypeID: 'CLSSHTD', name: 'Trial lens details' },
  CLSSHTDB: { archetypeID: 'CLSSHTDB', name: "Brand" },
  CLSSHTDBC: { archetypeID: 'CLSSHTDBC', name: "BC" },
  CLSSHTDD: { archetypeID: 'CLSSHTDD', name: 'DIA' },
  CLSSHTDS: { archetypeID: 'CLSSHTDS', name: 'S' },
  CLSSHTDC: { archetypeID: 'CLSSHTDC', name: 'C' },
  CLSSHTDA: { archetypeID: 'CLSSHTDA', name: 'A' },
  CLSSHTDT: { archetypeID: 'CLSSHTDT', name: 'Type' },
  CLSSHCEN: { archetypeID: 'CLSSHCEN', name: 'Centration' },
  CLSSCENTER: { archetypeID: 'CLSSHCENTER', name: 'Center' },
  CLSSMID: { archetypeID: 'CLSSMID', name: 'Mid-periphery' },
  CLSSED: { archetypeID: 'CLSSED', name: 'Edge width' },
  CLSSECL: { archetypeID: 'CLSSECL', name: 'Edge clearance' },
  CLSSCLU: { archetypeID: 'CLSSCLU', name: 'Conclusion' },
  CLSSHVAW: { archetypeID: 'CLSSHVAW', name: 'VA with CLS' },
  CLSOVR: { archetypeID: 'CLSOVR', name: 'Over ref' },
  CLSSHVAVL: { archetypeID: 'CLSSHVAVL', name: 'VA' },
  CLSSHGAZE: { archetypeID: 'CLSSHGAZE', name: 'Primary gaze' },
  CLSFL: { archetypeID: 'CLSFL', name: 'Fluorescein pattern' },
  THCLS: { archetypeID: 'THCLS', name: 'Trial for CLs/ Hard Cls' },
  TSCLS: { archetypeID: 'TSCLS', name: 'Trial for CLs/ Soft Cls' },
  TOCLS: { archetypeID: 'TOCLS', name: 'Trial for Cls/ OrthoK Cls' },
  OHCLS: { archetypeID: 'OHCLS', name: 'Order for CLs/ Hard Cls' },
  OSCLS: { archetypeID: 'OSCLS', name: 'Order for CLs/ Soft Cls' },
  OOCLS: { archetypeID: 'OOCLS', name: 'Order for Cls/ OrthoK Cls' },
  TLSCLS: { archetypeID: 'TLCLS', name: 'Trial list for CLs/Soft Cls' },
  TLHCLS: { archetypeID: 'TLHCLS', name: 'Trial list for CLs/Hard Cls' },
  CLSON: { archetypeID: 'CLSON', name: 'Note Order Contact lens' },
  CLSTSCC: { archetypeID: 'CLSSCCL', name: 'Corneal Coverrage' },
  CLSRO: { archetypeID: 'CLSRO', name: 'Rotation' },
  CLSPUT: { archetypeID: 'CLSRO', name: 'Push up test' },
  CLSSCLUS: { archetypeID: 'CLSSCLUS', name: 'Conclusion' },
  CLSTS: { archetypeID: 'CLSTS', name: 'Contact lens type - Soft', description: 'Soft' },
  CLSTH: { archetypeID: 'CLSTH', name: 'Contact lens type - Hard', description: 'Hard' },
  CLSTO: { archetypeID: 'CLSTO', name: 'Contact lens type - Orth-K', description: 'OrthoK' },
  CLST: { archetypeID: 'CLST', name: 'Contact lens type', type: 'radio', options: ["CLSTS", "CLSTH", "CLSTO"], default: "" },
  CLSOCEN: { archetypeID: 'CLSOCEN', name: 'Centration' },
  CLSORES: { archetypeID: 'CLSORES', name: 'Reserve' },
  CLSOALI: { archetypeID: 'CLSOALI', name: 'Alignment' },
  CLSOPER: { archetypeID: 'CLSOPERV', name: 'Periphevy' },
  CLSOVAWO: { archetypeID: 'CLSOVAWO', name: 'VA without CLs' },
  CLSOCL: { archetypeID: 'CLSOCL', name: 'Conclusion' },
  // Refaction TONO
  REFRACTONO: { archetypeID: 'REFRACTONO', name: 'Refractonometer' },
  // General Examination Checkup
  GNRE: { archetypeID: 'GNRE', name: 'General Examination' },

  // PINHOLE
  PHG: { archetypeID: 'PHG', description: 'Pinhole glass'},
  PHGT: { archetypeID: 'PHGT', description: 'Pinhole glass type'},
  PHGS: { archetypeID: 'PHGS', name: 'Pinhole glass selection', type: "select", options: ["Thập phân", "Đếm ngón tay", "Kính lỗ không tăng"], default: ""},
  PHGS_SNE: {archetypeID: 'PHGS', name: 'Pinhole glass snellen selection ', type: "select", options: ["Snellen", "Đếm ngón tay", "Kính lỗ không tăng"], default: "" },
  PHGTV: { archetypeID: 'PHGV', description: 'Pinhole glass type value'},
}