<template>
  <v-row>
    <v-col cols="3" class="d-flex align-center">
      <span class="text-left text-body-2 font-weight-bold mr-2">
        {{ Archetypes.CLSSED.name }}
      </span>
    </v-col>
    <v-col cols="9">
      <ArchetypeInput
        :path="returnPath('ED')"
        :hideName="true"
        :collections="dataPoints"
        :schema="Archetypes.CLSSE"
      />
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: 'ContactLensEdgeWithComponent',
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
    pathAppend: {
      type: String,
      default: ""
    }
  },
  methods: {
    returnPath(primaryPath) {
      let pathAppend = this.pathAppend != '' ? `/${this.pathAppend}` : '';
      return `${this.pathPrefix}/${primaryPath}${pathAppend}`;
    }
  }
}
</script>

<style>

</style>