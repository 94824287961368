<template>
  <div class="d-block">
    <v-row>
      <v-col v-for="type in eyeTypes" :key="type" cols="4" class="text-center">
        <h4>{{ Archetypes[type].archetypeID }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="type in eyeTypes" :key="type" cols="4" class="text-center">
         <ArchetypeInput
          :path="`${pathPrefix}/PHGT/SNE/${type}`"
          :hideName="true"
          :schema="Archetypes.PHGS_SNE"
          :collections="dataPoints"
          v-model="DVT[type]"
          class="mb-3"
        />
        <!-- nếu select là Thập phân -->
        <ArchetypeInput
           :path="`${pathPrefix}/PHGTV/SNE/DVD/${type}`"
          :hideName="true"
          :schema="Archetypes.DVD_SNE"
          :collections="dataPoints"
          v-if="DVT[type].val == 'Snellen'"
          class="mb-3"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/PHGTV/SNE/DVDL/${type}`"
          :hideName="true"
          :schema="Archetypes.DVDL"
          :collections="dataPoints"
          v-if="DVT[type].val == 'Snellen'"
          class="mb-3"
        />

        <!-- If selection is DNT -->
        <ArchetypeInput
          :path="`${pathPrefix}/PHGTV/SNE/DVCF/${type}`"
          :hideName="true"
          :schema="Archetypes.DVCF"
          :collections="dataPoints"
          v-if="DVT[type].val == 'Đếm ngón tay'"
          class="mb-3"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "PinHoleGlassSnellenComponent",
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: () => []
    },
    pathPrefix: {
      type: String,
      default: ''
    },
  },
  created() {
    this.path = this.Archetypes.PHGT.archetypeID;
  },
  data: () => {
    return {
      path: '',
      eyeTypes: ["OD", "OS"],
      DVT: {
        OD: {},
        OS: {},
      },
    }
  }
}
</script>

<style>

</style>