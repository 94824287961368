<template>
  <div class="d-block">
    <span class="font-weight-bold mb-2">{{ Archetypes.CLSFL.name }}</span>
    <v-sheet elevation="0">
      <v-card-text>
         <component
            class="mt-4"
            v-for="(component, index) in components" :key="index"
            :is="component"
            :pathAppend="pathAppend"
            :pathPrefix="formatPathPrefix"
            :dataPoints="dataPoints"
          ></component>
      </v-card-text>  
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'ContactLensOrthoKFluoresceinPatternComponent',
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
    pathAppend: {
      type: String,
      default: ""
    }
  },
  created() {
    let path = this.pathPrefix == '' ? this.primaryPath : `${this.pathPrefix}/${this.primaryPath}`;
    this.formatPathPrefix = path;
  },
  data: () => ({
    primaryPath: 'FP', //Fluorescein pattern
    formatPathPrefix: '',
    components: [
      'CLSORESComponent',
      'CLSOALIComponent',
      'CLSOPERComponent',
      'CLSOCLUComponent'
    ]
  }),
  methods: {
    returnPathPrefix() {
      let path = this.pathPrefix == '' ? this.primaryPath : `${this.pathPrefix}/${this.primaryPath}`;
      return path;
    }
  }
}
</script>

<style>

</style>