import Vue from 'vue';

// List of archetypes + components
import { Archetypes } from "./archetypes";
import { listComponents } from "./components";

Object.keys(Archetypes).forEach(key => {
  Archetypes[key].archetypeKey = key;
})

// Create a global variable mixins to store this list of components
Vue.mixin({
  data: function () {
    return {
      EhrComponents: listComponents,
      Archetypes: Archetypes,
    }
  },
  methods: {
    validateHighLightDataPoint(dataPoint) {
      if (!dataPoint) {
        return false;
      }
      if (dataPoint.value == "" || dataPoint.value == [] || dataPoint.value == undefined) {
        return false;
      }
      if (dataPoint.value.includes("|")) {
        var value = dataPoint.value.split("|")[0];
        if (value == "") {
          return false
        }
      }
      return true;
    }
  }
});