<template>
  <div class="d-block">
    <v-row>
      <v-col v-for="type in eyeTypes" :key="type" cols="4" class="text-center">
        <h4>{{ Archetypes[type].archetypeID }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="type in eyeTypes" :key="type" cols="4" class="text-center">
        <ArchetypeInput
          :path="`${pathPrefix}/DVT/${type}`"
          :hideName="true"
          :schema="Archetypes.DVT_SNE"
          :collections="dataPoints"
          v-model="DVT[type]"
          class="mb-3"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVD/${type}`"
          :hideName="true"
          :schema="Archetypes.DVD_SNE"
          :collections="dataPoints"
          v-if="DVT[type].val == 'Snellen'"
          class="mb-3"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVDL/${type}`"
          :hideName="true"
          :schema="Archetypes.DVDL"
          :collections="dataPoints"
          v-if="DVT[type].val == 'Snellen'"
          class="mb-3"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVCF/${type}`"
          :hideName="true"
          :schema="Archetypes.DVCF"
          :collections="dataPoints"
          v-if="DVT[type].val == 'Đếm ngón tay'"
          class="mb-3"
        />
        <ArchetypeInput
          :path="`${pathPrefix}/DVDLL/${type}`"
          :hideName="true"
          :schema="Archetypes.DVDLL"
          :collections="dataPoints"
          v-if="DVT[type].val == 'Sáng tối'"
          class="mb-3"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";

export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      eyeTypes: ["OD", "OS", "OU"],
      DVT: {
        OD: {},
        OS: {},
        OU: {},
      },
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.v-data-table__wrapper thead {
  color: black;
}
</style>