import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'
var httpClient = new HttpClient(ApiGateway.Booking);
import moment from "moment"

const prefix = '/Booking';

export default {
    searchBooking: (keySearch, clinicID, timeStart, timeEnd, statusID, page = 1, limit = 10) => {
        var query = {
            keySearch,
            clinicID,
            statusID,
            timeStart,
            timeEnd
        };
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit
        };
        return httpClient.get(`${prefix}/Search`, query, headers)
    },
    createNewBooking: (booking) => {
        booking.timeStart = moment(booking.timeStart).format()
        booking.timeEnd = moment(booking.timeEnd).format()
        return httpClient.post(`${prefix}/Create`, {}, booking)
    },

    createNewBookingFollowUp: (booking) => {
        booking.timeStart = moment(booking.timeStart).format()
        booking.timeEnd = moment(booking.timeEnd).format()
        return httpClient.post(`${prefix}/CreateFollowUp`, {}, booking)
    },
    getBookingByPatient: (patientID, timeStart, timeEnd) => {
        var query = {
            patientID,
            timeStart,
            timeEnd
        };
        return httpClient.get(`${prefix}/SearchByPatient`, query);
    },
    getOptionalByPatient: (patientID, timeStart, timeEnd) => {
        var query = {
            patientID,
            timeStart,
            timeEnd
        };
        return httpClient.get(`${prefix}/SearchOptionalByPatient`, query);
    },
    deleteBooking: (bookingID) => httpClient.put(`${prefix}/Delete/${bookingID}`),
    getDetailBooking: (bookingID) => httpClient.get(`${prefix}/Get/${bookingID}`),
    updateDetailBooking: (bookingID, booking) => {
        booking.timeStart = moment(booking.timeStart).format()
        booking.timeEnd = moment(booking.timeEnd).format()
        return httpClient.put(`${prefix}/Update/${bookingID}`, {}, booking)
    },
    // Update ChiefComplaint by BookingID
    UpdateChiefComplaintsByBookingID: async (id, bodyData) => {
        return httpClient.put(`${prefix}/ChiefComplaints/Update/${id}`, {}, bodyData)
    },
}