<template>
  <v-row>
    <v-col cols="2" class="d-flex align-center">
      <span class="w-100 text-left font-weight-bold">
        {{ Archetypes.IMGL.name }}
      </span>
    </v-col>
    <v-col cols="10">
      <div class="d-block">
        <ArchetypeInput 
          :path="pathPrefix + '/IMGL'"
          :hideName="true"
          :schema="Archetypes.IMGL"
          :collections="dataPoints"
        />
      </div>
    </v-col>
  </v-row> 
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "ImageLinkComponent",
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: ""
    }
  }
}
</script>

<style>

</style>