<template>
  <div class="form-table">
    <v-row>
      <v-col cols="2" class="text-center">
        <h4></h4>
      </v-col>
      <v-col cols="5" class="text-center">
        <h4>{{ Archetypes.OD.name }}</h4>
      </v-col>
      <v-col cols="5" class="text-center">
        <h4>{{ Archetypes.OS.name }}</h4>
      </v-col>
    </v-row>
    <v-row v-for="comp in components" :key="comp">
      <component :is="comp" :dataPoints="dataPoints" :pathPrefix="pathPrefix" />
    </v-row>
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      components: [
        "SPHComponent",
        "CYLComponent",
        "AXSComponent",
        "VAComponent",
        "BCVAComponent",
        "ADDComponent",
        "NVComponent",
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.v-data-table__wrapper thead {
  color: black;
}
</style>