import Vue from 'vue'
import Vuex from 'vuex'

import Drawer from './drawer'
import Auth from './auth'
import Notify from './notify'
import WorkListTabs from './worklist-tabs'
import HeaderTabs from './header-tabs'
import DynamicForm from './dynamicForm'
import Patient from './patient'
import FollowUp from './followUp'
import Clinic from './clinic'
import Medication from './medication'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    HeaderTabs,
    Drawer,
    Auth,
    Notify,
    WorkListTabs,
    DynamicForm,
    Patient,
    FollowUp,
    Clinic,
    Medication
  },
})