<template>
  <v-sheet elevation="0">
    <div class="d-block w-100">
      <span class="font-weight-bold text-h6">{{ Archetypes.CLSPRE.name }}</span>
    </div>
    <div class="d-block">
      <div class="d-block" v-for="(component,index) in components" :key="index">
        <template v-if="index == 0">
          <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="5" class="text-center font-weight-bold">
              <span>OD</span>
            </v-col>
            <v-col cols="5" class="text-center font-weight-bold">
              <span>OS</span>
            </v-col>
          </v-row>
        </template>
        <component
          :is="component"
          :pathPrefix="pathPrefix"
          :dataPoints="dataPoints"
        ></component>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "ContactLensPretestComponent",

  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    pathPrefix: '/CLS/PRE',
    components: [
      'CLSPUPComponent',
      'CLSAPERComponent',
      'CLSHVIDComponent',
      'CLSKREADComponent'
    ]
  })
}
</script>

<style>

</style>