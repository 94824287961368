<template>
  <v-row>
    <v-col cols="2" class="text-left">
      <h3>{{ Archetypes.CLSKREAD.name }}</h3>
    </v-col>
    <v-col cols="5" class="text-center">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <ArchetypeInput
            class="w-70"
            :path="pathPrefix + '/KF/OD'"
            :hideName="true"
            :collections="dataPoints"
            :schema="Archetypes.CLSKF"
          />
          <span class="text-body-2 ml-2 w-30 d-flex flex-wrap">flat D</span>
        </div>

        <div class="d-flex align-center">
          <ArchetypeInput
            class="w-70"
            :path="pathPrefix + '/KS/OD'"
            :hideName="true"
            :collections="dataPoints"
            :schema="Archetypes.CLSKS"
          />
          <span class="text-body-2 ml-2 w-30 d-flex flex-wrap">steep D</span>
        </div>

        <div class="d-flex align-center">
          <ArchetypeInput
            class="w-70"
            :path="pathPrefix + '/KA/OD'"
            :hideName="true"
            :collections="dataPoints"
            :schema="Archetypes.CLSKA"
          />
          <span class="text-body-2 ml-2 w-30 d-flex flex-wrap">average D</span>
        </div>  
      </div>
    </v-col>
    <v-col cols="5" class="text-center">
      <div class="d-flex flex-column">
        <div class="d-flex align-center">
          <ArchetypeInput
            class="w-70"
            :path="pathPrefix + '/KF/OS'"
            :hideName="true"
            :collections="dataPoints"
            :schema="Archetypes.CLSKF"
          />
          <span class="text-body-2 ml-2 w-30 d-flex flex-wrap">flat D</span>
        </div>

        <div class="d-flex align-center">
          <ArchetypeInput
            class="w-70"
            :path="pathPrefix + '/KS/OS'"
            :hideName="true"
            :collections="dataPoints"
            :schema="Archetypes.CLSKS"
          />
          <span class="text-body-2 ml-2 w-30 d-flex flex-wrap">steep D</span>
        </div>

        <div class="d-flex align-center">
          <ArchetypeInput
            class="w-70"
            :path="pathPrefix + '/KA/OS'"
            :hideName="true"
            :collections="dataPoints"
            :schema="Archetypes.CLSKA"
          />
          <span class="text-body-2 ml-2 w-30 d-flex flex-wrap">average D</span>
        </div>  
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: 'ContactLensKReadingComponent',
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
}
</script>

<style scoped>
  .w-30 {
    width: 30%;
  }
  .w-70 {
    width: 70%;
  }
</style>