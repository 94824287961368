<template>
  <v-row>
    <v-col cols="12">
      <ArchetypeInput
        :path="pathPrefix + '/GS/OU'"
        :schema="Archetypes.GS"
        :collections="dataPoints"
        @input="input"
      />
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "GlassStatusComponent",
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  methods: {
    input(val) {
      let value = val.value
      this.$emit("input", value);
    },
  },
};
</script>

<style>
</style>