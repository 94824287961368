<template>
  <div class="d-block">
    <v-card class="pa-0" elevation="0" outlined>
      <v-card-title>
        <span>{{ Archetypes.ORF.name }}</span>
      </v-card-title>
      <v-card-text>
        <div v-for="(component, index) in components" :key="index">
          <v-row v-if="index == 0">
            <v-col cols="2"></v-col>
            <v-col cols="5" class="text-center font-weight-bold">
              <span class="font-weight-bold">OD</span>
            </v-col>
            <v-col cols="5" class="text-center font-weight-bold">
              <span class="font-weight-bold">OS</span>
            </v-col>
          </v-row>
          <component
            :is="component"
            :dataPoints="dataPoints"
            :pathPrefix="pathPrefix"
          ></component>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "OperativeReportComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    components: [
      "PRNAMEComponent",
      "OSTIMEComponent",
      "OETIMEComponent",
      "PRBYComponent",
      "COPOComponent",
    ],
  }),
};
</script>

<style>
</style>