<template>
  <v-sheet elevation="0">
    <div class="d-block">
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="5" class="text-center font-weight-bold">
          <span>OD</span>
        </v-col>
        <v-col cols="5" class="text-center font-weight-bold">
          <span>OS</span>
        </v-col>
      </v-row>
      <div v-for="component in components" :key="component">
        <component
          :is="component"
          :pathPrefix="pathPrefix"
          :dataPoints="dataPoints"
        ></component>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "SubjectiveRefractionComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    pathPrefix: "/SJR",
    components: [
      "SPHComponent",
      "CYLComponent",
      "AXSComponent",
      "DSVAComponent",
      "BCVAComponent",
      "ADDComponent",
      "NVComponent",
    ],
  }),
};
</script>

<style>
</style>