<template>
  <v-row>
      <v-col cols="12">
          <ArchetypeInput 
            :path="pathPrefix + '/PGT/OU'"
            :schema="Archetypes.PGT"
            :collections="dataPoints"
            v-model="valuePGT"
            @input="input"
          />
      </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
    name: "ProgressiveGlassTypeComponent",

    components: {
        ArchetypeInput
    },  
    props: {
        dataPoints: {
            type: Array,
            default: function () {
                return [];
            },
        },
        pathPrefix: {
            type: String,
            default: ""
        }
    },
    watch: {
        valuePGT() {
            this.$emit('input', this.valuePGT)
        }
    },
    data: () => ({
        valuePGT: null,
    }),
    methods: {
        input(val) {
            this.$emit("input", val);
        },
  },
}
</script>

<style>

</style>