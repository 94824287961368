<template>
  <v-menu
    v-model="menuTimePicker"
    ref="menuTime"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="timePicker"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="#007bff"
        v-bind="attrs"
        v-on="on"
      >
        mdi-clock-time-five
      </v-icon>
    </template>
    <v-time-picker
      v-if="menuTimePicker"
      v-model="timePicker"
      full-width
      @click:minute="$refs.menuTime.save(timePicker)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "BaseTimePickerComponent",
  watch: {
    timePicker() {
      this.$emit('input', this.timePicker)
    }
  },
  data: () => ({
    timePicker: null,
    menuTimePicker: false
  })
}
</script>

<style>

</style>