<template>
  <v-row>
    <v-col cols="2" class="d-flex align-center">
      <span class="w-100 text-left font-weight-bold">
        {{ Archetypes.OSTIME.name }}
      </span>
    </v-col>
    <v-col cols="5">
      <div class="d-flex align-center">
        <ArchetypeInput
          class="mr-1"
          :path="pathPrefix + '/OSTIME/OD'"
          :hideName="true"
          :schema="Archetypes.DTIME"
          :collections="dataPoints"
          ref="ODSTIMEComponent"
        />
        <BaseTimePicker 
          v-model="timePickerOD"
        />
      </div> 
    </v-col>
    <v-col cols="5">
      <div class="d-flex align-center">
         <ArchetypeInput
          class="mr-1"
          :path="pathPrefix + '/OSTIME/OS'"
          :hideName="true"
          :schema="Archetypes.DTIME"
          :collections="dataPoints"
          ref="OSSTIMEComponent"
        />
        <BaseTimePicker 
          v-model="timePickerOS"
        />
      </div> 
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import BaseTimePicker from "@/components/common/BaseTimePicker"
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "OperationStartTimeComponent",
  components: {
    ArchetypeInput,
    BaseTimePicker
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: ""
    }
  },
  watch: {
    timePickerOD() {
      let dateTime = this.connectDateWithTime(this.date, this.timePickerOD);
      this.parseDataToArchetype("OD", dateTime);
    },
    timePickerOS() {
      let dateTime = this.connectDateWithTime(this.date, this.timePickerOS);
      this.parseDataToArchetype("OS", dateTime);
    }
  },
  data:() => ({
    timePickerOD: null,
    timePickerOS: null,
    date: moment().format('DD/MM/YYYY')
  }),
  created() {},
  methods: {
    connectDateWithTime(date, time) {
      return `${date} - ${time}:00`;
    },
    parseDataToArchetype(side, value) {
      if (side == "OD") {
        this.$refs.ODSTIMEComponent.parseData(value);
        return;
      } else if (side == "OS") {
        this.$refs.OSSTIMEComponent.parseData(value);
        return;
      }
    }
  },
};
</script>

<style>
</style>