<template>
  <v-row>
    <v-col cols="2" class="d-flex">
      <span class="w-100 text-center font-weight-bold mt-2">
        {{ Archetypes.NV.archetypeID }}
      </span>
    </v-col>
    <v-col cols="5">
      <ArchetypeInput
        :path="pathPrefix + '/GPCG/OD'"
        :hideName="true"
        :schema="Archetypes.GP_CG"
        :collections="dataPoints"
      />
      <ArchetypeInput
        :path="pathPrefix + '/RP/OD'"
        :hideName="true"
        :schema="Archetypes.RP"
        :collections="dataPoints"
      />
    </v-col>
    <v-col cols="5">
      <ArchetypeInput
        :path="pathPrefix + '/GPCG/OS'"
        :hideName="true"
        :schema="Archetypes.GP_CG"
        :collections="dataPoints"
      />
      <ArchetypeInput
        :path="pathPrefix + '/RP/OS'"
        :hideName="true"
        :schema="Archetypes.RP"
        :collections="dataPoints"
      />
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "NearVisionCurrentGlassComponent",

  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
          return [];
      },
    },
    pathPrefix: {
      type: String,
      default: ""
    },
  },
  data() {
    return {};
  },
  created() {},
}
</script>

<style>

</style>