<template>
  <v-row>
    <v-col cols="2" class="d-flex align-center">
      <span class="w-100 text-center font-weight-bold">
        {{ Archetypes.SGTP.name }}
      </span>
    </v-col>
    <v-col cols="5">
    <div class="form-table nice-diagonal">
      <v-row>
      <v-col cols="4" class="text-center">
      </v-col>
      <v-col cols="4" class="text-center">
        <ArchetypeInput
        :path="pathPrefix + '/SGTPUp/OD'"
        :hideName="true"
        :schema="Archetypes.SGTPUp"
        :collections="dataPoints"
        @input="input"
        />
      </v-col>
      <v-col cols="4" class="text-center">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="text-center">
        <ArchetypeInput
        :path="pathPrefix + '/SGTPLeft/OD'"
        :hideName="true"
        :schema="Archetypes.SGTPLeft"
        :collections="dataPoints"
        @input="input"
        />
      </v-col>
      <v-col cols="4" class="text-center">
      </v-col>
      <v-col cols="4" class="text-center">
        <ArchetypeInput
          :path="pathPrefix + '/SGTPRight/OD'"
          :hideName="true"
          :schema="Archetypes.SGTPRight"
          :collections="dataPoints"
          @input="input"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="text-center">
      </v-col>
      <v-col cols="4" class="text-center">
        <ArchetypeInput
          :path="pathPrefix + '/SGTPDown/OD'"
          :hideName="true"
          :schema="Archetypes.SGTPDown"
          :collections="dataPoints"
          @input="input"
        />
      </v-col>
      <v-col cols="4" class="text-center">
      </v-col>
    </v-row>
    </div>
    </v-col>
    <v-col cols="5">
    <div class="form-table nice-diagonal">
      <v-row>
      <v-col cols="4" class="text-center">
      </v-col>
      <v-col cols="4" class="text-center">
        <ArchetypeInput
          :path="pathPrefix + '/SGTPUp/OS'"
          :hideName="true"
          :schema="Archetypes.SGTPUp"
          :collections="dataPoints"
          @input="input"
        />
      </v-col>
      <v-col cols="4" class="text-center">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="text-center">
        <ArchetypeInput
          :path="pathPrefix + '/SGTPLeft/OS'"
          :hideName="true"
          :schema="Archetypes.SGTPLeft"
          :collections="dataPoints"
          @input="input"
        />
      </v-col>
      <v-col cols="4" class="text-center">
      </v-col>
      <v-col cols="4" class="text-center">
        <ArchetypeInput
          :path="pathPrefix + '/SGTPRight/OS'"
          :hideName="true"
          :schema="Archetypes.SGTPRight"
          :collections="dataPoints"
          @input="input"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="text-center">
      </v-col>
      <v-col cols="4" class="text-center">
        <ArchetypeInput
          :path="pathPrefix + '/SGTPDown/OS'"
          :hideName="true"
          :schema="Archetypes.SGTPDown"
          :collections="dataPoints"
          @input="input"
        />
      </v-col>
      <v-col cols="4" class="text-center">
      </v-col>
    </v-row>
    </div>
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style>
.form-table {
  position: relative;
}
.nice-diagonal:before {
  content: "";
  width: 85%;
  height: 85%;
  position: absolute;
  top: 15px;
  left: 20px;
  background: linear-gradient(to top right, transparent calc(50% - 1px), purple, transparent calc(50% + 1px)), 
              linear-gradient(to top left, transparent calc(50% - 1px), purple, transparent calc(50% + 1px));
}
</style>