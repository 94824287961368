<template>
  <div>
    <div class="d-flex justify-end">
      <ArchetypeInput
        :path="'/VA/DVU'"
        :hideName="true"
        :schema="Archetypes.DVU"
        :collections="dataPoints"
        v-model="DVU"
        class="mb-3"
      />
    </div>
    <!-- SC -- Without glass -->
    <h3 class="mt-3 mb-3 text-left pl-3">{{ Archetypes.SC.description }}</h3>
    <DIVSNEComponent
      v-if="DVU.val == 'Snellen'"
      :pathPrefix="'/DV/SC'"
      :dataPoints="dataPoints"
    />
    <DIVDComponent v-else :pathPrefix="'/DV/SC'" :dataPoints="dataPoints" />

    <!-- CC -- With glass -->
    <h3 class="mt-3 mb-3 text-left pl-3">{{ Archetypes.CC.description }}</h3>
    <DIVSNEComponent
      v-if="DVU.val == 'Snellen'"
      :pathPrefix="'/DV/CC'"
      :dataPoints="dataPoints"
    />
    <DIVDComponent v-else :pathPrefix="'/DV/CC'" :dataPoints="dataPoints" />
      
    <!-- Pin Hole glass -->
    <h3 class="mt-3 mb-3 text-left pl-3">{{ Archetypes.PHG.description }}</h3>
    <PHGSNEComponent 
      v-if="DVU.val == 'Snellen'"
      :pathPrefix="'/PHG'"
      :dataPoints="dataPoints"
    />
    <PHGComponent v-else :pathPrefix="'/PHG'" :dataPoints="dataPoints" />

    <!-- Near vision -->
    <h3 class="mt-3 mb-3 text-left pl-3">{{ Archetypes.NV.name }}</h3>
    <NVComponent :pathPrefix="'/DV'" :dataPoints="dataPoints" />
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
import NVComponent from "@/components/examination/components/Archetypes/NVComponent.vue";
import DIVDComponent from "@/components/examination/components/Archetypes/DIVDComponent.vue";
import DIVSNEComponent from "@/components/examination/components/Archetypes/DIVSNEComponent.vue";

export default {
  components: {
    NVComponent,
    DIVDComponent,
    DIVSNEComponent,
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      DVU: {},
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.v-data-table__wrapper thead {
  color: black;
}
</style>