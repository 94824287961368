<template>
  <v-row>
      <v-col cols="12">
          <ArchetypeInput
            :path="pathPrefix + '/QNGP/OU'"
            :hideName="true"
            :schema="Archetypes.QNGP"
            :collections="dataPoints"
            @input="input"
        />
      </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
    name: 'QuickNotGlassPrescriptionComponent',
    
    components: {
        ArchetypeInput
    },
    props: {
        dataPoints: {
            type: Array,
            default: function () {
                return [];
            },
        },
        pathPrefix: {
            type: String,
            default: ""
        }
    },
    methods: {
        input(val) {
            this.$emit("input", val);
        },
  },
}
</script>

<style>

</style>