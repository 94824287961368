import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './stores/store'
import Vuebar from 'vuebar'
import './plugins/base'
import VueSkycons from 'vue-skycons';
import VueNumber from 'vue-number-animation';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMask from "v-mask";
import '@/components/examination/';
import i18n from './plugins/i18n';
import VueTabsChrome from 'vue-tabs-chrome';
import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.use(VueTabsChrome);
Vue.use(VueSkycons, {
  color: '#1e88e5'
});
Vue.use(Toast, {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 20,
  newestOnTop: true,
  hideProgressBar: true,
  position: "top-right",
  timeout: 4000,
});
Vue.config.productionTip = false
Vue.use(Vuebar);
Vue.use(VueNumber);
Vue.use(CoolLightBox);
Vue.use(VueSweetalert2);
Vue.use(VueMask);

// Signals socket connection
import '@/plugins/signals';

new Vue({
  vuetify,
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')