import moment from "moment"
import BookingService from '@/services/booking'

const state = () => ({
    clinicID: null,
    statusID: 1,
    startOfMonth: null,
    endOfMonth: null,
    eventOfMonth: [],
    eventListDateOfMonth: [],
    totalsEventOfMonth: 0,
    openDetailBookingDate: false,
    detailDate: '',
    eventOfDate: [],
    bookingOfPatient: { // only for 1 patient
        totals: 0,
        items: [],
        patientID: null,
        loading: false,
        error: false,
        error_message: ''
    },
    noteOfPatient: { // only for 1 patient
        totals: 0,
        items: [],
        patientID: null,
        loading: false,
        error: false,
        error_message: ''
    },
})

const mutations = {
    SET_START_MONTH: (state, payload) => {
        state.startOfMonth = payload.startOfMonth;
    },
    SET_END_MONTH: (state, payload) => {
        state.endOfMonth = payload.endOfMonth;
    },
    SET_CLINIC: (state, payload) => {
        state.clinicID = payload.clinicID;
    },
    SET_DETAIL_BOOKING_DATE: (state, payload) => {
        state.openDetailBookingDate = payload.open;
        state.detailDate = payload.date;
        if (payload.open) {

            state.eventOfDate = state.eventOfMonth.filter(item => {
                if (item.dateVisit == state.detailDate) {
                    return item;
                }
            });
        } else {
            state.eventOfDate = [];
        }
    },
    SET_PATIENT: (state, payload) => {
        state.bookingOfPatient.patientID = payload.patientID
    }
}

const actions = {
    updateEventByMonth: async ({ state }) => {
        var event = await BookingService.searchBooking(
            '',
            state.clinicID,
            state.startOfMonth,
            state.endOfMonth,
            state.statusID,
            1,
            100
        )
        if (event.error) {
            return;
        }

        state.totalsEventOfMonth = event.totals;
        state.eventOfMonth = event.items.map(item => {
            item.dateVisit = moment(item.timeStart).format('YYYY-MM-DD') // we can query from that
            return item;
        });


        let listEventDate = [];
        event.items.forEach(itemEvent => {
            let date = moment(itemEvent.timeStart).format('YYYY-MM-DD');
            listEventDate.push(date);
        })
        state.eventListDateOfMonth = listEventDate;
    },
    getEventByMonth: async ({ commit, state }, payload) => {
        let { startOfMonth, endOfMonth, datePicker } = payload;

        if (state.startOfMonth != startOfMonth && state.endOfMonth != endOfMonth) {
            state.startOfMonth = startOfMonth;
            state.endOfMonth = endOfMonth;

            var event = await BookingService.searchBooking(
                '',
                state.clinicID,
                state.startOfMonth,
                state.endOfMonth,
                state.statusID,
                1,
                100
            )
            if (event.error) {
                return;
            }

            state.totalsEventOfMonth = event.totals;
            state.eventOfMonth = event.items.map(item => {
                item.dateVisit = moment(item.timeStart).format('YYYY-MM-DD') // we can query from that
                return item;
            });

            let listEventDate = [];
            event.items.forEach(itemEvent => {
                let date = moment(itemEvent.timeStart).format('YYYY-MM-DD');
                listEventDate.push(date);
            })
            state.eventListDateOfMonth = listEventDate;
            // call mutations
            commit('SET_DETAIL_BOOKING_DATE', { open: true, date: datePicker })
        }
    },
    getBookingPatient: async ({ state }) => {
        // from today to future
        state.bookingOfPatient.loading = true;
        var bookingPatient = await BookingService.getBookingByPatient(
            state.bookingOfPatient.patientID,
            moment().format('YYYY-MM-DD'),
            ''
        )
        var optionalPatient = await BookingService.getOptionalByPatient(
            state.bookingOfPatient.patientID,
            moment().format('YYYY-MM-DD'),
            ''
        )
        if (bookingPatient.error) {
            state.bookingOfPatient.loading = false;
            state.noteOfPatient.totals = 0;
            state.bookingOfPatient.totals = 0;
            state.bookingOfPatient.error = true;
            state.bookingOfPatient.error_message = 'Can not load list booking!'
            return;
        }

        state.bookingOfPatient.loading = false;
        state.noteOfPatient.totals = optionalPatient.totals;
        state.bookingOfPatient.totals = bookingPatient.totals + state.noteOfPatient.totals;
        state.bookingOfPatient.items = bookingPatient.items.filter(i => i.statusID == 1).map(item => {
            item.dateVisit = moment(item.timeStart).format('DD-MM-YYYY');
            item.atStart = moment(item.timeStart).format('HH:mm');
            item.atEnd = moment(item.timeStart).add(1, 'hours').format('HH:mm');
            return item;
        });
    },
    getAllOptionalPatient: async ({ state }) => {
        state.noteOfPatient.loading = true;
        // from today to future
        var optionalPatient = await BookingService.getOptionalByPatient(
            state.bookingOfPatient.patientID,
            moment().format('YYYY-MM-DD'),
            ''
        )
        if (optionalPatient.error) {
            state.noteOfPatient.loading = false;
            state.noteOfPatient.totals = 0;
            state.noteOfPatient.error = true;
            state.noteOfPatient.error_message = 'Can not load list note!'
            return;
        }

        state.noteOfPatient.loading = false;
        state.noteOfPatient.items = optionalPatient.items.filter(i => i.statusID === 0).map(item => {
            item.dateVisit = moment(item.timeStart).format('DD-MM-YYYY');
            item.atStart = moment(item.timeStart).format('HH:mm');
            item.atEnd = moment(item.timeStart).add(1, 'hours').format('HH:mm');
            return item;
        });
    }
}

export default {
    namespaced: 'FollowUp',
    state,
    mutations,
    actions
}