<template>
  <div class="d-flex flex-column w-100">
    <span class="font-weight-bold mb-2">{{ Archetypes.CLSOVR.name }}</span>
    <!-- S-C-A -->
    <div class="d-block">
      <v-row>
        <!-- S -->
        <v-col cols="4">
          <div class="d-flex">
            <span :class="classTitle">{{ Archetypes.CLSSHTDS.name}}</span>
            <ArchetypeInput
              :path="returnPath('OVR/S')"
              :hideName="true"
              :schema="Archetypes.CLSSHS"
              :collections="dataPoints"
            />
          </div>
        </v-col>
          <!-- C -->
        <v-col cols="4">
          <div class="d-flex">
            <span :class="classTitle">{{ Archetypes.CLSSHTDC.name}}</span>
            <ArchetypeInput
              :path="returnPath('OVR/C')"
              :hideName="true"
              :schema="Archetypes.CLSSHC"
              :collections="dataPoints"
            />
          </div>
        </v-col>
          <!-- A -->
        <v-col cols="4">
          <div class="d-flex">
            <span :class="classTitle">{{ Archetypes.CLSSHTDA.name}}</span>
            <ArchetypeInput
              :path="returnPath('OVR/A')"
              :hideName="true"
              :schema="Archetypes.CLSSHA"
              :collections="dataPoints"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- VA -->
    <div class="d-block">
      <v-row>
        <v-col cols="2 d-flex">
          <span :class="classTitle">{{ Archetypes.CLSSHVAVL.name}}</span>
        </v-col>
        <v-col cols="10">
          <ArchetypeInput
              :path="returnPath('OVR/VA')"
              :hideName="true"
              :schema="Archetypes.CLSSHVAV"
              :collections="dataPoints"
            />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "ContactLensOverRefComponent",
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
    pathAppend: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    classTitle: 'text-left text-body-2 font-weight-bold mr-2 mt-3'
  }),
  methods: {
    returnPath(primaryPath) {
      let pathAppend = this.pathAppend != '' ? `/${this.pathAppend}` : '';
      return `${this.pathPrefix}/${primaryPath}${pathAppend}`;
    }
  }
}
</script>

<style>

</style>