<template>
  <div class="d-flex flex-column">
    <component
      class="mt-3"
      v-for="(component, index) in components" :key="index"
      :is="component"
      v-bind="$props"
    ></component>
  </div>
</template>

<script>
export default {
  name: 'ContactLensSoftBySideComponent',
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
    pathAppend: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    components: [
      'CLSSHTDComponent',    // trial len detail
      'CLSCENComponent',     // centration
      'CLSTSCComponent',    // Coraneal
      'CLSSHPGComponent',   // primary gaze
      'CLSRComponent',      // Rotation
      'CLSPUTComponent',
      'CLSSCLUComponent',   // Soft conclusion
      'CLSSHVAComponent',
      'CLSORComponent'
    ],
  })
}
</script>

<style>

</style>