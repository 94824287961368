<template>
  <v-row class="pa-0 ma-0 mt-2">
    <v-col lg="6" md="6" sm="12" sx="12" class="pa-0 d-flex justify-center">
      <ArchetypeInput
        :path="pathPrefix + '/WBCG'"
        :schema="Archetypes.WBCG"
        :collections="dataPoints"
      />
    </v-col> 
  </v-row> 
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: 'WhereBuyCurrentGlassComponent',
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
        type: Array,
        default: function () {
            return [];
        },
    },
    pathPrefix: {
        type: String,
        default: ""
    },
  },
}
</script>

<style>

</style>