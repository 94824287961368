import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';

// load all file in folder that you want to load
const requireComponent = require.context(
  // Path folder 
  './components',
  // load all children files in subfolder
  true,
  // Regular express to find files
  /\w+.(vue|js)$/
)

// List of components
const componentNames = [];

requireComponent.keys().forEach(fileName => {
  // get Component configuration
  const componentConfig = requireComponent(fileName);
  fileName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1');
  // get name of component using PascalCase
  const componentName = upperFirst(JSON.parse(JSON.stringify(fileName.split("/").pop())));
  // import the component as global component
  Vue.component(componentName, componentConfig.default || componentConfig)
  // Import the component name into the list
  componentNames.push(componentName);
});

export const listComponents = componentNames;