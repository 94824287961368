<template>
  <div class="d-flex flex-column w-100">
    <span class="mt-1 mb-1 font-weight-bold">{{ Archetypes.CLSSHGAZE.name }}</span>
    <div class="d-flex">
      <ArchetypeInput
        :path="returnPath('PG/T')"
        :hideName="true"
        :schema="Archetypes.CLSSHPT"
        :collections="dataPoints"
      />
      <span class="text-body-2 font-weight-bold ml-2 mt-3">
        mm
      </span>
    </div>
    <!-- VA -->
    <div class="d-block">
      <v-row>
        <v-col cols="12">
          <ArchetypeInput
              :path="returnPath('PG/S')"
              :hideName="true"
              :schema="Archetypes.CLSSHPS"
              :collections="dataPoints"
            />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: "ContactLensPrimaryGazeComponent",
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
    pathAppend: {
      type: String,
      default: ""
    } //ODOS
  },
  data: () => ({
    classTitle: 'text-left text-body-2 font-weight-bold mr-2'
  }),
  methods: {
    returnPath(primaryPath) {
      let pathAppend = this.pathAppend != '' ? `/${this.pathAppend}` : '';
      return `${this.pathPrefix}/${primaryPath}${pathAppend}`;
    }
  }
}
</script>

<style>

</style>