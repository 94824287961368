import Helper from '../plugins/helper';

const state = () => ({
  tabs: getCachedWorkListTabs(),
})

const getters = {
  worklistTabs: state => state.tabs,
}

const mutations = {
  ADD_WORKLIST_TAB(state, payload) {
    if (!state.tabs.find(t => t.id == payload.id)) {
      state.tabs.push(payload)
    }
    saveCacheWorkListTabs(state.tabs);
  },
  REMOVE_WORKLIST_TAB(state, payload) {
    // not allow delete tabID = 1 & 2 (index 0 & 1)
    var i;
    for (i = 2; i < state.tabs.length; i++) {
      if (state.tabs[i].id == payload.id) {
        state.tabs.splice(i, 1);
        break;
      }
    }
    saveCacheWorkListTabs(state.tabs);
  },
  UPDATE_WORKLIST_TAB_TOTAL(state, payload) {
    for (var i = 0; i < state.tabs.length; i++) {
      if (state.tabs[i].id == payload.id) {
        state.tabs[i].total = payload.total;
        break;
      }
    }
    saveCacheWorkListTabs(state.tabs);
  }
}

const actions = {

}

function getCachedWorkListTabs() {
  var jsonData = Helper.getLocalStorage("WorkListTabs");
  console.log(jsonData);
  if (!jsonData) return [
    {
      id: 1,
      title: "Check-In",
      total: 0,
    },
    {
      id: 2,
      title: "Booking",
      total: 0,
    },
  ];
  return JSON.parse(jsonData);
}

function saveCacheWorkListTabs(tabs) {
  return Helper.setLocalStorage("WorkListTabs", JSON.stringify(tabs));
}

export default {
  state,
  getters,
  mutations,
  actions,
}