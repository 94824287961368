import _ from 'lodash';
import { listComponents } from "@/components/examination/components"

const state = () => ({
    listForm: [],
    listComponents: listComponents,
    formTable: [],
    mappingFormPath: []
})

const getters = {
    getListArchetypeComponent: state => state.listComponents,
    getFormTable: state => state.formTable,
    getLastFormID: (state) => {
        let last = _.last(state.formTable);
        return (typeof last.ID == 'undefined') ? null : last.ID
    },
    getListMappingFormPath: state => state.mappingFormPath,
}

const mutations = {
    CREATE_FORM: (state, payload) => {
        let { listPathChild, formDetail } = payload;

        let newForm = {
            ID: `F_${state.formTable.length + 1}`,
            Name: formDetail.name,
            Description: formDetail.description,
        };

        // add mapping form path
        listPathChild.forEach(item => {
            let newRecord = {
                ID: state.mappingFormPath.length + 1,
                FormID: newForm.ID,
                PathArchetypeChild: item
            };
            state.mappingFormPath.push(newRecord);
        })

        // save new Form record
        state.formTable.push(newForm);
    },

    CREATE_MAPPING_FORM_PATH: (state, payload) => {

        let { listPathChild, formID } = payload;

        listPathChild.forEach(item => {
            let newRecord = {
                ID: state.mappingFormPath.length + 1,
                FormID: formID,
                PathArchetypeChild: item
            };
            // push them into mappingFormPathTable
            state.mappingFormPath.push(newRecord);
        });
    },

    UPDATE_FORM: (state, payload) => {
        // update information form
        const { ID, formDetail, listPathChild } = payload;

        state.formTable.map(itemForm => {
            if (itemForm.ID == ID) {
                itemForm.Name = formDetail.name;
                itemForm.Description = formDetail.description
            }
        })
        // update listComponent form
        // remove all component with FormID == payload.ID
        state.mappingFormPath = _.filter(state.mappingFormPath, function (i) { return i.FormID != ID })

        // push again
        // add mapping form path
        listPathChild.forEach(item => {
            let newRecord = {
                ID: state.mappingFormPath.length + 1,
                FormID: ID,
                PathArchetypeChild: item
            };
            state.mappingFormPath.push(newRecord);
        })
    },

    REMOVE_FORM: (state, payload) => {
        let { ID } = payload;
        // update list Form
        state.formTable = state.formTable.filter(itemForm => itemForm.ID != ID);
        // update list MappingFormPath 
        state.mappingFormPath = state.mappingFormPath.filter(itemMap => itemMap.FormID != ID);
    }


}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}