/* eslint-disable no-unused-vars */
import AuthMiddleware from '@/middlewares/authen'
import { UserTypeCodes } from "@/plugins/constant";

export default [
  {
    path: 'chief-complaints',
    component: () => import('@/views/Patient/EpisodeRecord/ChiefComplaints'),
  },
  {
    path: 'visualAcuityTono',
    component: () => import('@/views/Patient/EpisodeRecord/VisualAcuityTono'),
  },
  {
    path: 'general-check-up',
    component: () => import('@/views/Patient/EpisodeRecord/GeneralCheckUp'),
  },
  {
    path: 'examination',
    component: () => import(`@/views/Patient/EpisodeRecord/Examination`),
  },
  {
    path: 'diagnosis',
    component: () => import(`@/views/Patient/EpisodeRecord/Diagnosis`),
    beforeEnter: AuthMiddleware.AllowUserTypes([UserTypeCodes.Root, UserTypeCodes.Admin, UserTypeCodes.Doctor]),
  },
  {
    path: 'doctor-treatment-plan',
    component: () => import(`@/views/Patient/EpisodeRecord/TreatmentPlan`),
    beforeEnter: AuthMiddleware.AllowUserTypes([UserTypeCodes.Root, UserTypeCodes.Admin, UserTypeCodes.Doctor]),
  },
  {
    path: 'opto-treatment-plan',
    component: () => import(`@/views/Patient/EpisodeRecord/TreatmentPlan`),
  },
  {
    path: 'follow-up',
    component: () => import(`@/views/Patient/EpisodeRecord/FollowUp`),
  },
  {
    path: 'discharge-summary',
    component: () => import(`@/views/Patient/EpisodeRecord/DischargeSummary`),
  },
  {
    path: 'optometry-treatment',
    name: 'glass-prescription',
    component: () => import(`@/views/Patient/EpisodeRecord/TreatmentPlan/TreatmentPlan`),
  },
  {
    path: 'optometrist-conclusion',
    component: () => import(`@/components/common/dischargeSummary/OptometristConclusion`),
  },
];
