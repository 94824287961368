<template>
  <v-sheet>
    <div class="d-block">
      <span class="text-left text-subtitle-2 font-weight-bold">{{ Archetypes.CLSONV.name }}</span>
    </div>
    <div class="d-block"> 
      <ArchetypeInput
        :path="pathPrefix + '/NOTE/OU'"
        :hideName="true"
        :collections="dataPoints"
        :schema="Archetypes.CLSONV"
        @input="input"
      />
    </div>
  </v-sheet>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  name: 'ContactLensOrderNoteComponent',
  components: {
    ArchetypeInput
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
}
</script>

<style>

</style>