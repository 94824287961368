import RecordRoutes from "./record/routes"

/* eslint-disable no-unused-vars */
export default [
  {
    path: 'patient/:patientId',
    component: () => import('@/layouts/patient-layout/PatientLayout'),
    children: [
      {
        path: '',
        component: () => import(`@/views/Patient/Index`),
      },
      {
        path: 'overview',
        component: () => import(`@/views/Patient/Overview`),
      },
      {
        path: 'progress',
        component: () => import(`@/views/Patient/Progress`),
      },
      {
        path: 'analysis',
        component: () => import(`@/views/Patient/Analysis`),
      },
      {
        path: 'episodeRecord/:episodeRecordID',
        component: () => import(`@/layouts/record-layout/RecordLayout`),
        children: [
          ...RecordRoutes
        ]
      },
      {
        path: 'prescription/:episodeRecordID',
        component: () => import(`@/layouts/prescription-layout/PrescriptionLayout`),
        children: [
          {
            path: 'glass',
            name: 'Đơn kính',
            component: () => import(`@/components/prescription/GlassPrint`),
            props: { print: true }
          },
          {
            path: "medicine",
            name: "Đơn thuốc",
            component: () => import(`@/components/prescription/MedicinePrint`),
            props: { print: true }
          },
          {
            path: "cls/:typeCls",
            name: "Đơn Kính Áp Tròng",
            component: () => import('@/components/prescription/ContactLensPrint'),
            props: { print: true }
          },
          {
            path: "discharge",
            name: "Bản tóm tắt xuất viện",
            component: () => import(`@/components/prescription/DischargePrint`),
            props: { print: true, detail: true }
          },
          {
            path: 'payment-summary',
            name: "Payment Summary",
            component: () => import('@/components/payment/PaymentPrint'),
            props: { print: true, detail: true }
          },
        ]
      }
    ],
  },
];
