<template>
  <v-card
    class="elevation-0 covteda-card"
    style="border: 1px solid rgba(0, 0, 0, 0.6); width: 600px"
  >
    <v-tabs
      v-model="eyeType"
      background-color="transparent"
      grow
      class="cover-test-eye-tabs"
    >
      <v-tab v-for="item in eyeTypes" :key="item">
        <v-badge
          v-if="hasDataPoint(pathPrefix, item)"
          color="green darken-2"
          dot
          offset-x="-8"
          offset-y="10"
        >
          {{ Archetypes[item].name }}
        </v-badge>
        <span v-else>{{ Archetypes[item].name }}</span>
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="eyeType">
      <v-tab-item eager v-for="item in eyeTypes" :key="item">
        <v-row>
          <v-col cols="4" class="d-flex">
            <span class="w-100 text-left font-weight-bold">
              {{ Archetypes.COVTT.name }}
            </span>
          </v-col>
          <v-col cols="8">
            <ArchetypeInput
              :path="`${pathPrefix}/COVTT/${item}`"
              :hideName="true"
              :schema="Archetypes.COVTT"
              :collections="dataPoints"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="d-flex">
            <span class="w-100 text-left font-weight-bold">
              {{ Archetypes.COVTPD.name }}
            </span>
          </v-col>
          <v-col cols="8">
            <ArchetypeInput
              :path="`${pathPrefix}/COVTPD/${item}`"
              :hideName="true"
              :schema="Archetypes.COVTPD"
              :collections="dataPoints"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="d-flex">
            <span class="w-100 text-left font-weight-bold">
              {{ Archetypes.COVTACM.name }}
            </span>
          </v-col>
          <v-col cols="8">
            <ArchetypeInput
              :path="`${pathPrefix}/COVTACM/${item}`"
              :hideName="true"
              :schema="Archetypes.COVTACM"
              :collections="dataPoints"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="d-flex">
            <span class="w-100 text-left font-weight-bold">
              {{ Archetypes.COVTPOP.name }}
            </span>
          </v-col>
          <v-col cols="8">
            <ArchetypeInput
              :path="`${pathPrefix}/COVTPOP/${item}`"
              :hideName="true"
              :schema="Archetypes.COVTPOP"
              :collections="dataPoints"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      eyeType: 0,
      eyeTypes: ["OD", "OS", "ALT"],
    };
  },
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    hasDataPoint(prefix, eyeType) {
      return this.validateHighLightDataPoint(
        this.dataPoints.find(
          (d) => d.path.includes(prefix) && d.path.includes(eyeType)
        )
      );
    },
  },
};
</script>

<style lang="scss">
.cover-test-eye-tabs.v-tabs {
  .v-tabs-slider {
    height: 0px;
  }
  > .v-tabs-bar {
    .v-tab {
      font-size: 11px !important;
      color: #424242 !important;
      min-width: 186px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    }
    .v-tab--active {
      background: #eeeeee;
      font-weight: bold;
    }
  }
  .v-tabs-bar.v-item-group > * {
    cursor: initial;
  }
}
</style>